import styled from "styled-components";
import { CONFIG } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState, useEffect, useContext } from "react";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { useParams } from "react-router-dom";
import useScreenOrientation from "../../../hooks/use-screenOrientation";
import { Camera } from "@mediapipe/camera_utils";
import { POSE_CONNECTIONS, Pose } from "@mediapipe/pose";
import posePackage from "@mediapipe/pose/package.json";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import {
  checkIfFullyVisible,
  checkIfHandsAreUp,
  checkIfLyingDown,
} from "../../../helpers/displayHelpers";
import * as Sentry from "@sentry/react";
import {
  displayCanvasFeature,
  forceShowCanvasFeature,
  forceShowVideoFeature,
} from "../../../utils/features";
import { eventsLimiter, setEventsStarted } from "../../../utils/limiter";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import WebsocketContext from "../../../containers/Mirror/WorkoutScreen/WebsocketContext";
import FPSCounter from "./notFPSCounter";
import CameraValidation from "./CameraValidation";
import { NativeBridge } from "../../../containers/Mirror/WorkoutScreen/MobileCommunication/index.js";
import ReactionClubFitInScreen from "../../../constants/clientsStyles/ReactionClubFitInScreen/index.js";
import {
  getModelComplexity,
  initiationComponents,
  recordComponents,
  REDUCED_JOINT_SET_MODE,
  sendEventsComponents,
  workoutDifficulties,
} from "./canvasConfig.js";
import { setErrorMessage, setShowError } from "../../../store/fit/error.js";
import { ERROR_MESSAGES } from "../../../containers/Mirror/ErrorScreen/errors.js";
import { setWaitForUpload, uploadFileToServer } from "../../../store/fit/account.js";
import useMediaRecorder from "../../../hooks/useMediaRecorder.js";
import { getCompanyNameFromUrl } from "../../../utils/urls.js";
import { selectToken } from "../../../store/fit/workout.js";
import { deviceDetect, mobileModel, osVersion } from "react-device-detect";

const VideoContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  min-width: 274px;
  margin: 0 auto;
  width: 100%;
  max-height: -webkit-fill-available;
`;

const VideoEl = styled.video`
  ${({ $initiation, $validationCheck, $fullyVisible }) =>
    $initiation && borderColor($validationCheck, $fullyVisible)}
  ${({ $screenWidth }) => ($screenWidth < 896 ? "width: 100%;" : "")}
  transform: scale(-1, 1);
  display: ${(props) => videoDisplay(props)};
`;

const CanvasEl = styled.canvas`
  ${({ $initiation, $validationCheck, $fullyVisible }) =>
    $initiation && borderColor($validationCheck, $fullyVisible)}
  ${({ $screenWidth }) => ($screenWidth < 896 ? "width: 100%;" : "")}
  display: ${(props) => canvasDisplay(props)};
`;

const borderColor = ($validationCheck, $fullyVisible) => {
  return `border: 10px solid ${
    !$fullyVisible
      ? CONFIG.colors.badPositionJoints
      : $validationCheck
      ? CONFIG.colors.handsUpJoints
      : CONFIG.colors.badPositionJoints
  } ;`;
};

const videoDisplay = ({ $tracking, $appType, $forceShowVideo }) => {
  if ($forceShowVideo) return "inline";
  else if ($appType === CONFIG.APP_TYPE.MIRROR) return "none";
  else if ($tracking) return "none";
  else return "inline";
};

const canvasDisplay = ({ $tracking, $appType, $forceShowCanvas }) => {
  if ($forceShowCanvas) return "block";
  else if ($appType === CONFIG.APP_TYPE.MIRROR) return "none";
  else if ($tracking) return "block";
  else return "none";
};

export let eventsSent = 0;

let event = 0;

const nativeBridge = new NativeBridge();

const clientUIMap = {
  "reaction-club": <ReactionClubFitInScreen />,
};

const Canvas = ({ showFPS = true }) => {
  const {
    tracking,
    setTracking,
    fullyVisible,
    setFullyVisible,
    setHandsPositionUp,
    handsPositionUp,
    setLieDownPosition,
    lieDownPosition,
    poseLandmarks,
    setPoseLandmarks,
    setCanvasLoaded,
    canvasLoaded,
    paused,
    timeIsUp,
    record,
    startTimer,
    complexity,
    componentData,
  } = useContext(WorkoutContext);

  const dispatch = useDispatch();
  const { stateUpdate } = useContext(WebsocketContext);
  const refVideo = useRef();
  const refCanvas = useRef();
  const screenWidth = useWindowWidth();
  const [canvasWidth, setCanvasWidth] = useState(CONFIG.CAMERA_CANVAS_WIDTH);
  const [canvasHeight, setCanvasHeight] = useState(CONFIG.CAMERA_CANVAS_HEIGHT);
  const orientation = useScreenOrientation();
  const [results, setResults] = useState([]);
  const [frameIndex, setFrameIndex] = useState(0);

  const validationType =
    componentData?.validation || CONFIG.WORKOUTFLOW_VALIDATION_TYPES.fitInScreen;

  const reducedJointsFitInScreen =
    componentData?.validation === CONFIG.WORKOUTFLOW_VALIDATION_TYPES.notFullyVisible;
  const reducedJointSetMode =
    REDUCED_JOINT_SET_MODE.includes(componentData?.type) || reducedJointsFitInScreen;
  const visibilityPass = reducedJointSetMode ? 4 : 14;

  const token = useSelector(selectToken);
  const params = useParams();
  const { appType, clientId, sessionId } = params;

  const modelComplexity = workoutDifficulties[clientId];
  const companyName = getCompanyNameFromUrl();
  const timer = useRef(null);

  //  `refVideo.current.srcObject` is the stream you want to record
  const { startRecording, stopRecording, pauseRecording, resumeRecording } =
    useMediaRecorder(
      refVideo.current?.srcObject,

      () => {
        console.log("Recording started");
        dispatch(setWaitForUpload(true));
      },

      (blob, fileName, index, ending, fileType) => {
        // Convert the blob to a file

        const videoFile = new File(
          [blob],
          `${fileName}-${sessionId}-${index}.${ending}`,
          {
            type: fileType,
          }
        );

        dispatch(
          uploadFileToServer({
            file: videoFile,
            fileName: videoFile?.name,
            companyName: companyName,
            token: token,
          })
        );
      },

      (error) => {
        dispatch(setWaitForUpload(false));
        console.error("Recording error:", error);
      }
    );

  useEffect(() => {
    if (!componentData) return;
    if (recordComponents.includes(componentData?.type) && record && startTimer) {
      startRecording(componentData);
    }
    if (!startTimer) {
      stopRecording();
    }
  }, [startTimer, componentData]);

  useEffect(() => {
    if (recordComponents.includes(componentData?.type) && record && startTimer) {
      if (paused) pauseRecording();
      else resumeRecording();
    }
  }, [paused]);

  ////// CANVAS LOADING TIMEOUT
  useEffect(() => {
    clearTimeout(timer.current);
    if (canvasLoaded) {
      nativeBridge.initiationCompleted();
      return;
    }
    timer.current = setTimeout(() => {
      dispatch(setShowError(true));
      dispatch(setErrorMessage(ERROR_MESSAGES.workout.mediapipe_load_failed));

      // Add logging to Sentry
      const sentryError = new Error(
        "Canvas loading timeout: Mediapipe failed to load within the expected time."
      );
      Sentry.captureException(sentryError, {
        extra: { timeout: CONFIG.canvas_loading_timeout },
      });
    }, CONFIG.canvas_loading_timeout * 1000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [canvasLoaded]);

  const sendEvents =
    !paused && sendEventsComponents?.includes(componentData?.type) && !timeIsUp;
  const initiation = initiationComponents?.includes(componentData?.type);
  useEffect(() => {
    if (complexity === null) return;

    const pose = new Pose({
      locateFile: (file) => {
        const fileUrl = `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${posePackage.version}/${file}`;
        console.log(`Loading MediaPipe file: ${fileUrl}`);
        return fileUrl;
      },
    });

    try {
      const calculatedModelComplexity = getModelComplexity(
        mobileModel,
        osVersion,
        complexity
      );
      console.log(
        `Used model complexity: ${modelComplexity ?? calculatedModelComplexity}`
      );
      pose.setOptions({
        modelComplexity: modelComplexity ?? calculatedModelComplexity,
        smoothLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
        selfieMode: true,
        useCpuInference: false,
      });
    } catch (error) {
      console.error("Error setting Pose options:", error);
      Sentry.captureException(error, {
        extra: { context: "Setting Pose options" },
      });
      return;
    }

    pose.onResults((results) => {
      event++;
      if (event >= 1) {
        setCanvasLoaded(true);
      }
      setEventsStarted(true);
      setResults(results);
    });

    let camera;

    try {
      camera = new Camera(refVideo?.current, {
        onFrame: async () => {
          try {
            if (refVideo?.current) {
              await pose?.send({ image: refVideo?.current });
            }
          } catch (error) {
            console.error("Error sending frame to Pose:", error);
            Sentry.captureException(error, {
              extra: { context: "Sending frame to Pose" },
            });
            pose?.reset();
          }
        },
        width: CONFIG.CAMERA_CANVAS_WIDTH,
        height: CONFIG.CAMERA_CANVAS_HEIGHT,
      });

      camera
        .start()
        .then(() => {
          console.log("Camera started successfully");
          console.log("Camera stream:", refVideo?.current?.srcObject);
        })
        .catch((error) => {
          console.error("Camera failed to start:", error);
          Sentry.captureException(error, {
            extra: { context: "Starting camera" },
          });
        });
    } catch (error) {
      console.error("Error initializing camera:", error);
      Sentry.captureException(error, {
        extra: { context: "Initializing camera" },
      });
      return;
    }

    return () => {
      event = 0;
      let stream = camera?.video?.srcObject;
      if (stream) {
        const tracks = stream?.getTracks();
        tracks?.forEach((track) => track?.stop());
        camera.video.srcObject = null;
      }
    };
  }, [complexity]);

  useEffect(() => {
    if (!results) return;

    if (results?.poseLandmarks == null) {
      setPoseLandmarks([]);
      setFullyVisible(false);
    } else {
      setPoseLandmarks(results?.poseLandmarks);
    }

    setFrameIndex((frameIndex + 1) % CONFIG.SEND_REQUEST_EVERY_FRAME);

    if (!results?.poseLandmarks) {
      setTracking(false);
      return;
    }
    const parameters = {
      event_type: CONFIG.EVENT_TYPES.state_update,
      data: {
        landmarks: results.poseLandmarks,
        worldLandmarks: results.poseWorldLandmarks,
      },
      company_name: clientId,
    };

    if (
      frameIndex === 0 &&
      poseLandmarks?.slice(11).filter(({ visibility }) => visibility > 0.1).length >=
        visibilityPass
    )
      setFullyVisible(
        checkIfFullyVisible(poseLandmarks, initiation, reducedJointSetMode)
      );

    if (
      componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.cameraSetupValidation
    ) {
      setHandsPositionUp(checkIfHandsAreUp(results.poseLandmarks, fullyVisible));
      setLieDownPosition(checkIfLyingDown(results.poseLandmarks, fullyVisible));
    }
    const shouldUpdateState =
      (!showFPS || (sendEvents && stateUpdate)) && eventsLimiter();

    // Perform state update if conditions are met
    if (shouldUpdateState) {
      stateUpdate(parameters);
      eventsSent += 1;
    }

    if (!tracking) {
      checkCanvasSize();
      setTracking(true);
    }

    if (displayCanvasFeature && results?.image) draw(results);
  }, [frameIndex, tracking, results]);

  useEffect(() => {
    reverseCanvasSize(orientation);
  }, [orientation]);

  const draw = (results) => {
    const poseLandmarks = results?.poseLandmarks?.map((landmark, index) =>
      (index > 10 && index < 17) || index > 22 ? landmark : { ...landmark, visibility: 0 }
    );

    const poseConnections = POSE_CONNECTIONS?.filter((pair) =>
      pair.every((landmark) => (landmark > 10 && landmark < 17) || landmark > 22)
    ).slice(0, -2);

    const canvasCtx = refCanvas?.current?.getContext("2d");
    if (!canvasCtx) return;

    canvasCtx?.save();
    canvasCtx?.clearRect(0, 0, refCanvas.current.width, refCanvas.current.height);
    canvasCtx?.drawImage(
      results.image,
      0,
      0,
      refCanvas.current.width,
      refCanvas.current.height
    );

    drawConnectors(canvasCtx, poseLandmarks, poseConnections, {
      color: canvasColor(initiation, fullyVisible, CONFIG.CANVAS_PARTS.connector),
      lineWidth: 4,
    });

    drawLandmarks(canvasCtx, poseLandmarks, {
      color: canvasColor(initiation, fullyVisible, CONFIG.CANVAS_PARTS.joint),
      lineWidth: 4,
    });
    canvasCtx.restore();
  };

  const canvasColor = (initiation, fullyVisible, part) => {
    if (part === CONFIG.CANVAS_PARTS.joint) {
      if (initiation && !validationChecks[validationType])
        return CONFIG.colors.badPositionJoints;
      if (initiation && validationChecks[validationType])
        return CONFIG.colors.handsUpJoints;
      if (fullyVisible) return CONFIG.colors.joints;
      if (!fullyVisible) return CONFIG.colors.badPositionJoints;
    } else if (part === CONFIG.CANVAS_PARTS.connector) {
      if (initiation && !validationChecks[validationType])
        return CONFIG.colors.badPositionConnectors;
      if (initiation && validationChecks[validationType])
        return CONFIG.colors.handsUpConnectors;
      if (fullyVisible) return CONFIG.colors.connectors;
      if (!fullyVisible) return CONFIG.colors.badPositionConnectors;
    }
  };

  const validationChecks = {
    [CONFIG.WORKOUTFLOW_VALIDATION_TYPES.fitInScreen]: fullyVisible,
    [CONFIG.WORKOUTFLOW_VALIDATION_TYPES.handsUp]: handsPositionUp,
    [CONFIG.WORKOUTFLOW_VALIDATION_TYPES.lieDown]: lieDownPosition,
    [CONFIG.WORKOUTFLOW_VALIDATION_TYPES.notFullyVisible]: fullyVisible,
  };

  const checkCanvasSize = () => {
    if (!refVideo.current) return;
    console.log(
      "Video element dimensions:",
      refVideo.current.clientWidth,
      refVideo.current.clientHeight
    );
    console.log("Canvas element dimensions:", canvasWidth, canvasHeight);

    if (refVideo.current.clientHeight === 0 && refVideo.current.clientWidth === 0) return;

    if (refVideo.current.clientWidth !== canvasWidth) {
      setNewCanvasSize();
    }
  };

  const reverseCanvasSize = () => {
    const oldCanvasHeight = canvasWidth;
    const oldCanvasWidth = canvasHeight;
    setCanvasWidth(oldCanvasWidth);
    setCanvasHeight(oldCanvasHeight);
  };

  const setNewCanvasSize = () => {
    setCanvasWidth(refVideo.current.clientWidth);
    setCanvasHeight(refVideo.current.clientHeight);
  };

  return (
    <VideoContainer className="video container">
      {showFPS ? <FPSCounter /> : null}
      <VideoEl
        $fullyVisible={fullyVisible}
        $initiation={initiation}
        $validationCheck={validationChecks[validationType]}
        playsInline={true}
        crossOrigin="anonymous"
        ref={refVideo}
        $tracking={tracking}
        $appType={appType}
        $screenWidth={screenWidth}
        $forceShowVideo={forceShowVideoFeature}
      />
      <CanvasEl
        data-testid={fullyVisible ? "green" : "red"}
        $fullyVisible={fullyVisible}
        $validationCheck={validationChecks[validationType]}
        $initiation={initiation}
        $appType={appType}
        $tracking={tracking}
        ref={refCanvas}
        width={canvasWidth + "px"}
        height={canvasHeight + "px"}
        $screenWidth={screenWidth}
        $forceShowCanvas={forceShowCanvasFeature}
      />

      {initiation && fullyVisible ? <CameraValidation /> : null}
      {initiation && !fullyVisible
        ? clientId in clientUIMap
          ? clientUIMap[clientId]
          : null
        : null}
    </VideoContainer>
  );
};

export default Canvas;
