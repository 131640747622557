import { useContext } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { getCompanyNameFromUrl } from "../../../../utils/urls";
import clients from "../../../../constants/clients";
import { css, styled } from "@mui/material";

const ExerciseContainer = styled("div")`
  min-width: 57px;
  min-height: 12px;
  background: rgba(140, 140, 140, 0.4);
  backdrop-filter: blur(124px);
  border-radius: 40px;
  padding: 4px;
`;

const ExerciseText = styled("p")`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  text-transform: capitalize;
  overflow-wrap: normal;
  max-width: 100px;
  color: #d1ffd8;
`;

const Exercise = () => {
  const { componentData } = useContext(WorkoutContext);
  const companyName = getCompanyNameFromUrl();
  const exerciseName = componentData?.exerciseName;

  const { styles } = clients(companyName);

  return (
    <ExerciseContainer>
      <ExerciseText>{exerciseName ? exerciseName : "Reps"}</ExerciseText>
    </ExerciseContainer>
  );
};

export default Exercise;
