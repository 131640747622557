import { Checkbox, FormControlLabel, TextField, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/Buttons/Button";
import {
  selectEmail,
  selectEmailUsageAgreement,
  setEmail,
  setEmailUsageAgreement,
} from "../../../../../store/fit/quiz";

const Wrapper = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 420px;
  width: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 40px;
`;

const EmailForm = ({ setQuizProgress, quizProgress }) => {
  const dispatch = useDispatch();
  const checked = useSelector(selectEmailUsageAgreement);
  const email = useSelector(selectEmail);

  const submitHandler = (e) => {
    const formElements = e.target.elements;
    e.preventDefault();
    dispatch(setEmail(formElements[0].value));
    dispatch(setEmailUsageAgreement(formElements[1].checked));
    setQuizProgress(() => quizProgress + 1);
  };

  const checkboxClickHandler = () => {
    dispatch(setEmailUsageAgreement(!checked));
  };

  return (
    <Wrapper onSubmit={submitHandler}>
      <TextField
        type="email"
        defaultValue={email}
        label="Email"
        variant="outlined"
      />
      <StyledFormControlLabel
        control={
          <Checkbox
            defaultChecked
            value={checked}
            onClick={checkboxClickHandler}
          />
        }
        label="I agree to receive emails"
      />
      <Button type="submit" size="large">
        NEXT
      </Button>
    </Wrapper>
  );
};

export default EmailForm;
