import * as React from "react";

const ChevronLeftIcon = (props) => (
  <svg
    width={10}
    height={17}
    viewBox="0 0 10 17"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.096 17a1.37 1.37 0 0 1-.56-.117 1.288 1.288 0 0 1-.45-.332L.827 9.266a1.168 1.168 0 0 1-.295-.771c0-.282.104-.554.295-.771L7.305.439c.22-.248.536-.404.879-.434.342-.03.683.07.948.276.265.206.43.502.463.823.031.321-.075.64-.295.889L3.51 8.5l5.596 6.508a1.147 1.147 0 0 1 .175 1.299c-.107.209-.275.385-.485.508-.21.123-.453.187-.7.184Z" />
  </svg>
);

export default ChevronLeftIcon;
