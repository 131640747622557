import { Button as MuiButton } from "@mui/material";
import SmartLink from "../../Primitives/SmartLink";

const Button = ({ children, ...rest }) => (
  <MuiButton component={SmartLink} variant="outlined" {...rest}>
    {children}
  </MuiButton>
);

export default Button;
