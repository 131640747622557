import styled from "styled-components";
import { FPSDisplay } from "../../../../utils/limiter";
import { displayFpsFeature } from "../../../../utils/features";
import { useContext } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";

const FpsWrapper = styled.div`
  padding: 0px 5px 0px 5px;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
  min-width: 50px;
  transform: translate(-50%);
`;
const StyledFps = styled.p`
  font-size: 40px;
  color: #ff0000;
`;

const FPSCounter = () => {
  const { ping } = useContext(WorkoutContext);

  if (!displayFpsFeature) return null;
  return (
    <FpsWrapper>
      <StyledFps>{`${FPSDisplay} EPS, ${ping} ms`}</StyledFps>
    </FpsWrapper>
  );
};

export default FPSCounter;
