import { styled } from "@mui/material";
import { CameraIcon } from "../../../../../components/Icons";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../../components/Buttons/Button";
import WorkoutLockModal from "./WorkoutLockModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectWorkoutName } from "../../../../../store/fit/workout";
import {
  selectSessionId,
  selectWorkoutId,
  selectWorkouts,
} from "../../../../../store/fit/selection";
import {
  selectCookieId,
  selectEnteredName,
} from "../../../../../store/fit/quiz";
import xhrRequest from "../../../../../api/xhrRequest";
import { BE_AWS_URL } from "../../../../../api";
import useAuth from "../../../../../hooks/useAuth";
import CompetitionRegistration from "../../../CompetitionRegistration";
import { competitionClients } from "../../../../../constants/clients";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`;

const Text = styled("p")`
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 4px;
`;

const TextContainer = styled("div")`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 420px;
`;

const Footer = () => {
  const { appType, clientId } = useParams();
  const sessionId = useSelector(selectSessionId);
  const workoutId = useSelector(selectWorkoutId);
  const activeWorkout = useSelector(selectWorkoutName);
  const workouts = useSelector(selectWorkouts);
  const navigate = useNavigate();
  const cookieId = useSelector(selectCookieId);
  const enteredUsername = useSelector(selectEnteredName);
  const [isLockModalOpen, setIsLockModalOpen] = useState(false);

  const { name, username } = useAuth();

  const [competitionSignUp, setCompetitionSignUp] = useState(false);

  const sentWorkoutRegisterData = async () => {
    try {
      xhrRequest({
        url: `${BE_AWS_URL}/startWorkoutSession`,
        method: "POST",
        data: {
          cookieId: cookieId,
          companyName: clientId,
          workoutId: workoutId,
          sessionId: sessionId,
          username: username,
          name: enteredUsername,
        },
        tryAuth: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleStart = () => {
    if (!workouts) return;
    if (workouts.find(({ name }) => name === activeWorkout)?.isProtected) {
      setIsLockModalOpen(true);
    } else if (competitionClients.includes(clientId)) {
      setCompetitionSignUp(true);
    } else {
      startWorkout();
    }
  };

  const startWorkout = () => {
    sentWorkoutRegisterData();
    navigate(`/${appType}/${clientId}/${workoutId}/${sessionId}`);
  };

  const handleCloseLockModal = () => {
    setIsLockModalOpen(false);
  };

  return (
    <Wrapper>
      {competitionSignUp && (
        <CompetitionRegistration
          startWorkout={startWorkout}
          setCompetitionSignUp={setCompetitionSignUp}
        />
      )}
      <WorkoutLockModal
        isOpen={isLockModalOpen}
        handleClose={handleCloseLockModal}
      />
      <TextContainer>
        <Text>
          Make sure to connect
          <br /> your webcam!
        </Text>
        <CameraIcon width={11} height={7} fill="white" />
      </TextContainer>
      {workouts && workouts.length ? (
        <StyledButton size="large" onClick={handleStart}>
          START
        </StyledButton>
      ) : null}
    </Wrapper>
  );
};

export default Footer;
