const TapIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M9 12c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm-2 2c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm4 0c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm2-2c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm2 2c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm-8 4c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm4 0c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm2-2c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm2 2c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm-8 2c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm4 0c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1z"
      />
    </svg>
  );
};

export default TapIcon;
