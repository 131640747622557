import { createSlice } from "@reduxjs/toolkit";

// Reducer
const volumeSlice = createSlice({
  name: "volume",
  initialState: {
    voiceVolume: 1,
    indicatorVolume: 0.15,
    repetitionVolume: 1,
    startStopVolume: 1,
  },

  reducers: {
    setVoiceVolume: (state, action) => {
      state.voiceVolume = action.payload;
    },
    setIndicatorVolume: (state, action) => {
      state.indicatorVolume = action.payload;
    },
    setRepetitionVolume: (state, action) => {
      state.repetitionVolume = action.payload;
    },
    setStartStopVolume: (state, action) => {
      state.repetitionVolume = action.payload;
    },
  },
});

export const {
  setVoiceVolume,
  setIndicatorVolume,
  setRepetitionVolume,
  setStartStopVolume,
} = volumeSlice.actions;

//Selectors

export const selectVoiceVolume = (state) => state?.fit?.volume?.voiceVolume;
export const selectIndicatorVolume = (state) =>
  state?.fit?.volume?.indicatorVolume;
export const selectRepetitionVolume = (state) =>
  state?.fit?.volume?.repetitionVolume;
export const selectStartStopVolume = (state) =>
  state?.fit?.volume?.startStopVolume;

const volumeReducer = volumeSlice.reducer;

export default volumeReducer;
