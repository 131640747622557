import { useContext } from "react";
import WorkoutContext from "../../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import styled from "styled-components";
import { selectChallengeRepCount } from "../../../../../../store/fit/challenge";
import { useSelector } from "react-redux";
import { CONFIG } from "../../../../../../config";

const RepCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  min-width: 115px;
  color: #a6d5fa;
`;

const CurrentRepsText = styled.p`
  font-size: 50px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
`;

const TotalRepsText = styled.p`
  font-size: 30px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const ChallengeScoreContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  min-width: 115px;
  color: #a6d5fa;
`;

const ChallengeScoreText = styled.p`
  font-size: 50px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
`;

const RepsNumber = ({ type }) => {
  const { repCount, componentData, challengeMode } = useContext(WorkoutContext);
  const challengeReps = useSelector(selectChallengeRepCount);

  const totalReps = componentData?.totalReps;
  const componentType = componentData?.type;
  const timedExercise =
    componentType === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise && totalReps === 0;

  const renderChallengeReps =
    challengeMode &&
    type === "challenger" &&
    (componentType === "enduranceExercise" || timedExercise);

  if (renderChallengeReps)
    return (
      <ChallengeScoreContainer>
        <ChallengeScoreText>{challengeReps}</ChallengeScoreText>
      </ChallengeScoreContainer>
    );

  return (
    <>
      <RepCountContainer data-testid={"exerciseRepsTest"}>
        <CurrentRepsText>{repCount}</CurrentRepsText>
        {totalReps ? <>/</> : null}
        {totalReps ? <TotalRepsText>{totalReps}</TotalRepsText> : null}
      </RepCountContainer>
    </>
  );
};

export default RepsNumber;
