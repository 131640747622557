const expiryDate = new Date(new Date().getTime() + 365 * 86400000); // a year

/**
 *
 * @param name
 * @param value
 * @param days
 * @param path
 * @param domain
 * @param expiresTimestamp Timestamp in ms (13 digits) that defines expires
 */
export function setCookie(
  name = "",
  value = "",
  days = 0,
  path = "/",
  domain = "",
  expiresTimestamp = null
) {
  const isSSR = typeof window === "undefined";

  if (!isSSR) {
    let expires = "";

    if (expiresTimestamp !== null) {
      const date = new Date(expiresTimestamp);
      expires = `; expires=${date.toUTCString()}`;
    } else if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    } else {
      expires = `; expires=${expiryDate}`;
    }

    if (domain) {
      domain = `; domain=${domain}`;
    }

    document.cookie = `${name}=${value}${expires}${domain}; path=${path}`;
  }
}

/**
 * @param name
 * @returns {string|null}
 */
export function getCookie(name) {
  const isSSR = typeof window === "undefined";

  if (!isSSR) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } else {
    return null;
  }
}

/**
 * @param name
 */
export function deleteCookie(name) {
  setCookie(name, "", -1);
}
