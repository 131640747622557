import { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material";
import { CameraIcon } from "../../../../components/Icons";
import { MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";
import WorkoutContext from "../WorkoutContext";
import { getCompanyNameFromUrl } from "../../../../utils/urls";
import ReactionClubCameraAccess from "../../../../constants/clientsStyles/ReactionClubWorkoutUI/ReactionClubCameraAccess";
import Button from "../../../../components/Buttons/Button";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  width: 90%;
  max-height: -webkit-fill-available;
  max-width: 400px;
  margin: 0 auto;
`;

const StyledText = styled("h2")`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  text-align: center;
  margin-top: 40px;
`;

const IconWrapper = styled("div")`
  max-width: 150px;
  margin: 0 auto;
`;

const ErrMsg = styled("p")`
  margin-top: 20px;
  color: white;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 420px;
`;

const clientUIMap = {
  "reaction-club": <ReactionClubCameraAccess />,
};

const CameraAccessScreen = () => {
  const { setCameraAccess } = useContext(WorkoutContext);
  const [errMsg, setErrMsg] = useState("");
  const clientName = getCompanyNameFromUrl();

  const handlePermissionRequest = () => {
    requestMediaPermissions({ video: true, audio: false })
      .then(() => {
        setCameraAccess(true);
        console.log("Camera access granted");
      })
      .catch((err) => {
        const { type } = err;
        setCameraAccess(false);

        switch (type) {
          case MediaPermissionsErrorType.SystemPermissionDenied:
            setErrMsg(
              "Could not start video source. Browser does not have permission to access camera."
            );
            break;
          case MediaPermissionsErrorType.UserPermissionDenied:
            setErrMsg("Could not start video source. User permission denied.");
            break;
          case MediaPermissionsErrorType.CouldNotStartVideoSource:
            setErrMsg(
              "Could not start video source. Check if another app or window is using camera."
            );
            break;
          default:
            setErrMsg("An unknown error occurred. Please try again.");
            break;
        }
      });
  };

  useEffect(() => {
    handlePermissionRequest();
  }, []);

  if (clientName in clientUIMap) {
    return clientUIMap[clientName];
  }

  return (
    <Wrapper>
      <IconWrapper>
        <CameraIcon width={"100%"} height={200} />
      </IconWrapper>
      <StyledText>Please turn on camera access to start workout.</StyledText>

      {errMsg && <ErrMsg>{errMsg}</ErrMsg>}
      <ErrMsg>
        If this message persists after giving permissions, please reload the page.
      </ErrMsg>
    </Wrapper>
  );
};

export default CameraAccessScreen;
