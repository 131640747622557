import React from "react";
import { CarouselProvider } from "pure-react-carousel";
import InnerCarouselWithStore from "./InnerCarouselWithStore";
import carouselStyles from "../../../../styles/carouselStyles";
import {
  selectWorkoutSummary,
  selectWorkoutSummaryLoading,
} from "../../../../store/fit/summary";
import { useSelector } from "react-redux";
import { Skeleton, styled } from "@mui/material";

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  ${carouselStyles}
`;

const StyledSkeleton = styled(Skeleton)`
  max-width: 480px;
  max-height: 310px;
  height: 350px;
  width: 90%;
  margin: auto;
  border-radius: 30px;
`;

const TipsCarousel = () => {
  const { exerciseTips } = useSelector(selectWorkoutSummary) || {};
  const summaryLoading = useSelector(selectWorkoutSummaryLoading);
  const step = 1;

  return (
    <Wrapper>
      {summaryLoading ? (
        <StyledSkeleton variant="rectangular" />
      ) : (
        <CarouselProvider
          isPlaying={true}
          naturalSlideWidth={164}
          naturalSlideHeight={270}
          totalSlides={(exerciseTips && exerciseTips.length) || 0}
          visibleSlides={1}
          step={step}
          infinite={true}
          currentSlide={0}
        >
          <InnerCarouselWithStore />
        </CarouselProvider>
      )}
    </Wrapper>
  );
};

export default TipsCarousel;
