import styled from "styled-components";
import Progress from "./Progress";
import RepsNumber from "./RepsNumber";
import { showMobileReps } from "../mobileStatsConfig";
import ExerciseName from "../ExerciseName";
import { CONFIG } from "../../../../../config";
import { useContext } from "react";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  height: min-content;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  bottom: 0;
  background: #ffffff00;
  background: linear-gradient(180deg, #ffffff00, #01010180);
  align-items: center;
`;

const RepsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
`;

const MobileRepCount = () => {
  const { componentData, challengeMode } = useContext(WorkoutContext);
  const componentType = componentData?.type;
  const totalReps = componentData?.totalReps;
  const enduranceExercise =
    componentType === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise;
  const timedExercise = CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise && totalReps === 0;
  const showChallengeRepcount = challengeMode && (enduranceExercise || timedExercise);
  return (
    <Wrapper>
      {componentType !== CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise &&
        componentType !== CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise && (
          <ExerciseName />
        )}
      {showMobileReps?.includes(componentType) && (
        <RepsWrapper>
          <RepsNumber />
          {showChallengeRepcount && <RepsNumber type={"challenger"} />}
          <Progress />
        </RepsWrapper>
      )}
    </Wrapper>
  );
};

export default MobileRepCount;
