import React from "react";
import { Button, styled } from "@mui/material";
import { withAuthenticator, Authenticator } from "@aws-amplify/ui-react";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 64px;
  background-color: black;
`;

const Text = styled("p")`
  color: white;
`;

const Testing = () => {
  return (
    <Wrapper>
      <Text>CONTENT Visible all the time</Text>
      <br />
      <br />
      <br />
      <Authenticator>
        {({ signOut, userAttributes, currentAuthenticatedUser, user }) => (
          <div>
            <Text>Logged in CONTENT</Text>
            <Text>Welcome {user.username}</Text>
            <Button variant="outlined" onClick={signOut}>
              Sign out
            </Button>
          </div>
        )}
      </Authenticator>
    </Wrapper>
  );
};

export default Testing;
