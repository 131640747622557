import { LinearProgress, styled } from "@mui/material";
import { useContext } from "react";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { showStaticTimerBar } from "../mobileStatsConfig";
import ExerciseName from "../ExerciseName";
import StaticTimerProgress from "./StaticTimerProgress";
import { CONFIG } from "../../../../../config";

const Wrapper = styled("div")`
  position: absolute;
  width: 100%;
  display: flex;
  height: min-content;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  bottom: 0;
  background: #ffffff00;
  background: linear-gradient(180deg, #ffffff00, #01010180);
  align-items: center;
`;

const TimerBarWrapper = styled("div")`
  display: flex;
  width: 100%;
  align-items: flex-end;
`;

const LinearProgressWrapper = styled("div")`
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
`;

const MobileStaticTimer = ({ staticExpiryTimestamp, startCountdown }) => {
  const { componentData } = useContext(WorkoutContext);

  return (
    <Wrapper>
      {(componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise ||
        componentData?.type ===
          CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise) && <ExerciseName />}
      {showStaticTimerBar?.includes(componentData?.type) && (
        <TimerBarWrapper>
          <LinearProgressWrapper>
            {startCountdown ? (
              <StaticTimerProgress
                expiryTimestamp={staticExpiryTimestamp}
                startCountdown={startCountdown}
              />
            ) : (
              <LinearProgress
                sx={{
                  width: "100%",
                  height: "25px",
                  clipPath: "polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);",
                  color: "#54B862",
                }}
                color="inherit"
                variant="determinate"
                value={0}
              />
            )}
          </LinearProgressWrapper>
        </TimerBarWrapper>
      )}
    </Wrapper>
  );
};

export default MobileStaticTimer;
