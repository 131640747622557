// Function to find the longest common prefix
export const findLongestCommonPrefix = (strs) => {
  if (!strs.length) return "";

  // Sort the array
  strs.sort();

  const first = strs[0];
  const last = strs[strs.length - 1];
  let i = 0;

  // Compare characters until they don't match
  while (i < first.length && first.charAt(i) === last.charAt(i)) {
    i++;
  }

  // Return the common prefix
  return first.substring(0, i);
};
