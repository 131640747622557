import { useEffect, useState } from "react";
import { styled, css } from "@mui/material";
import { TimerIcon } from "../../../../../../../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  resetState,
  selectWorkoutName,
  setWorkoutName,
} from "../../../../../../../store/fit/workout";
import {
  selectWorkouts,
  setWorkoutId,
} from "../../../../../../../store/fit/selection";
import Button from "../../../../../../../components/Buttons/Button";

const CardWrapper = styled("div")`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const Card = styled(Button)`
  display: flex;
  position: relative;
  justify-content: flex-end;
  flex-direction: column;
  min-width: 140px;
  min-height: 290px;
  width: 90%;
  background: rgba(217, 217, 217, 0.3);
  /* box-shadow: inset 0px 0px 3px 5px rgba(0, 0, 0, 0.25); */
  border-radius: 30px !important;
  overflow: hidden;
  transition: all 0.3s !important;
  border: ${({ activeWorkout, theme }) =>
    activeWorkout
      ? `2px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.primary.main}`} !important;
  transform: ${({ activeWorkout }) =>
    activeWorkout ? "scale(1.15)" : "unset"};
  ${({ $img }) => css`
    background-image: url(${$img});
  `}

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const ExerciseNameWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 55px;
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 30px 30px;
  background-color: rgba(18, 2, 11, 0.35);
  position: absolute;
  bottom: 0px;
`;

const ExerciseText = styled("h2")`
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
`;

const TimeContainer = styled("div")`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 12px;
  right: 12px;
  min-width: 40px;
  min-height: 24px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 150px;
  border: 1px solid rgba(144, 202, 249, 0.5);
`;

const TimeNumber = styled("p")`
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 1px;
`;

const TimeText = styled("p")`
  font-weight: 700;
  font-size: 6px;

  text-align: center;
  color: rgba(255, 255, 255, 0.8);
`;

const TimeWrapper = styled("div")`
  display: flex;
  align-items: baseline;
  gap: 1px;
`;

const WorkoutCard = ({ workoutName, time, id, onClick, index }) => {
  const workout = useSelector(selectWorkoutName);
  const workouts = useSelector(selectWorkouts);

  const [activeWorkout, setActiveWorkout] = useState(false);

  const dispatch = useDispatch();

  const setWorkoutNameAndID = (workoutName, id) => {
    dispatch(setWorkoutName(workoutName));
    dispatch(setWorkoutId(id));
  };

  useEffect(() => {
    if (index === 0) {
      setWorkoutNameAndID(workoutName, id);
    }
  }, []);

  useEffect(() => {
    if (workout === workoutName) setActiveWorkout(true);
    else setActiveWorkout(false);
  }, [workout]);

  const workoutData = workouts.find((x) => x.name === workoutName);

  return (
    <CardWrapper
      onClick={() => {
        setWorkoutNameAndID(workoutName, id);
        onClick();
      }}
    >
      <Card activeWorkout={activeWorkout} $img={workoutData?.imageData.s}>
        <TimeContainer>
          <TimeWrapper>
            <TimerIcon width={6} height={6} fill={"white"} />
            <TimeNumber>{Math.round(time / 60)}</TimeNumber>
            <TimeText>min</TimeText>
          </TimeWrapper>
        </TimeContainer>
        <ExerciseNameWrapper>
          <ExerciseText>{workoutName}</ExerciseText>
        </ExerciseNameWrapper>
      </Card>
    </CardWrapper>
  );
};

export default WorkoutCard;
