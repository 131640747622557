import { CONFIG } from "../config";

const statusText = {
  notVisible: "Make sure you are fully visible",
  handsDown: '"Put your hands up to start!"',
  reducedJointSetMode: "Make sure you are visible",
};

export const getStatusText = (poseLandmarks, initiation, reducedJointSetMode = false) => {
  const isFullyVisible = checkIfFullyVisible(
    poseLandmarks,
    initiation,
    reducedJointSetMode
  );
  if (!initiation) {
    if (reducedJointSetMode && !isFullyVisible) return statusText.reducedJointSetMode;
    else if (!isFullyVisible) return statusText.notVisible;

    return false;
  }

  if (initiation) {
    if (!isFullyVisible) return statusText.notVisible;
    return statusText.handsDown;
  }
};

export const checkIfHandsAreUp = (landMarks, visible) => {
  if (!visible) return false;
  return (
    landMarks[9].y > landMarks[13].y &&
    landMarks[10].y > landMarks[14].y &&
    landMarks[14].y > landMarks[16].y &&
    landMarks[13].y > landMarks[15].y
  );
};

export const checkIfLyingDown = (landmarks, visible) => {
  if (!visible) return false;

  // Extract all Y and X coordinates of landmarks
  const allYCoordinates = landmarks.map((point) => point.y);
  const allXCoordinates = landmarks.map((point) => point.x);

  // Calculate distances
  const v_distance = Math.max(...allYCoordinates) - Math.min(...allYCoordinates);
  const h_distance = Math.max(...allXCoordinates) - Math.min(...allXCoordinates);

  // Check if h_distance is greater than v_distance
  return h_distance > v_distance;
};

export const checkIfFullyVisible = (
  landMarks,
  initiation,
  reducedJointSetMode = false
) => {
  const upperBodyJoints = [11, 12, 13, 14, 15, 16]; // Shoulder, Elbow, and Hand joints
  const faceJoints = [0, 1, 2, 3, 4, 5, 6, 7]; // Example indices for face landmarks (eyes, ears, nose, mouth)

  if (reducedJointSetMode) {
    // Define the minimum number of joints that need to be visible
    const minVisibleUpperBodyJoints = 4;
    const minVisibleFaceJoints = 1;

    // Count the number of visible upper body joints
    const visibleUpperBodyJointsCount = upperBodyJoints.reduce((count, index) => {
      const { x, y } = landMarks[index] || {};
      return count + (x > 0 && y > 0 && x < 1 && y < 1 ? 1 : 0);
    }, 0);

    // Count the number of visible face joints
    const visibleFaceJointsCount = faceJoints.reduce((count, index) => {
      const { x, y } = landMarks[index] || {};
      return count + (x > 0 && y > 0 && x < 1 && y < 1 ? 1 : 0);
    }, 0);

    // Check if the number of visible joints meets the minimum threshold
    return (
      visibleUpperBodyJointsCount >= minVisibleUpperBodyJoints &&
      visibleFaceJointsCount >= minVisibleFaceJoints
    );
  }

  return (
    landMarks?.slice(11).filter(({ x, y }) => x > 0 && y > 0 && x < 1 && y < 1).length >
    setVisibleJoints(initiation)
  );
};
const setVisibility = (initiation) => {
  return initiation ? 0.5 : 0.16;
};

const setVisibleJoints = (initiation) => {
  return initiation
    ? CONFIG.VISIBILITY_DIFFICULTY.joints.hard
    : CONFIG.VISIBILITY_DIFFICULTY.joints.easy;
};
