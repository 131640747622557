import { CircularProgress, styled } from "@mui/material";
import { useContext } from "react";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";

const ProgressContainer = styled("div")`
  color: #f5f5f5;
  position: relative;
  min-width: 100px;
  min-height: 100px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(74, 63, 22, 1) 0%,
    rgba(244, 107, 107, 0.006) 100%
  );
`;

const RepCountContainer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CurrentRepsText = styled("p")`
  font-size: 70px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
`;

const TotalRepsText = styled("p")`
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const ReactionProgress = () => {
  const { repCount, componentData } = useContext(WorkoutContext);

  const totalReps = componentData?.totalReps;

  return (
    <ProgressContainer data-testid={"exerciseRepsTest"}>
      {totalReps ? (
        <CircularProgress
          color="inherit"
          style={{ minWidth: 130, minHeight: 130 }}
          variant="determinate"
          value={(repCount / totalReps) * 100}
        />
      ) : null}
      <RepCountContainer>
        <CurrentRepsText>{repCount}</CurrentRepsText>
        {totalReps ? <>/</> : null}
        {totalReps ? <TotalRepsText>{totalReps}</TotalRepsText> : null}
      </RepCountContainer>
    </ProgressContainer>
  );
};
export default ReactionProgress;
