import styled from "styled-components";
import Canvas from "../../../../components/WorkoutComponents/Canvas";
import RepCount from "../../../../components/WorkoutComponents/RepCount";
import CornerScore from "../../../../components/WorkoutComponents/CornerScore";
import { useParams } from "react-router";
import clients from "../../../../constants/clients";

const Wrapper = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
`;

const FitCamera = () => {
  const { clientId } = useParams();
  const iframe = true;
  const { settings } = clients(clientId);

  return (
    <Wrapper>
      {settings?.showRepCount && <RepCount />}
      {settings?.showScore && <CornerScore />}
      <Canvas showFPS={false} iframe={iframe} />
    </Wrapper>
  );
};
export default FitCamera;
