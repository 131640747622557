import { styled } from "@mui/material";

import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

import useWindowWidth from "../../../../../../hooks/useWindowWidth";
import { CONFIG } from "../../../../../../config";
import Button from "../../../../../../components/Buttons/Button";
import clients from "../../../../../../constants/clients";
import { getCompanyNameFromUrl } from "../../../../../../utils/urls";
import { useParams } from "react-router-dom";
import useAuth from "../../../../../../hooks/useAuth";

const MenuStyledButton = styled(Button)`
  white-space: nowrap;
  height: min-content;
  width: 100%;
`;

const LoginButton = () => {
  const breakpoint = useWindowWidth() < CONFIG.SelectionScreenBreakpoint;
  const clientName = getCompanyNameFromUrl();

  const { requireAuth } = clients(clientName);
  const { signOut, status } = useAuth();

  const { appType, clientId } = useParams();

  return status === "authenticated" && requireAuth ? (
    <MenuStyledButton endIcon={!breakpoint && <LogoutIcon />} onClick={signOut}>
      {breakpoint ? <LogoutIcon /> : <p>Log out</p>}
    </MenuStyledButton>
  ) : (
    requireAuth && (
      <MenuStyledButton
        to={`/${appType}/${clientId}/auth`}
        endIcon={!breakpoint && <LoginIcon />}
      >
        {breakpoint ? <LoginIcon /> : <p>Login</p>}
      </MenuStyledButton>
    )
  );
};

export default LoginButton;
