import React from "react";
import { CarouselProvider } from "pure-react-carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  selectWorkouts,
  selectWorkoutsLoading,
} from "../../../../../../store/fit/selection";
import { Skeleton, styled } from "@mui/material";
import InnerCarouselWithStore from "./InnerCarouselWithStore";
import carouselStyles from "../../../../../../styles/carouselStyles";
import { useEffect } from "react";
import {
  setErrorMessage,
  setShowError,
} from "../../../../../../store/fit/error";
import { ERROR_MESSAGES } from "../../../../ErrorScreen/errors";

const StyledSkeleton = styled(Skeleton)`
  max-width: 550px;
  max-height: 350px;
  margin: auto;
  border-radius: 30px;
`;

const WorkoutsWrapper = styled("div")`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  ${carouselStyles}
`;

const SliderComponent = () => {
  const step = 1;
  const workouts = useSelector(selectWorkouts);
  const workoutsLoading = useSelector(selectWorkoutsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (workouts && workouts?.length == 0) {
      dispatch(setShowError(true));
      dispatch(setErrorMessage(ERROR_MESSAGES.selection.noWorkouts));
    }
  }, [workouts]);

  return (
    <WorkoutsWrapper>
      {workoutsLoading ? (
        <StyledSkeleton variant="rectangular" height={350} width="90%" />
      ) : (
        workouts && (
          <CarouselProvider
            isPlaying={true}
            naturalSlideWidth={164}
            naturalSlideHeight={290}
            totalSlides={(workouts && workouts.length) || 0}
            visibleSlides={1}
            step={step}
            infinite={true}
            currentSlide={0}
          >
            <InnerCarouselWithStore />
          </CarouselProvider>
        )
      )}
    </WorkoutsWrapper>
  );
};

export default SliderComponent;
