import { createSlice } from "@reduxjs/toolkit";
import xhrRequest from "../../api/xhrRequest";
import { BE_AWS_URL } from "../../api";

const accountSlice = createSlice({
  name: "account",
  initialState: {
    user: undefined,
    userLoading: undefined,
    uploading: false,
    gettingUrl: false,
    waitForUpload: false,
    startedUploadingProcess: false,
  },

  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserLoading: (state, action) => {
      state.userLoading = action.payload;
    },
    setUploading: (state, action) => {
      state.uploading = action.payload;
    },
    setGettingUrl: (state, action) => {
      state.gettingUrl = action.payload;
    },
    setWaitForUpload: (state, action) => {
      state.waitForUpload = action.payload;
    },
    setStartedUploadProcess: (state, action) => {
      state.startedUploadingProcess = action.payload;
    },
  },
});

export const {
  setUser,
  setUserLoading,
  setUploading,
  setGettingUrl,
  setWaitForUpload,
  setStartedUploadProcess,
} = accountSlice.actions;

//Thunk

export const getUserAsync =
  ({ token }) =>
  async (dispatch) => {
    try {
      dispatch(setUserLoading(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/authvalidation`,
        method: "POST",
        data: {
          userToken: token,
        },
        jwt: token,
      });

      dispatch(setUser(response?.data?.user?.userInfo));
      dispatch(setUserLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setUserLoading(false));
    }
  };

export const uploadFileToServer =
  ({ file, fileName, companyName, token }) =>
  async (dispatch) => {
    try {
      dispatch(setStartedUploadProcess(true));
      dispatch(setGettingUrl(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/generatePresignedURLRecordingUpload?file_name=${fileName}&companyName=${companyName}`,
        method: "GET",
        tryAuth: true,
        jwt: token,
      });

      dispatch(setGettingUrl(false));
      const url = response?.data?.url;
      dispatch(setUploading(true));
      // Perform the actual file upload using the presigned URL

      const uploadResponse = await xhrRequest({
        url: url,
        method: "PUT",
        data: file,
        timeout: 120000,
        headers: {
          "Content-Type": file.type,
        },
        tryAuth: false,
      });

      if (uploadResponse.status === 200) {
        console.log("Upload successful!");
        dispatch(setUploading(false));
        dispatch(setWaitForUpload(false));
        dispatch(setStartedUploadProcess(false));
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      dispatch(setUploading(false));
      dispatch(setGettingUrl(false));
      dispatch(setWaitForUpload(false));
      dispatch(setStartedUploadProcess(false));
    }
  };

export const selectAccountUser = (state) => state?.fit?.account?.user;
export const selectAccountUserLoading = (state) => state?.fit?.account?.userLoading;
export const selectUploading = (state) => state?.fit?.account?.uploading;
export const selectGettingUrl = (state) => state?.fit?.account?.gettingUrl;
export const selectWaitForUpload = (state) => state?.fit?.account?.waitForUpload;
export const selectStartedUploadProcess = (state) =>
  state?.fit?.account?.startedUploadingProcess;

const accountReducer = accountSlice.reducer;

export default accountReducer;
