import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  styled,
} from "@mui/material";

import {
  getLeaderboardAsync,
  getWorkoutSummaryAsync,
  selectLeaderboard,
  selectWorkoutSummary,
} from "../../../store/fit/summary";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCompanyNameFromUrl } from "../../../utils/urls";
import clients from "../../../constants/clients";

const rows = [
  {
    place: 1,
    name: "Gytis Puidokas",
    timestamp: 1677499639,
    workout: "ABS",
    score: 123,
    isCurrentUser: true,
  },
  {
    place: 2,
    name: "Gytis Puidokas",
    timestamp: 1677499669,
    workout: "ABS",
    score: 123,
    isCurrentUser: false,
  },
  {
    username: "2256729a-c598-4cf4-8a6e-e1aefd600139",
    place: 2,
    name: "Gytis Puidokas",
    workout: "ABS",
    score: 123,
  },
  {
    username: "2256729a-c598-4cf4-8a6e-e1aefd600138",
    place: 0,
    name: "Gytis Puidokas",
    workout: "ABS",
    score: 123,
  },
  {
    place: 0,
    name: "Gytis Puidokas",
    workout: "ABS",
    score: 123,
  },
  {
    place: 0,
    name: "Gytis Puidokas",
    timestamp: "ABS",
    score: 123,
  },
];

const Leaderboard = () => {
  const dispatch = useDispatch();
  const { sessionId } = useParams();

  const companyName = getCompanyNameFromUrl();
  const { disableLeaderboard } = clients(companyName);

  const { rankings } = useSelector(selectWorkoutSummary) || {};
  //   const { username } = useAuth();

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const highlight = (isCurrentUser) => {
    if (isCurrentUser) {
      return {
        td: {
          color: main,
        },
      };
    } else {
      return {};
    }
  };

  //   useEffect(() => {
  //     dispatch(
  //       getLeaderboardAsync({
  //         sessionId,
  //         username,
  //       })
  //     );
  //   }, []);

  const options = {
    day: "2-digit",
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  if (disableLeaderboard) return null;

  return (
    <Wrapper>
      <InnerWrapper>
        <Title>Leaderboard</Title>

        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Place</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rankings?.map((row) => (
                <TableRow
                  key={row.place}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    ...highlight(row?.isCurrentUser),
                  }}
                >
                  <TableCell>{row.place}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {new Date(row.timestamp).toLocaleDateString("en-US", options)}
                  </TableCell>
                  <TableCell align="right">{row.score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Leaderboard;

const Wrapper = styled("div")`
  margin-top: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  background: #171d29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 15px;
`;

const InnerWrapper = styled("div")`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTableContainer = styled(TableContainer)`
  background: rgb(23, 29, 41);
`;

const Title = styled("div")`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 40px;
  width: fit-content;
  text-align: center;
`;
