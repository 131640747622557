const EndIcon = (props) => {
  return (
    <svg
      fill="#fff"
      height="35px"
      width="35px"
      version="1.1"
      id="XMLID_314_"
      viewBox="0 0 24 24"
      stroke="#fff"
      strokeWidth="2.4"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        <g id="close">
          <g>
            <polygon points="20.3,21.7 12,13.4 3.7,21.7 2.3,20.3 10.6,12 2.3,3.7 3.7,2.3 12,10.6 20.3,2.3 21.7,3.7 13.4,12 21.7,20.3 " />{" "}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EndIcon;
