import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { CircularProgress, Collapse, styled } from "@mui/material";
import Countdown from "./Countdown";
import { useContext } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import Stopwatch from "./Stopwatch";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { CONFIG } from "../../../../config";
import clients, { clientNamesToIgnoreNewUI } from "../../../../constants/clients";
import { selectWorkoutScore } from "../../../../store/fit/workout";
import { useSelector } from "react-redux";
import { getCompanyNameFromUrl } from "../../../../utils/urls";

const Timer = ({ startCountdown, expiryTimestamp }) => {
  const { clientId } = useParams();
  /// responsible for loading timer component. If false, timer placeholder is displayed. If true, timer component loads
  const {
    startTimer,
    componentData,
    timeLeft,
    setTimeLeft,
    repCount,
    showMobileComponent,
    paused,
  } = useContext(WorkoutContext);
  const totalReps = componentData?.totalReps;
  const repsLeft = totalReps - repCount;
  const [showMobileTimer, setShowMobileTimer] = useState(false);
  const [showEnduranceTimeOut, setShowEnduranceTimeOut] = useState(false);
  const enduranceTimer = useRef(null);
  const screenWidth = useWindowWidth();
  const workoutScore = useSelector(selectWorkoutScore);
  const duration = componentData?.duration;
  ////static endurance exercise logic
  useEffect(() => {
    if (
      componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise
    ) {
      clearTimeout(enduranceTimer.current);
      setShowEnduranceTimeOut(false);
      setTimeLeft(undefined);

      if (workoutScore > 0) {
        enduranceTimer.current = setTimeout(() => {
          setShowEnduranceTimeOut(true);
        }, 6000);
      }
    }
    return () => {
      clearTimeout(enduranceTimer.current);
    };
  }, [workoutScore]);

  ////endurance exercise logic
  useEffect(() => {
    if (componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise) {
      clearTimeout(enduranceTimer.current);
      setShowEnduranceTimeOut(false);
      setTimeLeft(undefined);

      if (repCount > 0) {
        enduranceTimer.current = setTimeout(() => {
          setShowEnduranceTimeOut(true);
        }, 6000);
      }
    }
    return () => {
      clearTimeout(enduranceTimer.current);
    };
  }, [repCount]);

  //// mobile timer logic

  useEffect(() => {
    if (repsLeft <= 3) {
      setShowMobileTimer(true);
    }
  }, [repCount]);

  useEffect(() => {
    if (timeLeft < 15) setShowMobileTimer(true);
  }, [timeLeft]);

  if (
    screenWidth < CONFIG.MobileBreakpoint &&
    !clientNamesToIgnoreNewUI.includes(clientId)
  ) {
    return (
      <Collapse in={showMobileComponent || showMobileTimer || paused} collapsedSize={10}>
        <Wrapper data-testid="exerciseTimerTest">
          <TimerText
            $screenWidth={screenWidth}
            $clientId={clientId}
            $showEnduranceTimeOut={showEnduranceTimeOut}
          >
            {startCountdown &&
              (duration ? (
                <Countdown
                  expiryTimestamp={expiryTimestamp}
                  startTimer={startTimer}
                  setTimeLeft={setTimeLeft}
                />
              ) : (
                <Stopwatch
                  showEnduranceTimeOut={showEnduranceTimeOut}
                  startTimer={startTimer}
                />
              ))}
            {!startCountdown && (
              <>
                {Math.floor(duration / 60)}:
                {duration % 60 < 10 ? "0" + (duration % 60) : duration % 60}
              </>
            )}
          </TimerText>
        </Wrapper>
      </Collapse>
    );
  }
  //Desktop

  return (
    <Circle data-testid="exerciseTimerTest">
      <CircularProgress
        color="inherit"
        style={{
          width: "66px",
          height: "65px",
          position: "absolute",
          top: 0,
          left: 0,
          // color: "rgb(144, 202, 249)",
          zIndex: 0,
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        }}
        variant="determinate"
        value={100}
      />
      <CircularProgress
        color="inherit"
        style={{
          width: "66px",
          height: "65px",
          color: `${
            timeLeft > 15
              ? "#54B862"
              : timeLeft > 5
              ? "#f5da42"
              : timeLeft === undefined
              ? "#54B862"
              : "#d10f0f"
          }`,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
        variant="determinate"
        value={startCountdown ? (timeLeft / duration) * 100 : 100}
      />
      <InnerCircleContainer>
        <PercentageContainer>
          <TimerText
            $screenWidth={screenWidth}
            $clientId={clientId}
            $showEnduranceTimeOut={showEnduranceTimeOut}
          >
            {startCountdown &&
              (duration ? (
                <Countdown
                  expiryTimestamp={expiryTimestamp}
                  startTimer={startTimer}
                  setTimeLeft={setTimeLeft}
                />
              ) : (
                <Stopwatch
                  showEnduranceTimeOut={showEnduranceTimeOut}
                  startTimer={startTimer}
                />
              ))}
            {!startCountdown && (
              <>
                {Math.floor(duration / 60)}:
                {duration % 60 < 10 ? "0" + (duration % 60) : duration % 60}
              </>
            )}
          </TimerText>
        </PercentageContainer>
      </InnerCircleContainer>
    </Circle>
  );
};

export default Timer;

const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 60px;
  width: 130px;
  background-color: #690808;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
`;

const Circle = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 66px;
  width: 65px;
`;

const InnerCircleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PercentageContainer = styled("div")`
  display: flex;
  align-items: flex-end;
`;

const TimerText = styled("p")`
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  ${({ $screenWidth, $clientId, theme, $showEnduranceTimeOut }) =>
    $screenWidth < CONFIG.MobileBreakpoint &&
    !clientNamesToIgnoreNewUI.includes($clientId)
      ? $showEnduranceTimeOut
        ? ` font-size: 35px;
      color: #fff;
      animation: colorBlink 1s linear 0s infinite normal forwards;
  
      @keyframes colorBlink {
        0%,
        50%,
        100% {
          color: #fff;
        }
    
        25%,
        75% {
          color: #140908;
        }
      }`
        : `font-size: 35px;
        color: #fff;`
      : $showEnduranceTimeOut
      ? ` font-size: 18px;
      color: ${theme.palette.primary.main};
      animation: redBlink 1s linear 0s infinite normal forwards;
    
      @keyframes redBlink {
        0%,
        50%,
        100% {
          color: ${theme.palette.primary.main};
        }
    
        25%,
        75% {
          color: #cf1908;
        }
      }
      `
      : ` 
      font-size: 18px;
      color: ${theme.palette.primary.main};`}
`;
