import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useWindowWidth from "../../../../../hooks/useWindowWidth";
import { selectWorkoutSummary } from "../../../../../store/fit/summary";

const Wrapper = styled.div`
  width: 100%;
  color: #000;
`;

const AccuracyChart = () => {
  const workoutSummary = useSelector(selectWorkoutSummary);
  const { accuracyChart } = workoutSummary || {};

  const CHART_OPTIONS = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "35%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 1,
      borderRadius: 50,
    },
    colors: ["#6D73FF"],
    fill: {
      type: ["gradient"],
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.25,
        opacityFrom: 1,
        opacityTo: 0.3,
        stops: [0, 100],
      },
    },
    xaxis: {
      categories: accuracyChart?.xaxis2 || [],
      axisBorder: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: "#fff",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max: 100,
      tickAmount: 5,
      axisBorder: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: "#fff",
        },
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
    //   tooltip: {
    //     y: {
    //       formatter: function (val) {
    //         return "$ " + val + " thousands"
    //       }
    //     }
    //   }
  };

  const width = useWindowWidth();
  return (
    <Wrapper>
      <Chart
        type="bar"
        height={width < 600 ? 250 : 300}
        options={CHART_OPTIONS}
        series={accuracyChart?.series || []}
      />
    </Wrapper>
  );
};

export default AccuracyChart;
