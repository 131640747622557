import styled from "@emotion/styled";

const ContentWrapper = styled("div")`
  max-height: calc(100% - 60px);
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export default ContentWrapper;
