import { Guid } from "js-guid";

export const GUID = () => {
  const storage = sessionStorage.getItem("sessionID");
  if (storage) return storage;
  if (!storage) {
    const newID = Guid.newGuid().StringGuid;
    // sessionStorage.setItem("sessionID", newID);
    return newID;
  }
};

export const resetGUID = () => {
  const newID = Guid.newGuid().StringGuid;
  // sessionStorage.setItem("sessionID", newID);
  return newID;
};
