import { CONFIG } from "../../../config";

export const sendEventsComponents = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.tutorial,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const initiationComponents = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.cameraSetupValidation,
];

export const workoutDifficulties = {
  iFIT: 2,
};

export const recordComponents = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const REDUCED_JOINT_SET_MODE = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const getModelComplexity = (model, osVersion, complexity) => {
  if (higherModelComplexityStringList.includes(model) || iOSVersions.includes(osVersion))
    return complexity > 1 ? complexity : 1;
  return complexity;
};

const higherModelComplexityStringList = [
  /////Samsung Galaxy S22 5G
  "SM-S901B",
  "SM-S901U",
  /////Samsung Galaxy S22 Plus 5G
  "SM-S906B",
  "SM-S906U",
  /////Samsung Galaxy S22 Ultra 5G
  "SM-S908B",
  "SM-S908U",
  /////Samsung Galaxy S23 5G
  "SM-S911B",
  "SM-S911U",
  /////Samsung Galaxy S23 Plus 5G
  "SM-S916B",
  "SM-S916U",
  /////Samsung Galaxy S23 Ultra 5G
  "SM-S918B",
  "SM-S918U",
  /////Samsung Galaxy S23 FE 5G
  "SM-S911E",
  /////Samsung Galaxy S24 5G
  "SM-S941B",
  "SM-S941U",
  /////Samsung Galaxy S24 Plus 5G
  "SM-S946B",
  "SM-S946U",
  /////Samsung Galaxy S24 Ultra 5G
  "SM-S948B",
  "SM-S948U",
  /////Google Pixel 7
  "G10NZB",
  /////Google Pixel 7 Pro
  "G8VOU",
  /////Google Pixel 8
  "G20NZB",
  /////Google Pixel 8 Pro
  "G8VOU2",
  /////Xiaomi 13
  "2201123G",
  /////Xiaomi 13 Pro
  "2201132C",
];

const iOSVersions = [
  "17.4.1",
  "17.4",
  "17.3.1",
  "17.3",
  "17.2.1",
  "17.2",
  "17.1.1",
  "17.1",
  "17.0.3",
  "17.0.2",
  "17.0.1",
  "17.0",
];
