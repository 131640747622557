import Exercise from "./Exercise";
import Progress from "./Progress";
import { useContext } from "react";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { CONFIG } from "../../../config";
import { styled } from "@mui/material";

const Wrapper = styled("div")`
  transition: all 0.5s;

  position: absolute;
  display: flex;
  justify-content: center;
  top: 30px;
  right: 30px;
  flex-direction: column;
  z-index: 3;

  @media (min-width: 500px) {
    transform: scale(1.8);
    top: 50px;
    right: 50px;
  }

  @media (min-width: 700px) {
    transform: scale(2.3);
    top: 70px;
    right: 80px;
  }

  @media (min-width: 1000px) {
    transform: scale(2.7);
    top: 82px;
    right: 100px;
  }
`;

const CountWrapper = styled("div")`
  display: flex;
  margin: 0 auto;
`;
///

const RepCount = () => {
  const width = useWindowWidth();
  const { repCount, componentData } = useContext(WorkoutContext);

  const totalReps = componentData?.totalReps;
  //////////////////////////////////
  ////////////////////////////////// Demo APP
  /////////////////////////////////

  if (
    componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise ||
    componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise ||
    componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise
  )
    return (
      <Wrapper data-testid="exerciseRepsTest">
        <CountWrapper>
          <Progress repCount={repCount} totalReps={totalReps} />
        </CountWrapper>
        {width <= 1350 && <Exercise />}
      </Wrapper>
    );
  else return null;
};

export default RepCount;
