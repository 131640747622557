import { styled as muiStyled } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/Buttons/Button";
import { getWorkoutSummaryAsync, selectRadarChart } from "../../../store/fit/summary";
import { resetState } from "../../../store/fit/workout";
import AccuracyCard from "./AccuracyCard";
import CongratulationsCard from "./CongratulationsCard";
import FeedbackCard from "./FeedbackCard";
import PerformanceCard from "./PerformanceCard";
import TopNav from "./TopNav";
import FloatingLogo from "../../../components/FloatingLogo";
import Leaderboard from "../Leaderboard";
import clients from "../../../constants/clients";
import { getCompanyNameFromUrl } from "../../../utils/urls";
import useAuth from "../../../hooks/useAuth";
import RadarCard from "./RadarCard";
import { resetChallenge, selectChallengeError } from "../../../store/fit/challenge";
import ChallengeResultScreen from "./ChallengeResultScreen";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 128px;
`;

const StyledStartButton = muiStyled(Button)`
    margin-top: 40px;
    min-width: 250px;
`;

const ResultScreen = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { name, username } = useAuth();
  const challengeId = searchParams.get("challenge");
  const challengeError = useSelector(selectChallengeError);
  const { sessionId, appType, clientId } = useParams();
  const clientName = getCompanyNameFromUrl();
  const { requireAuth, disableSummaryButtons, disableSummaryLoading } =
    clients(clientName);

  useEffect(() => {
    dispatch(
      getWorkoutSummaryAsync({
        sessionId,
        clientId,
        username,
        name,
      })
    );

    // HACK TILL WE CHaNGE DESIGN TO HAVE BLACK BACKGROUND
    document.body.style.background =
      "linear-gradient(0deg, rgb(0 0 0) 0%, rgb(107 116 139) 150.91%)";
    document.body.style.height = "auto";
    return () => {
      document.body.style.background = "black";
      document.body.style.height = "100%";
    };
  }, []);

  const handleStartAgainClick = () => {
    dispatch(resetChallenge());
    dispatch(resetState());
  };

  if (challengeId && !challengeError) return <ChallengeResultScreen />;

  if (disableSummaryLoading) return null;
  return (
    <Wrapper>
      <TopNav />
      <FeedbackCard />
      <CongratulationsCard />
      <PerformanceCard />
      <AccuracyCard />
      {requireAuth && <Leaderboard />}
      {!disableSummaryButtons && (
        <StyledStartButton
          to={`/${appType}/${clientId}/selection`}
          variant="outlined"
          onClick={handleStartAgainClick}
          size="large"
        >
          Start again
        </StyledStartButton>
      )}
      <FloatingLogo showEmail={true} />
    </Wrapper>
  );
};

export default ResultScreen;
