import { Slider } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../../../../components/Buttons/Button";
import { devices } from "../../../../../config";
import {
  selectHeight,
  selectWeight,
  setHeightStore,
  setWeightStore,
} from "../../../../../store/fit/quiz";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 90vw;
  max-width: 420px;
  margin: 0 auto;

  @media ${devices.tablet} {
    gap: 24px;
  }
`;

const SliderName = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
  text-align: center;

  @media ${devices.tablet} {
    font-size: 24px;
  }

  @media ${devices.laptop} {
    font-size: 26px;
  }

  @media ${devices.desktop} {
    font-size: 30px;
  }
`;

const SliderValue = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;

  @media ${devices.laptop} {
    font-size: 16px;
  }

  @media ${devices.desktop} {
    font-size: 22px;
  }
`;

const SecondForm = ({ setQuizProgress, quizProgress }) => {
  const reduxWeight = useSelector(selectWeight);
  const reduxHeight = useSelector(selectHeight);
  const [reduxHeightForSlider] = useState(reduxHeight);
  const [reduxWeightForSlider] = useState(reduxWeight);

  const dispatch = useDispatch();

  const changeHandler = (event) => {
    const target = event.target;
    if (target.name === "heightSlider") dispatch(setHeightStore(target.value));
    if (target.name === "weightSlider") dispatch(setWeightStore(target.value));
  };

  const handleClick = () => {
    setQuizProgress(() => quizProgress + 1);
  };

  return (
    <Wrapper>
      <SliderWrapper>
        <SliderName>height</SliderName>
        <SliderValue>{reduxHeight} cm</SliderValue>
        <Slider
          aria-label="Height scale"
          defaultValue={reduxHeightForSlider}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={100}
          max={235}
          onChange={changeHandler}
          name={"heightSlider"}
        />
      </SliderWrapper>
      <SliderWrapper>
        <SliderName>weight</SliderName>
        <SliderValue>{reduxWeight} kg</SliderValue>
        <Slider
          aria-label="Weight scale"
          defaultValue={reduxWeightForSlider}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={40}
          max={180}
          onChange={changeHandler}
          name={"weightSlider"}
        />
      </SliderWrapper>
      <Button size="large" onClick={handleClick}>
        NEXT
      </Button>
    </Wrapper>
  );
};

export default SecondForm;
