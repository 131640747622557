import { Chip, css, styled } from "@mui/material";
import ImageLoader from "../ImageLoader";
import LazyLoad from "react-lazyload";
import SmartLink from "../Primitives/SmartLink";
import { getCompanyNameFromUrl } from "../../utils/urls";
import clients from "../../constants/clients";
import FaceIcon from "@mui/icons-material/Face";
import useAuth from "../../hooks/useAuth";

const Wrapper = styled("div")`
  display: flex;
  align-items: flex-end;
  z-index: 100;
  position: fixed;
  bottom: 30px;
  left: 30px;
`;

const StyledLink = styled(SmartLink)`
  ${({ $logoStyles, $linkStyles }) => css`
    > div {
      display: flex;
    }

    @media (max-width: 1000px) {
      bottom: 20px;
      left: 20px;
    }

    @media (max-width: 600px) {
      bottom: 16px;
      left: 16px;
    }

    @media (max-height: 650px) {
      display: none;
    }

    ${$logoStyles}
    ${$linkStyles}
  `}
`;

const StyledImageLoader = styled(ImageLoader)`
  width: 48px;
  height: 48px;
  margin-right: 8px;

  @media (max-width: 1000px) {
    width: 40px;
    height: 40px;
  }
`;

const FloatingLogo = ({ linkStyles, clickable = true, showEmail = false }) => {
  const { name } = useAuth();
  const nameSlice = name?.slice(0, 9);

  const companyName = getCompanyNameFromUrl();
  const { requireAuth, homeUrl, logo, logoStyles } = clients(companyName);

  return (
    <Wrapper>
      <StyledLink
        target="_blank"
        to={clickable && homeUrl}
        $logoStyles={logoStyles}
        $linkStyles={linkStyles}
      >
        <LazyLoad>
          <StyledImageLoader src={logo} />
        </LazyLoad>
      </StyledLink>
      {nameSlice && showEmail && requireAuth && (
        <Chip icon={<FaceIcon />} label={`${nameSlice}..`} variant="outlined" />
      )}
    </Wrapper>
  );
};

export default FloatingLogo;
