import { useRef, useEffect } from "react";
import RecordRTC from "recordrtc";
import { isSafari } from "../utils/browserCheck";
import { MOBILE_TYPE, useMobileTypeDetector } from "./useMobileTypeDetector";

let fileName;
let index;

const useMediaRecorder = (stream, onStart, onStop, onError) => {
  const recorder = useRef(null);
  const safari = isSafari();
  const mobileType = useMobileTypeDetector();
  const ios = mobileType === MOBILE_TYPE.IOS;
  const apple = safari || ios;
  const mimeType = apple ? "video/mp4" : 'video/webm; codecs="vp8"';
  const ending = apple ? "mp4" : "webm";
  const fileType = apple ? "video/mp4" : "video/webm";

  useEffect(() => {
    if (!stream) return;

    const setupRecorder = () => {
      if (recorder.current) {
        recorder.current.destroy();
        recorder.current = null;
      }

      // Setup the recorder with options
      recorder.current = new RecordRTC(stream, {
        type: "video",
        // type: ending,
        mimeType: mimeType,
        recorderType: RecordRTC.MediaStreamRecorder,
        checkForInactiveTracks: true,
        timeSlice: 1000, // for ondataavailable to be called every 1 second
        video: stream.getVideoTracks().length > 0,
      });
    };

    setupRecorder();

    return () => {
      if (recorder.current) {
        recorder.current.destroy();
        recorder.current = null;
      }
    };
  }, [stream]);

  const startRecording = (componentData) => {
    fileName = componentData?.apiName;
    index = componentData?.index;
    if (recorder?.current.getState() !== "recording") {
      recorder.current.startRecording();
      onStart(); // Call onStart callback
    }
  };

  const stopRecording = async () => {
    if (
      recorder?.current?.getState() === "recording" ||
      recorder?.current?.getState() === "paused"
    ) {
      console.log("stopping recording");
      recorder.current.stopRecording(async () => {
        const blob = await recorder.current.getBlob();
        // Optionally fix the WebM duration if required
        onStop(blob, fileName, index, ending, fileType);
        // Call onStop callback with the blob
      });
    }
  };

  const pauseRecording = () => {
    if (recorder?.current?.getState() === "recording") {
      recorder.current.pauseRecording();
      console.log("Recording paused");
    }
  };

  const resumeRecording = () => {
    if (recorder?.current?.getState() === "paused") {
      recorder.current.resumeRecording();
      console.log("Recording resumed");
    }
  };

  return { startRecording, stopRecording, pauseRecording, resumeRecording };
};

export default useMediaRecorder;
