const NextIcon = (props) => {
  return (
    <svg
      version="1.1"
      id="Uploaded to svgrepo.com"
      width="45px"
      height="45px"
      viewBox="0 0 32 32"
      fill="#fff"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          className="sharpcorners_een"
          d="M19,16L7,26V6L19,16z M13,6v3.698L20.562,16L13,22.302V26l12-10L13,6z"
        ></path>{" "}
      </g>
    </svg>
  );
};

export default NextIcon;
