import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectWorkoutSummary,
  selectWorkoutSummaryLoading,
} from "../../../../store/fit/summary";
import SummarySkeleton from "../SummarySkeleton";
import CongratulationsChart from "./CongratulationsChart";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background: #171d29;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(21px);
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 24px;
`;

const Title = styled.div`
  width: 100%;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
`;

const DescriptionText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 14px;
  max-width: 170px;
  text-align: center;
  margin-top: 12px;

  @media (min-width: 600px) {
    font-size: 16px;
    max-width: 270px;
  }
`;

const CongratulationsCard = () => {
  const workoutSummary = useSelector(selectWorkoutSummary);

  const summaryLoading = useSelector(selectWorkoutSummaryLoading);
  const { name, inTopPercentage, athletesCount, betterThanCount } =
    workoutSummary || {};

  if (summaryLoading)
    return (
      <Wrapper>
        <SummarySkeleton />
      </Wrapper>
    );

  return (
    <Wrapper>
      <Title>
        Congratulations
        {/* , <b>{name}!</b> */}
      </Title>
      <DescriptionText>
        You are in top: <b>{inTopPercentage || "-"}%</b>
      </DescriptionText>

      <CongratulationsChart inTopPercentage={inTopPercentage} />

      <DescriptionText>
        In a room with a <b>{athletesCount || "-"}</b> atheletes, you would be
        better than <b>{betterThanCount || "-"}</b> of them!
      </DescriptionText>
    </Wrapper>
  );
};

export default CongratulationsCard;
