import { LinearProgress, styled } from "@mui/material";
import { useContext, useEffect } from "react";
import WorkoutContext from "../../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { CONFIG } from "../../../../../../config";
import { useSelector } from "react-redux";
import { selectChallengeRepCount } from "../../../../../../store/fit/challenge";

const Wrapper = styled("div")`
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
  position: relative;
`;

const ProgressBar = styled(LinearProgress)`
  width: 100%;
  height: 25px;
  clip-path: polygon(0% 0, 95% 0%, 100% 100%, 5% 100%);
  position: absolute;
  background-color: transparent; /* Remove background */
`;

const Progress = () => {
  const { repCount, componentData } = useContext(WorkoutContext);
  const componentType = componentData?.type;
  const totalReps = componentData?.totalReps;
  const challengeReps = useSelector(selectChallengeRepCount);

  const exerciseProgress = (repCount / totalReps) * 100;
  const challengeRepsProgress = challengeReps ? (challengeReps / totalReps) * 100 : 0;

  if (componentType === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise) return null;

  if (!totalReps) return null;

  return (
    <Wrapper>
      <ProgressBar
        variant="determinate"
        value={challengeRepsProgress}
        sx={{
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#d10f0f",
          },
          backgroundColor: "#18391c",
        }}
      />
      <ProgressBar
        variant="determinate"
        value={exerciseProgress}
        sx={{
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#54B862",
          },
          backgroundColor: "transparent",
        }}
      />
    </Wrapper>
  );
};

export default Progress;
