import { BE_SOCKETS_URL } from "./api";

export const CONFIG = {
  ///////////////////////////////////
  ///////////// new Logic parameters
  ////////////////////////////////////
  WORKOUTFLOW_COMPONENT_TYPES: {
    setupTutorial: "setupTutorial",
    cameraSetupValidation: "cameraSetupValidation",
    cameraSetupHandsUpValidation: "cameraSetupHandsUpValidation",
    tutorial: "tutorial",
    exercise: "exercise",
    staticExercise: "staticExercise",
    enduranceExercise: "enduranceExercise",
    staticEnduranceExercise: "staticEnduranceExercise",
    officeExercise: "officeExercise",
    officeStaticExercise: "officeStaticExercise",
    tips: "tips",
    break: "break",
  },

  WORKOUTFLOW_VALIDATION_TYPES: {
    fitInScreen: "fitInScreen",
    handsUp: "handsUp",
    lieDown: "lieDown",
    notFullyVisible: "notFullyVisible",
  },

  //////////////////////////////////

  SOCKET_URL: BE_SOCKETS_URL,
  defaultCompanyName: "fitty",
  defaultAppType: "web",

  USER_BIO_INFO: {
    height: 184,
    weight: 77,
    gender: 0,
  },

  SEND_REQUEST_EVERY_FRAME: 1,

  CAMERA_CANVAS_WIDTH: (1280 * 7) / 11,
  CAMERA_CANVAS_HEIGHT: (720 * 7) / 11,

  CANVAS_PARTS: {
    joint: "joint",
    connector: "connector",
  },

  CONTEXT_TYPES: {
    MIRROR: "mirror",
    FITTY_CAMERA: "fittyCamera",
  },

  EVENT_TYPES: {
    session_start: "session_start",
    session_end: "session_end",
    start_exercise: "start_exercise",
    state_update: "state_update",
    check_connection: "connection_check",
  },

  WORKOUT_EVENT_TYPES: {
    exercise: "exercise",
    staticExercise: "staticExercise",
    break: "break",
    coolOff: "coolOff",
    warmUp: "warmUp",
  },

  EXERCISE_TYPES_TO_IGNORE: ["cool_off", "warmUp", "break"],

  RESPONSE_TYPES: {
    STATE_UPDATE: "state_update",
    STATUS: "connection_status",
    ERROR: "error",
    START_EXERCISE: "start_exercise",
    CONNECTION_CHECK: "connection_check",
  },

  METRIC_NAMES: {
    METRIC_NAME: "metrics",
    REPS: "rep_count",
    SCORE: "score",
    KCAL: "calories",
    TOTAL_SCORE: "total_score",
    FEEDBACK: "feedback",
  },

  MESSAGE_NAMES: {
    WORKOUT_MESSAGE: "feedback",
  },

  disableFeatures: true,
  timer: 3, /// Actual Timer + 1
  tips_timer: 10, // seconds
  threshold: 90, /// 1s = 30
  visibilityFeedbackTime: 15,
  feedbackTimer: 1.5,
  instructionsRepeatTimer: 10, /// + 1sec
  default_tips_timer: 5,
  canvas_loading_timeout: 60,
  staticExerciseTimeMinus: 60,

  canvas: {
    connector: "connector",
    joint: "joint",
  },

  colors: {
    handsUpConnectors: "#DDF1E0",
    handsUpJoints: "#54B862",
    badPositionConnectors: "rgba(255, 255, 255, 0)",
    badPositionJoints: "rgba(251, 55, 65, 0.8)",
    connectors: "rgba(67, 101, 184, 0.7)",
    joints: "rgba(255, 255, 255, 0.8)",
    feedBackColorNeutral: "rgb(144, 202, 249)", //
    feedBackColorPositive: "rgb(102, 187, 106)",
    feedBackColorNegative: "#ef5350",
  },

  APP_TYPE: { MIRROR: "mirror", WEB: "web" },

  DesktopTrigger: 1350,
  QuizDesktopTrigger: 1140,
  SelectionScreenBreakpoint: 735,
  WorkoutScreenLogoBreakpoint: 1000,
  MobileBreakpoint: 800,
  screenSizes: {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    tabletL: "862px",
    laptop: "1024px",
    desktop: "1140px",
  },

  SOUND_INDICATORS: {
    twenty: 20,
    ten: 10,
    five: 5,
  },

  VISIBILITY_DIFFICULTY: {
    joints: {
      hard: 20,
      easy: 19,
    },
  },
  MAXIMUM_HEIGHT_DIFFERENCE: 0.2,

  TUTORIAL_REPS: 1,

  MAX_RECONNECT_ATTEMPTS: 5,
  RECONNECT_INTERVAL: 5,

  TUTORIAL_MESSAGE: "Show us your squat",

  FITQ_TUTORIAL_MESSAGE_MAP: {
    push_up: "Show us your push-up",
    sit_up: "Show us your sit-up",
    squat: "Show us your squat",
  },

  startStopIndicator:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/start_stop_indicator.mp3",
  BACKEND_ERROR_MESSAGES: {
    UNAUTHORIZED: "WebSocket server refused the connection. Unauthorized",
  },

  TESTING_CLIENT_ID: "eb8c9c9c-5c3c-438a-8505-379519918519",
};

export const devices = {
  mobileS: `(min-width: ${CONFIG.screenSizes.mobileS})`,
  mobileM: `(min-width: ${CONFIG.screenSizes.mobileM})`,
  mobileL: `(min-width: ${CONFIG.screenSizes.mobileL})`,
  tablet: `(min-width: ${CONFIG.screenSizes.tablet})`,
  tabletL: `(min-with: ${CONFIG.screenSizes.tabletL})`,
  laptop: `(min-width: ${CONFIG.screenSizes.laptop})`,
  desktop: `(min-width: ${CONFIG.screenSizes.desktop})`,
};

export const tracesSampleRate = {
  localdev: 0,
  dev: 0,
  staging: 0,
  production: 0.25,
}[process.env.REACT_APP_ENV];

export const replaysSessionSampleRate = {
  localdev: 0,
  dev: 0,
  staging: 1,
  production: 0.05,
}[process.env.REACT_APP_ENV];

export const replaysOnErrorSampleRate = {
  localdev: 0,
  dev: 0,
  staging: 1.0,
  production: 1.0,
}[process.env.REACT_APP_ENV];

export const environments = {
  localdev: "localdev",
  dev: "dev",
  staging: "staging",
  production: "production",
}[process.env.REACT_APP_ENV];
