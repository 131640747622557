import {
  ThemeProvider,
  defaultDarkModeOverride,
  useTheme,
} from "@aws-amplify/ui-react";
import { useTheme as useMuiTheme } from "@mui/material/styles";

import React from "react";

const AmplifyThemeProvider = ({ children }) => {
  const { tokens } = useTheme();

  const { palette } = useMuiTheme();

  const theme = {
    name: "default-theme",
    tokens: {
      colors: {
        background: {
          primary: {
            value: "rgb(18, 18, 18)",
          },
          secondary: {
            value: "rgb(26, 32, 39)",
          },
        },
        font: {
          interactive: {
            value: palette.primary.main,
          },
        },
        brand: {
          primary: {
            // 10: tokens.colors.teal["100"],
            // 80: tokens.colors.teal["40"],
            // 90: tokens.colors.teal["20"],
            10: palette.primary.main,
            80: palette.primary.main,
            90: palette.primary.main,
            100: palette.primary.main,
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: palette.primary.main,
              },
            },
            _hover: {
              color: {
                value: palette.primary.light,
              },
            },
            _active: {
              color: {
                value: palette.primary.main,
              },
            },
          },
        },
      },
    },
  };

  const amplifyTheme = {
    name: "default-theme",
    overrides: [defaultDarkModeOverride],
  };

  return (
    <ThemeProvider theme={amplifyTheme} colorMode="dark">
      {children}
    </ThemeProvider>
  );
};

export default AmplifyThemeProvider;
