import { css } from "@emotion/react";
import { styled } from "@mui/material";
import FloatingLogo from "../../../../../components/FloatingLogo";
import Composition from "../Composition";
import { useContext, useEffect } from "react";
import WorkoutContext from "../../WorkoutContext";
import WebsocketContext from "../../WebsocketContext";
import { CONFIG } from "../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { DEFAULT_EXERCISE_ID } from "../../../../../constants";
import {
  getExercisesAsync,
  resetAndSetIds,
  selectCurrentEventIndex,
  selectSessionId,
  selectWorkoutFlow,
  selectWorkoutId,
  setCurrentEventIndex,
  setSessionId,
  setWorkoutId,
  selectWorkoutScore,
  resetState,
  selectToken,
} from "../../../../../store/fit/workout";
import useWindowWidth from "../../../../../hooks/useWindowWidth";
import { getCompanyNameFromUrl } from "../../../../../utils/urls";
import { disableCameraCheck } from "../../../../../utils/features";
import CameraAccessScreen from "../../CameraAccessScreen";
import { useSearchParams } from "react-router-dom";
import { selectShowError } from "../../../../../store/fit/error";

import {
  startTimerAfterScoreReceived,
  startTimerAlways,
  startTimerWhenTracking,
} from "./workoutFlowConfig";
import { NativeBridge } from "../../MobileCommunication";
import { selectAccountUser, selectWaitForUpload } from "../../../../../store/fit/account";
import ReactGA from "react-ga4";
import ChallengeFlow from "./ChallengeFlow";
import {
  getChallengeDataAsync,
  resetChallenge,
  selectChallengeData,
  selectChallengeError,
  setModifiedChallengeData,
} from "../../../../../store/fit/challenge";
import { modifyChallengeData } from "../../../../../helpers/modifyChallengeData";
import isCheater from "../../../../../utils/cheaters";
import { isEscapeKey } from "@mui/x-data-grid/utils/keyboardUtils";

let usedUrlIndex = false;

const WorkoutFlow = ({ fittyWorkout = false }) => {
  ///// contexts
  const {
    connected,
    setFittyWorkout,
    setRepCount,
    repCount,
    setCurrentExerciseApiName,
    setBeginWorkout,
    setStartTimer,
    startTimer,
    tracking,
    componentData,
    setComponentData,
    nextStageWorkout,
    cameraAccess,
    userReady,
    setRecord,
    setChallengeMode,
    challengeMode,
    setComplexity,
  } = useContext(WorkoutContext);

  const { stateUpdate, setContextType } = useContext(WebsocketContext);
  const user = useSelector(selectAccountUser);
  const waitForUpload = useSelector(selectWaitForUpload);
  ////// workout redux
  const currentEventIndex = useSelector(selectCurrentEventIndex);
  const workout = useSelector(selectWorkoutFlow);
  const workoutIdRedux = useSelector(selectWorkoutId);
  const sessionIdRedux = useSelector(selectSessionId);
  const score = useSelector(selectWorkoutScore);
  ///// error redux
  const showError = useSelector(selectShowError);
  ///// mobile communication
  const nativeBridge = new NativeBridge();
  const challengeData = useSelector(selectChallengeData);
  const token = useSelector(selectToken);
  ///// url
  const [searchParams] = useSearchParams();
  const { appType, clientId, workoutId, sessionId, workoutStep } = useParams();
  const suppressFinish = searchParams.get("suppressFinish")?.toLowerCase();
  const challenge = searchParams.get("challenge");
  const challengeError = useSelector(selectChallengeError);
  const eventIndex = searchParams.get("eventIndex");
  // Function to construct the navigateSearch string
  const constructNavigateSearch = (suppressFinish, challenge) => {
    let queryParams = [];

    if (suppressFinish === "true") {
      queryParams.push("suppressFinish=true");
    }

    if (challenge) {
      queryParams.push(`challenge=${challenge}`);
    }

    return queryParams.length ? `?${queryParams.join("&")}` : "";
  };

  const navigateSearch = constructNavigateSearch(suppressFinish, challenge);
  /////used hooks
  const clientName = getCompanyNameFromUrl();
  const screenWidth = useWindowWidth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalReps = componentData?.totalReps;

  useEffect(() => {
    if (!workout) return;
    workout?.workoutFlow.some(
      (workout) =>
        workout.type === "officeStaticExercise" || workout.type === "officeExercise"
    )
      ? setComplexity(2)
      : setComplexity(0);
  }, [workout]);

  useEffect(() => {
    if (isCheater(user?.id)) return;
    else setRecord(user?.recordingEnabled);
  }, [user]);

  const constructWorkoutUrl = (currentEventIndex = undefined) => {
    let basePath = `/${appType}/${clientId}`;
    if (fittyWorkout) basePath += "/fitty-workout";
    basePath += `/${workoutId}`;
    if (workoutStep !== undefined && workoutStep !== null && workoutStep !== "") {
      basePath += `/${currentEventIndex ?? workoutStep}`;
    }
    basePath += `/${sessionId}`;
    return basePath;
  };

  useEffect(() => {
    if (!workout || !challengeData) return;

    const modifiedChallengeData = modifyChallengeData(
      workout?.workoutFlow,
      challengeData?.workoutData
    );
    dispatch(setModifiedChallengeData(modifiedChallengeData));
  }, [workout, challengeData]);

  useEffect(() => {
    if (!token) return;
    if (challenge) {
      dispatch(getChallengeDataAsync(challenge, clientName, token));
    }
  }, [token]);

  useEffect(() => {
    if (workoutStep || workoutStep === 0) usedUrlIndex = true;
    // Handling index from URL
    const indexFromUrl = workoutStep
      ? parseInt(workoutStep, 10)
      : parseInt(eventIndex) ?? null;
    if (indexFromUrl !== null && !isNaN(indexFromUrl)) {
      dispatch(setCurrentEventIndex(indexFromUrl));
      sessionId && dispatch(setSessionId(sessionId));
      workoutId && dispatch(setWorkoutId(workoutId));
    } else if (sessionId !== sessionIdRedux || workoutId !== workoutIdRedux) {
      dispatch(resetAndSetIds({ sessionId, workoutId }));
      dispatch(resetChallenge());
    }

    ReactGA.event({
      category: "session_start",
      action: "session_start",
      label: clientId, // optional
    });
    const jwt = searchParams.get("JWT");

    if (jwt) {
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("clientName", clientId);
    }
    ////setting if window is fitty workout
    setFittyWorkout(fittyWorkout);

    setContextType(CONFIG.CONTEXT_TYPES.MIRROR);
    dispatch(
      getExercisesAsync({
        workoutId: workoutId || DEFAULT_EXERCISE_ID,
      })
    );
    if (jwt || eventIndex !== null)
      navigate({
        pathname: constructWorkoutUrl(),
        search: navigateSearch,
      });
  }, []);

  /// when workout is loaded back backend, sets first event
  useEffect(() => {
    if (!workout) return;
    if (!userReady) return;
    /////resetIndex
    const component = workout?.workoutFlow[currentEventIndex];
    // Check if any workout item is of type 'officeExercise' or 'officeStaticExercise'

    const exerciseAPIName = component?.apiName;
    updateData(component, currentEventIndex);
    if (exerciseAPIName) {
      setCurrentExerciseApiName(exerciseAPIName);
      updateExercise(exerciseAPIName);
    }
    nativeBridge.sendIndex(currentEventIndex);

    if (usedUrlIndex) {
      navigate({
        pathname: constructWorkoutUrl(currentEventIndex),
        search: navigateSearch,
      });
    }
  }, [currentEventIndex, workout, connected, userReady]);

  useEffect(() => {
    if (currentEventIndex >= workout?.workoutFlow.length && !waitForUpload) endWorkout();
  }, [currentEventIndex, waitForUpload]);

  ////exercise over when repcount reaches totalReps
  useEffect(() => {
    if (!totalReps) return;
    if (repCount >= totalReps) {
      nextStageWorkout();
    }
  }, [repCount]);

  useEffect(() => {
    if (challenge && !challengeError) setChallengeMode(true);
    if (challenge && challengeError) setChallengeMode(false);
  }, [challengeError]);

  ///////////////////////////////////////
  ///////// TIMER START //////////////////
  ///////////////////////////////////////

  /// if exercise , body is tracking and timer is not yet started, sets startTimer to true
  useEffect(() => {
    if (showError) return;
    if (
      (userReady &&
        startTimerWhenTracking.includes(componentData?.type) &&
        !startTimer &&
        tracking) ||
      startTimerAlways.includes(componentData?.type)
    ) {
      setBeginWorkout(true);
      setStartTimer(true);
    }
  }, [tracking, componentData, showError, userReady]);

  useEffect(() => {
    if (showError) return;
    if (
      startTimerAfterScoreReceived.includes(componentData?.type) &&
      !startTimer &&
      score > 0
    ) {
      setBeginWorkout(true);
      setStartTimer(true);
    }
  }, [score, showError]);

  //// update exercise in backend
  const updateExercise = (exerciseAPIName) => {
    if (CONFIG.EXERCISE_TYPES_TO_IGNORE.includes(exerciseAPIName)) return;

    const parameters = {
      exercise_id: exerciseAPIName,
      event_type: CONFIG.EVENT_TYPES.start_exercise,
    };
    stateUpdate(parameters);
  };
  /////////////////////////
  ///// ENDING SESSION
  /////////////////////////
  const endWorkout = () => {
    ReactGA.event({
      category: "workout_end",
      action: "normal_workout_end",
      label: clientId, // optional
    });

    nativeBridge.willShowTrainingSummary();
    endSessionInParent();
    dispatch(resetState());
    dispatch(resetChallenge());

    navigate({
      pathname: `/${appType}/${clientId}/summary/${sessionId}`,
      search: navigateSearch,
    });
  };

  /// message to iframe parent
  const endSessionInParent = () => {
    window?.parent?.postMessage("sessionEnd", "*");
  };

  ///updating data for component
  const updateData = (component, index) => {
    setComponentData({
      type: component?.type,
      index: index,
      videoUrl: component?.videoUrl,
      videoUrlMov: component?.videoUrlMov,
      audioUrl: component?.audioUrl,
      totalReps: component?.reps,
      apiName: component?.apiName,
      exerciseName: component?.name,
      duration:
        component?.type === "tips" && !component?.duration ? 3 : component?.duration,
      validation: component?.validation,
    });

    setRepCount(0);
  };

  if (!cameraAccess && !disableCameraCheck) return <CameraAccessScreen />;

  return (
    <Wrapper>
      <Composition />
      {screenWidth > CONFIG.WorkoutScreenLogoBreakpoint && (
        <FloatingLogo linkStyles={floatingLogoLinkStyles} clickable={false} />
      )}
      {challengeMode && <ChallengeFlow />}
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  width: 100%;
  height: -webkit-fill-available;
`;

const floatingLogoLinkStyles = css`
  top: 20px;
  left: 30px;

  @media (max-width: 1000px) {
    top: 10px;
    left: 20px;
  }

  @media (max-width: 600px) {
    top: 10px;
    left: 10px;
  }

  @media (max-height: 650px) {
    display: flex;
  }
`;

export default WorkoutFlow;
