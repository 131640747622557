import { CONFIG } from "../../../../config";
import useWindowWidth from "../../../../hooks/useWindowWidth";

const DesktopMenu = () => {
  const screenWidth = useWindowWidth();

  if (screenWidth < CONFIG.MobileBreakpoint) {
    return <></>;
  }

  return null;
};
export default DesktopMenu;
