import { CircularProgress, styled } from "@mui/material";
import ExerciseList from "./ExerciseList/";
import { useContext, useEffect, useRef, useState } from "react";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { CONFIG } from "../../../config";
import useWindowWidth from "../../../hooks/useWindowWidth";
import StaticExerciseChart from "../StaticExerciseChart";
import { clientNamesToIgnoreNewUI } from "../../../constants/clients";
import MobileTrainer from "./MobileTrainer";
import { useParams } from "react-router";
import { playVideosInstantlyComponentTypes } from "./TrainerConfig";
import { isIOS, isSafari } from "react-device-detect";

const Wrapper = styled("div")`
  height: -webkit-fill-available;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
`;

const TrainerEl = styled("video")`
  transition: all 0.5s linear;
  ${({ $screenWidth }) =>
    $screenWidth > CONFIG.DesktopTrigger ? `width: 100%;` : `width: 40%;`}
  ${({ $isLoading }) => $isLoading && "display: none;"}
  max-height: 400px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 6;
  left: 0%;
  filter: opacity(80%);
  max-width: 600px;
  min-width: 300px;
`;

const MobileTrainerEl = styled("video")`
  max-height: 500px;
  min-width: 200px;
  ${({ $isLoading }) => $isLoading && "display: none;"}
  filter: opacity(100);
  width: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(244, 107, 107, 0.006) 100%
  );
`;

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #a6d5fa;
  }
`;

const MobileWrapper = styled("div")`
@media (max-width: 1350px) {
  height: -webkit-fill-available;
  position: absolute;
  top: 0px;
  z-index: 6;
  left: 0%;
  display: flex;
  flex-direction: column;
  max-width: 330px;`;

const Trainer = () => {
  const screenWidth = useWindowWidth();
  const { playSound, componentData, beginWorkout } = useContext(WorkoutContext);

  const [isLoading, setIsLoading] = useState(true);
  const trainerVideoRef = useRef();
  const { clientId } = useParams();
  const videoUrl = componentData?.videoUrl;
  const videoUrlMov = componentData?.videoUrlMov;

  useEffect(() => {
    trainerVideoRef.current?.load();
  }, [videoUrl]);

  const startVideo =
    beginWorkout || playVideosInstantlyComponentTypes.includes(componentData?.type);

  const showMobileVideo = screenWidth < CONFIG.DesktopTrigger && startVideo;

  if (
    screenWidth < CONFIG.MobileBreakpoint &&
    !clientNamesToIgnoreNewUI.includes(clientId)
  )
    return <MobileTrainer />;
  else if (showMobileVideo) {
    return (
      <MobileWrapper>
        {/* {isLoading && <StyledCircularProgress />} */}
        <MobileTrainerEl
          ref={trainerVideoRef}
          data-testid="exerciseVideoTest"
          playsInline={true}
          autoPlay
          $isLoading={isLoading}
          muted={!playSound}
          loop
          $screenWidth={screenWidth}
          onCanPlay={() => {
            setIsLoading(false);
          }}
        >
          {(isIOS || isSafari) && videoUrlMov ? (
            <source src={videoUrlMov} />
          ) : (
            <>
              <source src={videoUrl} />
              <source src={videoUrlMov} />
            </>
          )}
        </MobileTrainerEl>
      </MobileWrapper>
    );
  } else if (screenWidth >= CONFIG.DesktopTrigger)
    return (
      <Wrapper $screenWidth={screenWidth}>
        {startVideo && (
          <TrainerEl
            ref={trainerVideoRef}
            data-testid="exerciseVideoTest"
            playsInline={true}
            autoPlay
            muted={!playSound}
            loop
            appType
            $screenWidth={screenWidth}
            onCanPlay={() => {
              setIsLoading(false);
            }}
            $isLoading={isLoading}
          >
            {(isIOS || isSafari) && videoUrlMov ? (
              <source src={videoUrlMov} />
            ) : (
              <>
                <source src={videoUrl} />
                <source src={videoUrlMov} />
              </>
            )}
          </TrainerEl>
        )}
        {(componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES?.staticExercise ||
          componentData?.type ===
            CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise) &&
        beginWorkout ? (
          <StaticExerciseChart />
        ) : (
          <ExerciseList />
        )}
      </Wrapper>
    );
  else return null;
};

export default Trainer;
