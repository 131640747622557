import { createSlice } from "@reduxjs/toolkit";
import xhrRequest from "../../api/xhrRequest";
import { BE_AWS_URL } from "../../api";

const initialState = {
  challengeInitialState: {
    challengeId: undefined,
    challengeData: undefined,
    modifiedChallengeData: undefined,
    challengeLoading: false,
    challengeRepIndex: 0,
    challengeTime: 0,
    challengeRepCount: 0,
    challengeScore: 0,
    challengeSummary: undefined,
    challengeSummaryLoading: false,
    challengeError: false,
  },
  challengeError: false,
};

const challengeSlice = createSlice({
  name: "challenge",
  initialState: initialState,
  reducers: {
    setChallengeId: (state, action) => {
      state.challengeInitialState.challengeId = action.payload;
    },
    setChallengeData: (state, action) => {
      state.challengeInitialState.challengeData = action.payload;
    },
    setModifiedChallengeData: (state, action) => {
      state.challengeInitialState.modifiedChallengeData = action.payload;
    },
    setChallengeLoading: (state, action) => {
      state.challengeInitialState.challengeLoading = action.payload;
    },
    setChallengeExerciseIndex: (state, action) => {
      state.challengeInitialState.challengeExerciseIndex = action.payload;
    },
    setChallengeRepIndex: (state, action) => {
      state.challengeInitialState.challengeRepIndex = action.payload;
    },
    incrementChallengeRepIndex: (state) => {
      state.challengeInitialState.challengeRepIndex += 1;
    },
    setChallengeTime: (state, action) => {
      state.challengeInitialState.challengeTime = action.payload;
    },
    setChallengeRepCount: (state, action) => {
      state.challengeInitialState.challengeRepCount = action.payload;
    },
    setChallengeScore: (state, action) => {
      state.challengeInitialState.challengeScore = action.payload;
    },
    setChallengeSummary: (state, action) => {
      state.challengeInitialState.challengeSummary = action.payload;
    },
    setChallengeSummaryLoading: (state, action) => {
      state.challengeInitialState.challengeSummaryLoading = action.payload;
    },
    setChallengeError: (state, action) => {
      state.challengeError = action.payload;
    },
    resetExerciseData: (state) => {
      state.challengeInitialState.challengeTime = 0;
      state.challengeInitialState.challengeRepCount = 0;
      state.challengeInitialState.challengeRepIndex = 0;
    },
    resetChallenge: (state) => {
      state.challengeInitialState = {
        ...initialState.challengeInitialState,
      };
    },
  },
});

export const {
  setChallengeId,
  setChallengeData,
  setModifiedChallengeData,
  setChallengeLoading,
  setChallengeRepIndex,
  incrementChallengeRepIndex,
  setChallengeRepCount,
  setChallengeScore,
  setChallengeTime,
  setChallengeSummary,
  setChallengeSummaryLoading,
  resetExerciseData,
  resetChallenge,
  setChallengeError,
} = challengeSlice.actions;

// Thunks
export const getChallengeDataAsync =
  (sessionId, clientName, token) => async (dispatch) => {
    try {
      dispatch(setChallengeLoading(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/getWorkoutReplayData?sessionId=${sessionId}&companyName=${clientName}`,
        tryAuth: true,
        jwt: token,
      });
      dispatch(setChallengeData(response?.data));
      dispatch(setChallengeLoading(false));
      dispatch(setChallengeError(false));
    } catch (error) {
      dispatch(setChallengeLoading(false));
      dispatch(setChallengeError(true));
      console.error(error);
    }
  };

export const getChallengeSummaryAsync =
  (sessionId, clientName, challengeId, username = undefined, name = undefined) =>
  async (dispatch) => {
    try {
      dispatch(setChallengeSummaryLoading(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/getWorkoutSummaryReport?sessionId=${sessionId}&companyName=${clientName}&username=${username}&name=${name}`,
      });
      const responseChallenger = await xhrRequest({
        url: `${BE_AWS_URL}/getWorkoutSummaryReport?sessionId=${challengeId}&companyName=${clientName}&username=${username}&name=${name}`,
      });
      dispatch(
        setChallengeSummary({
          YourData: response.data,
          ChallengerData: responseChallenger?.data,
        })
      );
      dispatch(setChallengeSummaryLoading(false));
    } catch (error) {
      dispatch(setChallengeSummaryLoading(false));
      console.error(error);
    }
  };

// Selectors
export const selectChallengeId = (state) =>
  state.fit.challenge.challengeInitialState.challengeId;
export const selectChallengeData = (state) =>
  state.fit.challenge.challengeInitialState.challengeData;
export const selectModifiedChallengeData = (state) =>
  state.fit.challenge.challengeInitialState.modifiedChallengeData;
export const selectChallengeLoading = (state) =>
  state.fit.challenge.challengeInitialState.challengeLoading;
export const selectChallengeRepIndex = (state) =>
  state.fit.challenge.challengeInitialState.challengeRepIndex;
export const selectChallengeTime = (state) =>
  state.fit.challenge.challengeInitialState.challengeTime;
export const selectChallengeRepCount = (state) =>
  state.fit.challenge.challengeInitialState.challengeRepCount;
export const selectChallengeScore = (state) =>
  state.fit.challenge.challengeInitialState.challengeScore;
export const selectChallengeSummary = (state) =>
  state.fit.challenge?.challengeInitialState.challengeSummary;
export const selectChallengeSummaryLoading = (state) =>
  state.fit.challenge?.challengeInitialState.challengeSummaryLoading;
export const selectChallengeError = (state) => state.fit.challenge?.challengeError;

export default challengeSlice.reducer;
