import React, { useEffect, useState } from "react";
import WorkoutContext from "./index";
import { useParams } from "react-router-dom";
import { alwaysInitiatedFeature } from "../../../../utils/features";
import { useRef } from "react";
import { isSafari } from "../../../../utils/browserCheck";
import { selectGender, selectHeight, selectWeight } from "../../../../store/fit/quiz";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementCurrentEventIndex,
  selectCurrentEventIndex,
} from "../../../../store/fit/workout";

const WorkoutContextComponent = ({ children }) => {
  //If time is over becomes true
  const [timeOver, setTimeOver] = useState(false);

  //tips

  //workout data

  const [staticExerciseStarted, setStaticExerciseStarted] = useState(false);

  // const safari = true;

  //Start session parameters:
  const { clientId, sessionId } = useParams();

  const [fittyWorkout, setFittyWorkout] = useState(false);

  const [tutorial, setTutorial] = useState(false);

  const tutorialVoice = useRef(null);

  const userBioInfoHeight = useSelector(selectHeight);
  const userBioInfoWeight = useSelector(selectWeight);
  const gender = useSelector(selectGender);
  const startSessionParams = {
    company_name: clientId,
    user_bio_information: {
      height: userBioInfoHeight,
      weight: userBioInfoWeight,
      gender: gender,
    },
    jwt: "",
    workout_id: "",
  };

  const endSessionParams = {
    session_id: sessionId,
  };

  const checkConnectionParams = {
    session_id: sessionId,
  };

  ////new logic states
  //canvas
  const [tracking, setTracking] = useState(false);
  const [fullyVisible, setFullyVisible] = useState(false);
  const [handsPositionUp, setHandsPositionUp] = useState(false);
  const [lieDownPosition, setLieDownPosition] = useState(false);
  const [poseLandmarks, setPoseLandmarks] = useState([]);
  const [canvasLoaded, setCanvasLoaded] = useState(false);
  const [feedbackArray, setFeedbackArray] = useState([]);
  const [feedbackData, setFeedbackData] = useState();
  const [connected, setConnected] = useState(null);
  //Compatibility
  const [AIFeedbackLoading, setAIFeedbackLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [timePassed, setTimePassed] = useState();
  const safari = isSafari();
  const [cameraAccess, setCameraAccess] = useState(undefined);
  const [checkedCamera, setCheckedCamera] = useState(false);
  const [newMessage, setNewMessage] = useState(null);
  const [message, setMessage] = useState();
  const [newScore, setNewScore] = useState(0);
  const [newScoreTimestamp, setNewScoreTimestamp] = useState(undefined);
  const [isInitiated, setInitiated] = useState(alwaysInitiatedFeature);
  const [startedPoseEstimation, setStartedPoseEstimation] = useState(false);
  const [repCount, setRepCount] = useState(0);
  const [currentExerciseApiName, setCurrentExerciseApiName] = useState(undefined);
  const [startTimer, setStartTimer] = useState(false);
  const [paused, setPaused] = useState(false);
  const [beginWorkout, setBeginWorkout] = useState(false);
  ////// new logic updating index function
  const [componentData, setComponentData] = useState();
  const [showGoOnStart, setShowGoOnStart] = useState(false);
  const [showGo, setShowGo] = useState(false);
  const expiryTimestamp = useRef(null);
  const [showMobileComponent, setShowMobileComponent] = useState(true);
  const [showTrainer, setShowTrainer] = useState(true);
  const [staticStartTimer, setStaticStartTimer] = useState(false);
  const [ping, setPing] = useState(0);
  /// user input
  const [userReady, setUserReady] = useState(false);
  const [unmountAnimationFinished, setUnmountAnimationFinished] = useState(false);
  const dispatch = useDispatch();
  const [timeIsUp, setTimeIsUp] = useState(false);
  const [record, setRecord] = useState(false);

  ///challenge
  const [challengeMode, setChallengeMode] = useState(false);

  const [complexity, setComplexity] = useState(null);
  const updateIndex = () => {
    dispatch(incrementCurrentEventIndex());
  };

  const nextStageWorkout = (timer) => {
    timer ? setStartTimer(false) : setStartTimer(null);
    setBeginWorkout(false);
    updateIndex();
  };

  return (
    <WorkoutContext.Provider
      value={{
        repCount,
        setRepCount,
        newScore,
        setCurrentExerciseApiName,
        connected,
        newMessage,
        message,
        setMessage,
        setNewMessage,
        newScore,
        tracking,
        setTracking,
        startTimer,
        setStartTimer,
        isInitiated,
        setInitiated,
        timeOver,
        setTimeOver,
        fullyVisible,
        setFullyVisible,
        setNewScore,
        newScoreTimestamp,
        setNewScoreTimestamp,
        currentExerciseApiName,
        setConnected,
        handsPositionUp,
        setHandsPositionUp,
        lieDownPosition,
        setLieDownPosition,
        poseLandmarks,
        setPoseLandmarks,
        safari,
        checkedCamera,
        setCheckedCamera,
        cameraAccess,
        setCameraAccess,
        startSessionParams,
        feedbackArray,
        setFeedbackArray,
        AIFeedbackLoading,
        setAIFeedbackLoading,
        feedbackData,
        setFeedbackData,
        staticExerciseStarted,
        setStaticExerciseStarted,
        endSessionParams,
        checkConnectionParams,
        timeLeft,
        timePassed,
        setTimeLeft,
        setTimePassed,
        fittyWorkout,
        setFittyWorkout,
        tutorial,
        setTutorial,
        tutorialVoice,
        ////////////
        canvasLoaded,
        setCanvasLoaded,
        startedPoseEstimation,
        setStartedPoseEstimation,
        beginWorkout,
        setBeginWorkout,
        componentData,
        setComponentData,
        updateIndex,
        showGo,
        setShowGo,
        expiryTimestamp,
        showMobileComponent,
        setShowMobileComponent,
        paused,
        setPaused,
        showTrainer,
        setShowTrainer,
        nextStageWorkout,
        updateIndex,
        showGoOnStart,
        setShowGoOnStart,
        staticStartTimer,
        setStaticStartTimer,
        ping,
        setPing,
        userReady,
        setUserReady,
        unmountAnimationFinished,
        setUnmountAnimationFinished,
        timeIsUp,
        setTimeIsUp,
        record,
        setRecord,
        complexity,
        setComplexity,
        challengeMode,
        setChallengeMode,
      }}
    >
      {children}
    </WorkoutContext.Provider>
  );
};

export default WorkoutContextComponent;
