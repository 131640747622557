import styled from "styled-components";
import ReactionProgress from "./ReactionProgress";
import { useContext } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import ReactionTimer from "./ReactionTimer";
import { isMobile } from "../../../../utils/browserCheck";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import useWindowHeight from "../../../../hooks/useWindowHeight";

const Wrapper = styled.div`
  width: min-content;
  height: min-content;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
  left: 50%;
  transform: translate(-50%, -25%);

  ${({ $isHorizontalMode }) => ($isHorizontalMode ? `top: 30px;` : `bottom: 30px;`)}

  @media (min-width: 500px), (min-width: 700px), (min-width: 1350px) {
    transform: translate(-50%, -25%);
  }
`;

const CountWrapper = styled.div`
  display: flex;
  margin: 0 auto;
`;

const reactionComponentMap = {
  staticEnduranceExercise: <ReactionTimer />,
  staticExercise: <ReactionTimer />,
  officeExercise: <ReactionProgress />,
  officeStaticExercise: <ReactionTimer />,
  exercise: <ReactionProgress />,
};

const ReactionStats = () => {
  const { componentData } = useContext(WorkoutContext);
  const screenWidth = useWindowWidth();
  const screenHeight = useWindowHeight();

  const mobile = isMobile();

  const isHorizontalMode = screenHeight < screenWidth && mobile;

  return (
    <Wrapper $isHorizontalMode={isHorizontalMode}>
      <CountWrapper>{reactionComponentMap[componentData?.type]}</CountWrapper>
    </Wrapper>
  );
};

export default ReactionStats;
