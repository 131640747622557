import { CONFIG } from "../../../../../config";

export const startTimerWhenTracking = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
];

export const startTimerAfterScoreReceived = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const challengeWorkoutFlowComponentTypes = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const startTimerAlways = [CONFIG.WORKOUTFLOW_COMPONENT_TYPES.break];
