import { CircularProgress, styled } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useSelector } from "react-redux";
import Button from "../../Buttons/Button";
import { selectFirstTimeUser } from "../../../store/fit/quiz";
import { getCompanyNameFromUrl } from "../../../utils/urls";
import clients from "../../../constants/clients";

const Card = styled("div")`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  top: 0;
  background-color: black;
`;

const VideoWrapper = styled("div")`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 86px;
  min-height: 188px;
`;

const StyledVideo = styled("video")`
  width: 100%;
  height: auto;
  max-height: -webkit-fill-available;

  ${({ isLoading }) => isLoading && "display: none;"}

  @media (max-width: 990px) {
    width: 100%;
  }
  @media (max-width: 836px) {
    width: 100%;
  }
  @media (max-width: 666px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #a6d5fa;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 80px;
  top: 20px;
  right: 20px;
  position: absolute;
  background-color: black;
`;

const VideoTutorial = () => {
  const { componentData, updateIndex } = useContext(WorkoutContext);
  const [isLoading, setIsLoading] = useState(true);
  const firstTimeUser = useSelector(selectFirstTimeUser);
  const companyName = getCompanyNameFromUrl();
  const { skipVideoTutorialFeature } = clients(companyName);
  const videoUrl = componentData?.videoUrl;
  const videoRef = useRef();

  useEffect(() => {
    if (skipVideoTutorialFeature === true && firstTimeUser === false) updateIndex();
  }, []);

  useEffect(() => {
    videoRef.current?.load();
  }, [videoUrl]);

  if (skipVideoTutorialFeature && firstTimeUser === false) return null;

  return (
    <Card>
      <VideoWrapper>
        {isLoading && <StyledCircularProgress />}

        <StyledVideo
          ref={videoRef}
          data-testid={"instructionalVideoTest"}
          playsInline={true}
          isLoading={isLoading}
          autoPlay
          onCanPlay={() => {
            setIsLoading(false);
          }}
          onEnded={updateIndex}
        >
          <source src={videoUrl} />
        </StyledVideo>

        <StyledButton size="large" onClick={updateIndex}>
          SKIP
        </StyledButton>
      </VideoWrapper>
    </Card>
  );
};

export default VideoTutorial;
