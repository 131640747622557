import { Button, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SmartLink from "../../../components/Primitives/SmartLink";
import { getWorkoutSummaryAsync, selectWorkoutSummary } from "../../../store/fit/summary";
import { resetState } from "../../../store/fit/workout";
import TipsCarousel from "./TipsCarousel";
import TopNav from "./TopNav";
import FloatingLogo from "../../../components/FloatingLogo";
import { resetChallenge } from "../../../store/fit/challenge";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  max-width: 1000px;
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
`;

const ResultsTipsScreen = () => {
  const dispatch = useDispatch();
  const { sessionId, appType, clientId } = useParams();

  const workoutSummary = useSelector(selectWorkoutSummary);

  useEffect(() => {
    if (!workoutSummary) {
      dispatch(
        getWorkoutSummaryAsync({
          sessionId: sessionId,
          clientId,
        })
      );
    }

    // HACK TILL WE CHaNGE DESIGN TO HAVE BLACK BACKGROUND
    document.body.style.background =
      "linear-gradient(0deg, rgb(0 0 0) 0%, rgb(107 116 139) 150.91%)";
    return () => (document.body.style.background = "black");
  }, []);

  const handleStartAgainClick = () => {
    dispatch(resetState());
    dispatch(resetChallenge());
  };

  return (
    <Wrapper>
      <TopNav />
      <TipsCarousel />
      <StyledButton
        component={SmartLink}
        to={`/${appType}/${clientId}/selection`}
        variant="outlined"
        onClick={handleStartAgainClick}
        size="large"
      >
        Start again
      </StyledButton>
      <FloatingLogo showEmail={true} />
    </Wrapper>
  );
};

export default ResultsTipsScreen;
