export class PriorityQueue {
  constructor() {
    this.heap = [];
    this.timeouts = new Map();
  }

  _swap(i, j) {
    [this.heap[i], this.heap[j]] = [this.heap[j], this.heap[i]];
  }

  _heapifyUp(index) {
    let parent = Math.floor((index - 1) / 2);
    while (index > 0 && this.heap[index].priority > this.heap[parent].priority) {
      this._swap(index, parent);
      index = parent;
      parent = Math.floor((index - 1) / 2);
    }
  }

  _heapifyDown(index) {
    let left = 2 * index + 1;
    let right = 2 * index + 2;
    let largest = index;

    if (
      left < this.heap.length &&
      this.heap[left].priority > this.heap[largest].priority
    ) {
      largest = left;
    }
    if (
      right < this.heap.length &&
      this.heap[right].priority > this.heap[largest].priority
    ) {
      largest = right;
    }
    if (largest !== index) {
      this._swap(index, largest);
      this._heapifyDown(largest);
    }
  }

  enqueue(value, priority, timeout = 5000) {
    const node = { value, priority };
    this.heap.push(node);
    this._heapifyUp(this.heap.length - 1);

    const timeoutId = setTimeout(() => {
      this._timeoutRemove(value);
    }, timeout);
    this.timeouts.set(value, timeoutId);
  }

  dequeue() {
    if (this.heap.length === 0) {
      return null;
    }
    if (this.heap.length === 1) {
      const node = this.heap.pop();
      clearTimeout(this.timeouts.get(node.value));
      this.timeouts.delete(node.value);
      return node;
    }
    const root = this.heap[0];
    this.heap[0] = this.heap.pop();
    this._heapifyDown(0);
    clearTimeout(this.timeouts.get(root.value));
    this.timeouts.delete(root.value);
    return root;
  }

  _timeoutRemove(value) {
    const index = this.heap.findIndex((node) => node.value === value);
    if (index !== -1) {
      this._swap(index, this.heap.length - 1);
      this.heap.pop();
      this._heapifyDown(index);
      this.timeouts.delete(value);
    }
  }
}
