import { styled, Slide, keyframes, CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import touchScreenAnimation from "../../../Animations/TouchScreenAnimation.json";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import { selectChallengeError } from "../../../../store/fit/challenge";
import gif from "./YdBO.gif";

const revealFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Card = styled("div")`
  height: 100vh; /* 100% of the viewport height */
  width: 100vw; /* 100% of the viewport width */
  position: fixed;
  gap: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 110;
  padding: 10px;
  top: 0;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(107, 116, 139) 150.91%);
`;

const AnimationContainer = styled("div")`
  width: 300px;
  height: 300px;
  margin-left: 30px;
`;

const StyledText = styled("h2")`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  animation: ${revealFromBottom} 0.5s ease-out forwards;
  margin-top: -75px;
`;

const StyledRecordText = styled("h2")`
  color: #f77564;
  font-size: 15px;
  text-align: center;
  margin-bottom: 20px;
  animation: ${revealFromBottom} 0.5s ease-out forwards;
`;

const StyledTopText = styled("h2")`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 40px;
  text-align: center;
  animation: ${revealFromBottom} 0.5s ease-out forwards;
`;

const IconWrapper = styled("div")`
  width: 200px; /* Adjusted to better fit the circular progress */
  height: 200px;
  position: relative;
  padding: 4px;
`;

const StyledImg = styled("img")`
  width: 100%;
  height: 100%;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: rotate(-90deg); /* Rotate to start from the top-left */
`;

const BeginWorkoutScreen = ({ howl }) => {
  const { setUserReady, setUnmountAnimationFinished, record, challengeMode } =
    useContext(WorkoutContext);
  const [checked, setChecked] = useState(true);
  const [isInitialMount, setIsInitialMount] = useState(true); // New state to track initial mount
  const challengeError = useSelector(selectChallengeError);
  const [screenClicked, setScreenClicked] = useState(false);
  const [progress, setProgress] = useState(0); // State to track progress

  useEffect(() => {
    if (howl && howl.current) {
      const updateProgress = () => {
        const duration = howl.current.duration() || 1; // Avoid division by zero
        const seek = howl.current.seek();
        const percentage = (seek / duration) * 100;
        setProgress(percentage);
      };

      howl.current.on("play", () => {
        setProgress(0);
        updateProgress();
        howl.current.on("end", () => {
          setProgress(100);
          setUserReady(true);
          setChecked(false); // Trigger the slide out animation
        }); // Ensure progress is 100% on end
        howl.current.on("seek", updateProgress);
        howl.current.on("pause", updateProgress);
        howl.current.on("stop", updateProgress);
      });

      const interval = setInterval(() => {
        if (howl.current.playing()) {
          updateProgress();
        }
      }, 100); // Update every 100ms

      return () => {
        clearInterval(interval);
        howl.current.off("play");
        howl.current.off("end");
        howl.current.off("seek");
        howl.current.off("pause");
        howl.current.off("stop");
      };
    }
  }, [howl]);

  useEffect(() => {
    // After the initial mount, set isInitialMount to false
    setIsInitialMount(false);
  }, []);

  const clickHandler = () => {
    if (!screenClicked) howl?.current.play(); // Play the sound when the button is clicked

    if (challengeMode) setScreenClicked(true);
    if (!challengeMode || challengeError) {
      setUserReady(true);
      setChecked(false); // Trigger the slide out animation
    }
  };

  const handleExited = () => {
    setUnmountAnimationFinished(true);
  };

  const exitAnimationDuration = 750; // 1000 milliseconds (1 second) for exit

  // Conditionally set timeout based on the component's mount status
  const timeout = isInitialMount ? 0 : exitAnimationDuration;

  return (
    <Slide
      direction="down"
      in={checked}
      onExited={handleExited}
      mountOnEnter
      unmountOnExit
      timeout={timeout}
      appear={false} // Disable the entrance animation
    >
      <Card onClick={clickHandler}>
        {challengeMode && screenClicked && !challengeError ? (
          <IconWrapper>
            <StyledImg src={gif} alt="voice-visualization-gif" />
            <StyledCircularProgress
              variant="determinate"
              value={progress}
              size={201}
              thickness={1}
            />
          </IconWrapper>
        ) : (
          <>
            <StyledTopText>Ready?</StyledTopText>
            <AnimationContainer>
              <Lottie animationData={touchScreenAnimation} loop={true} />
            </AnimationContainer>
            <StyledText>Tap the screen to start!</StyledText>
            {record && (
              <StyledRecordText>This session will be recorded.</StyledRecordText>
            )}
            {challengeError && (
              <StyledRecordText>
                There was a problem loading challenge. You can still do the workout
              </StyledRecordText>
            )}
          </>
        )}
      </Card>
    </Slide>
  );
};

export default BeginWorkoutScreen;
