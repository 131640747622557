import { styled } from "@mui/material";

const StyledImg = styled("img")`
  margin: 0 auto;
  margin-top: 20px;
  // width: 70%;
  max-height: 250px;
`;

const ErrMsg = styled("p")`
  margin-top: 20px;
  color: white;
  text-align: center;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  width: 90%;
  max-height: -webkit-fill-available;
  max-width: 400px;
  margin: 0 auto;
  // margin-top: 40px;
`;

const StyledText = styled("h2")`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  text-align: center;
  margin-top: 40px;
`;

const ReactionClubCameraAccess = () => {
  return (
    <Wrapper>
      <StyledText>The app will NOT record or share your screen</StyledText>
      <ErrMsg>Enable your camera so the AI coach can guide you in this session</ErrMsg>

      <StyledImg
        src={
          "https://fittyai-utils.s3.amazonaws.com/images/reaction-club-camera-access.png"
        }
      ></StyledImg>

      <ErrMsg>
        At the end of the activity we will only be sharing your number of repetitions to
        the challenge
      </ErrMsg>
    </Wrapper>
  );
};

export default ReactionClubCameraAccess;
