import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import FloatingLogo from "../../components/FloatingLogo";
import { ChevronLeft } from "@mui/icons-material";
import useWindowWidth from "../../hooks/useWindowWidth";
import { CONFIG } from "../../config";
import Button from "../../components/Buttons/Button";
import AuthScreen from "../../components/Auth";

const AuthAccessScreen = ({ title }) => {
  const { appType, clientId } = useParams();

  const breakpoint = useWindowWidth() < CONFIG.SelectionScreenBreakpoint;
  const { pathname } = useLocation();
  const isHome = `/${appType}/${clientId}/selection` === pathname;

  return (
    <Wrapper>
      <Header>
        {!isHome && (
          <StyledButton
            startIcon={!breakpoint && <ChevronLeft />}
            to={`/${appType}/${clientId}/selection`}
          >
            {breakpoint ? (
              <ChevronLeft />
            ) : (
              <>
                <p>Home</p>
              </>
            )}
          </StyledButton>
        )}
      </Header>
      <Title>{title}</Title>
      <AuthScreen />
      <FloatingLogo showEmail />
    </Wrapper>
  );
};

export default AuthAccessScreen;

const Title = styled("div")`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 48px;
  width: fit-content;
  text-align: center;
  max-width: 340px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: -webkit-fill-available;
`;

const Header = styled("div")`
  display: flex;
  align-content: center;
  margin: 24px auto;
  position: relative;
  width: 95vw;
  max-width: 1000px;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  height: min-content;
`;
