import styled from "styled-components";
import QuizProgress from "./QuizProgress";
import FormCard from "./FormCard";
import { useState } from "react";
import { devices } from "../../../config";
import { quizTextMap } from "./constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCookieId, setCookieId } from "../../../store/fit/quiz";
import { Guid } from "js-guid";
import ContentWrapper from "../../../components/ContentWrapper";
import FloatingLogo from "../../../components/FloatingLogo";

const Wrapper = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 16px;
  height: 100%;

  @media ${devices.mobileL} {
    gap: 30px;
  }

  @media ${devices.tablet} {
    gap: 32px;
  }
`;

const QuizTextWrapper = styled.div`
  max-width: 544px;

  @media ${devices.laptop} {
    max-width: 800px;
  }
`;

const QuizText = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-align: center;
  /* color: #ffffff; */
  padding: 0 24px 0 24px;

  @media ${devices.mobileL} {
    font-size: 36px;
    line-height: 55px;
  }

  @media ${devices.tablet} {
    font-size: 36px;
    line-height: 65px;
  }
  @media ${devices.tabletL} {
    font-size: 40px;
    line-height: 65px;
  }

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const QuizScreen = () => {
  const dispatch = useDispatch();
  const [quizProgress, setQuizProgress] = useState(1);
  const cookieId = useSelector(selectCookieId);

  useEffect(() => {
    if (!cookieId) dispatch(setCookieId(Guid.newGuid().StringGuid));
  }, []);

  return (
    <ContentWrapper>
      <Wrapper>
        <QuizProgress
          quizProgress={quizProgress}
          setQuizProgress={setQuizProgress}
        />
        <QuizTextWrapper>
          <QuizText>{quizTextMap[quizProgress]}</QuizText>
        </QuizTextWrapper>
        <FormCard
          quizProgress={quizProgress}
          setQuizProgress={setQuizProgress}
        />

        <FloatingLogo showEmail />
      </Wrapper>
    </ContentWrapper>
  );
};

export default QuizScreen;
