import React from "react";
import { LinearProgress } from "@mui/material";
import PropTypes from "prop-types"; // Importing PropTypes for type-checking

const ProgressBar = ({ myScorePercentage }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={myScorePercentage}
      sx={{
        width: "100%",
        height: "25px",
        marginBottom: "40px",
        clipPath: "polygon(5% 0, 100% 0%, 95% 100%, 0% 100%)",
        padding: "10px",
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#54B862",
        },
        backgroundColor: "#d10f0f",
      }}
    />
  );
};

// PropTypes to validate the type of myScorePercentage
ProgressBar.propTypes = {
  myScorePercentage: PropTypes.number.isRequired,
};

export default ProgressBar;
