import { useContext, useEffect, useState, useRef } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { CONFIG } from "../../../../config";
import { styled } from "@mui/material";
import clients from "../../../../constants/clients";
import { getCompanyNameFromUrl } from "../../../../utils/urls";
import { useDispatch, useSelector } from "react-redux";
import { setFirstTimeUser } from "../../../../store/fit/quiz";
import { selectWorkoutFlow } from "../../../../store/fit/workout";

const TimerText = styled("div")`
  color: ${({ theme }) => theme.palette.primary.main};
  font-height: 600;
  font-size: 70px;
  position: fixed;

  -webkit-animation: scale-down-center 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: scale-down-center 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  @-webkit-keyframes scale-down-center {
    0% {
      -webkit-transform: scale(2);
      transform: scale(2);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-down-center {
    0% {
      -webkit-transform: scale(2);
      transform: scale(2);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Card = styled("div")`
  position: absolute;
  bottom: 50px;
  width: 100px;
  margin-right: 0 auto;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  padding: 10px;
`;

const CameraValidation = () => {
  const timer = useRef(null);
  ///////////////////////////
  ////////// INITIATION VIA VISIBILITY

  const {
    fullyVisible,
    handsPositionUp,
    updateIndex,
    setShowGoOnStart,
    setShowGo,
    componentData,
    lieDownPosition,
  } = useContext(WorkoutContext);

  const validationType =
    componentData?.validation ?? CONFIG.WORKOUTFLOW_VALIDATION_TYPES.fitInScreen;

  const [showCountDown, setShowCountDown] = useState(true);
  const [count, setCount] = useState(componentData?.duration || 3);
  const clientName = getCompanyNameFromUrl();

  const { showGoAfterFitInScreen } = clients(clientName);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showGoAfterFitInScreen) setShowGoOnStart(true);

    return () => {
      if (showGoAfterFitInScreen === true) setShowGo(true);
    };
  }, []);

  const validationChecks = {
    [CONFIG.WORKOUTFLOW_VALIDATION_TYPES.fitInScreen]: fullyVisible,
    [CONFIG.WORKOUTFLOW_VALIDATION_TYPES.handsUp]: handsPositionUp,
    [CONFIG.WORKOUTFLOW_VALIDATION_TYPES.lieDown]: lieDownPosition,
    [CONFIG.WORKOUTFLOW_VALIDATION_TYPES.notFullyVisible]: fullyVisible,
  };

  useEffect(() => {
    if (!validationChecks[validationType]) {
      setCount(componentData?.duration || 3);
      clearTimeout(timer.current);
      return;
    }

    timer.current = setTimeout(() => {
      setShowCountDown(false);

      //// checking if validation completes
      if (count == 1 && validationChecks[validationType]) {
        updateIndex();
        dispatch(setFirstTimeUser(false));
      }

      if (validationChecks[validationType]) {
        setCount((count) => {
          return count - 1;
        });
      } else {
        setCount(componentData?.duration || 3);
      }
      setShowCountDown(true);
    }, 1000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [validationChecks[validationType], count]);

  ////////////////////////////////////////////////////////
  ////////////////////////////////// INITIATION VIA HANDSUP

  // useEffect(() => {
  //   if (
  //     workoutFlowComponentType !==
  //     CONFIG.WORKOUTFLOW_COMPONENT_TYPES.cameraSetupHandsUpValidation
  //   )
  //     return;
  //   if (!handsPositionUp) {
  //     setCount(CONFIG.timer);
  //     clearTimeout(timer.current);
  //   }

  //   timer.current = setTimeout(() => {
  //     if (count === 0 && handsPositionUp) {
  //       updateIndex();
  //     }

  //     if (handsPositionUp && fullyVisible) {
  //       setCount((count) => {
  //         return count - 1;
  //       });
  //     } else {
  //       setCount(CONFIG.timer);
  //     }
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timer.current);
  //   };
  // }, [count, handsPositionUp]);

  if (showCountDown) {
    return (
      <Card data-testid="fitInScreenCounterTest">
        <TimerText>{count}</TimerText>
      </Card>
    );
  }

  return null;
};

export default CameraValidation;
