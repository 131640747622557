import * as React from "react";
import { styled, css } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { TransitionGroup } from "react-transition-group";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { selectWorkoutFlow } from "../../../../store/fit/workout";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { getCompanyNameFromUrl } from "../../../../utils/urls";

import { elementsToShowInList } from "./listConfig";

const ExercisesContainer = styled("div")`
  ${({ theme }) =>
    css`
      ${scrollbarStyles}
    `}
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  max-height: 200px;
  height: 20%;
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border-radius: 20px;
  backdrop-filter: blur(25px);
  background-color: rgba(0, 0, 0, 0.3);
`;

const TimeText = styled("p")`
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 5px;
  white-space: nowrap;
`;

const ExerciseElement = styled("div")`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 0px;
  ${({ $index, theme }) =>
    $index === 0 && `border: 1px solid ${theme.palette.primary.main});`}
`;

const ExerciseTimeContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const ExerciseNameContainer = styled("div")`
  display: flex;
  width: 100%;
`;

const ExerciseText = styled("p")`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;

  color: ${({ theme }) => theme.palette.primary.main};
`;

const scrollbarStyles = css`
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    margin: 13px;
    background: #000000;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #8e8e8e;
    border-radius: 20px;
    border: 4px solid #000000;
  }
`;

const ExerciseList = () => {
  const { componentData } = useContext(WorkoutContext);
  const workoutFlow = useSelector(selectWorkoutFlow)?.workoutFlow;
  const events = workoutFlow?.map((event, index) => {
    if (elementsToShowInList?.includes(event?.type)) {
      return {
        ...event,
        id: `id${index}`,
      };
    } else
      return {
        id: `id${index}`,
        name: undefined,
        duration: undefined,
      };
  });

  const companyName = getCompanyNameFromUrl();
  const [renderedEvents, setRenderedEvents] = useState(events);

  useEffect(() => {
    setRenderedEvents(events?.slice(componentData?.index));
  }, [componentData]);

  if (!workoutFlow) return null;
  return (
    <ExercisesContainer>
      <TransitionGroup>
        {renderedEvents.map(({ id, name, duration }, index) => {
          if (!name) return null;
          return (
            <Collapse key={id}>
              <ExerciseElement $index={index}>
                <ExerciseNameContainer>
                  <ExerciseText>{name}</ExerciseText>
                </ExerciseNameContainer>
                <ExerciseTimeContainer>
                  <TimeText>{(duration / 60).toFixed(1)}min</TimeText>
                </ExerciseTimeContainer>
              </ExerciseElement>
            </Collapse>
          );
        })}
      </TransitionGroup>
    </ExercisesContainer>
  );
};

export default ExerciseList;
