import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useContext } from "react";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import TrainerVideo from "./TrainerVideo";
import { getCompanyNameFromUrl } from "../../../utils/urls";
import clients from "../../../constants/clients";

const Card = styled("div")`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  padding: 10px;
  top: 0;
  background-color: black;
`;

const ExerciseNameContainer = styled("div")`
  display: flex;
`;

const ExerciseName = styled("h1")`
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 32px;
  padding-bottom: 15px;
  text-transform: uppercase;
  text-align: center;
`;

const TimerText = styled("div")`
  color: ${({ theme }) => theme.palette.primary.main};
  font-height: 600;
  font-size: 70px;

  -webkit-animation: scale-down-center 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: scale-down-center 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  @-webkit-keyframes scale-down-center {
    0% {
      -webkit-transform: scale(2);
      transform: scale(2);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-down-center {
    0% {
      -webkit-transform: scale(2);
      transform: scale(2);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Tips = () => {
  const [showCountDown, setShowCountDown] = useState(true);
  const { componentData, updateIndex, setShowGoOnStart } = useContext(WorkoutContext);
  const exerciseName = componentData?.exerciseName;
  const duration = componentData?.duration;
  const [countdown, setCountDown] = useState(duration || 5);

  const clientName = getCompanyNameFromUrl();
  const { showGoAfterTips } = clients(clientName);

  useEffect(() => {
    setCountDown(componentData?.duration);
  }, [componentData]);

  useEffect(() => {
    // if (!showGoAfterFitInScreen) setShowGoOnStart(true);
    if (showGoAfterTips) setShowGoOnStart(true);
  }, []);

  ///// Tips timer
  useEffect(() => {
    let timer = setTimeout(() => {
      setShowCountDown(false);
      setCountDown((countdown) => {
        return countdown - 1;
      });
      setShowCountDown(true);
    }, 1000);
    //
    if (countdown <= 0) updateIndex();
    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

  return (
    <Card>
      <ExerciseNameContainer>
        <ExerciseName>{exerciseName}</ExerciseName>
      </ExerciseNameContainer>
      <TrainerVideo />
      {showCountDown ? (
        <TimerText data-testid="tipsCountdownTest">{countdown}</TimerText>
      ) : null}
    </Card>
  );
};

export default Tips;
