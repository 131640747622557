import { clientNamesToIgnoreNewUI } from "../../../constants/clients";
import { useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useRef } from "react";
import { styled } from "@mui/material";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { CONFIG } from "../../../config";
import { CHART_OPTIONS } from "./chartOptions";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import { getCompanyNameFromUrl } from "../../../utils/urls";

const ChartContainer = styled("div")`
  ${({ $screenWidth }) =>
    $screenWidth > CONFIG.DesktopTrigger
      ? `position: absolute;  bottom: 20px; 
width: 95%; max-height: 400px;`
      : `
position: absolute;
bottom: 80px;
width: 95%;
max-height: 400px;
transform: translate(-50%, 0%);
top: 40px;
max-width: 550px;
left: 50%;`}

  height: 18%;
  color: black;
  z-index: 3;
  flex-direction: column;
  border-radius: 25px;
  backdrop-filter: blur(25px);
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  @media (max-width: 540px) {
    top: 40px;
    z-index: 7;
  }
`;

let tick = 0;
const seriesData = [];

const StaticExerciseChart = () => {
  const { clientId } = useParams();
  const { newScore, newScoreTimestamp, startedPoseEstimation, componentData } =
    useContext(WorkoutContext);

  const exerciseName = componentData?.exerciseName;

  const [displayChart, setDisplayChart] = useState(false);
  const { palette } = useMuiTheme();
  const screenWidth = useWindowWidth();

  const [chartOptions, setChartOptions] = useState(CHART_OPTIONS);
  const [data, setData] = useState({
    xaxis: undefined,
    series: [{ data: seriesData }],
  });
  const chartRef = useRef();

  useEffect(() => {
    seriesData.splice(0, seriesData.length);
    setDisplayChart(startedPoseEstimation);
  }, [startedPoseEstimation]);

  //Resetting data array after exercise is complete
  useEffect(() => {
    if (!startedPoseEstimation) seriesData.splice(0, seriesData.length);
  }, [startedPoseEstimation]);

  useEffect(() => {
    if (newScore === null) return;
    if (newScore === -1) seriesData?.push(0);
    else {
      seriesData?.push(newScore);
    }
    tick++;

    setData({
      xaxis: tick,
      series: [{ data: seriesData, name: exerciseName }],
    });

    setChartOptions({
      ...CHART_OPTIONS,
      xaxis: { max: componentData?.duration },
      title: {
        text: undefined,
      },
      yaxis: { labels: { style: { colors: [palette.primary.main] } } },
    });
  }, [newScoreTimestamp]);

  if (
    screenWidth < CONFIG.MobileBreakpoint &&
    !clientNamesToIgnoreNewUI.includes(clientId)
  )
    return null;

  return (
    <ChartContainer data-test-id="chartTest" id="chart" $screenWidth={screenWidth}>
      <ReactApexChart
        options={chartOptions}
        series={data.series}
        type="line"
        height={"100%"}
        ref={chartRef}
      />
    </ChartContainer>
  );
};

export default StaticExerciseChart;
