import { useContext, useEffect, useRef, useState } from "react";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { Collapse, styled } from "@mui/material";

const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 45px;
  background-color: #690808;
  clip-path: polygon(10% 0, 90% 0, 100% 100%, 0 100%);
`;

const MobileExerciseName = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  text-align: center;
  max-width: 100%;
  color: #fff;
  padding: 0 30px 0 30px;
  text-transform: uppercase;
  overflow-wrap: break-word;
`;

const ExerciseName = () => {
  const { componentData, showMobileComponent, paused } = useContext(WorkoutContext);

  const exerciseName = componentData?.exerciseName;

  if (!exerciseName) return null;

  return (
    <Collapse in={showMobileComponent || paused} collapsedSize={10}>
      <Wrapper>
        <MobileExerciseName>{exerciseName}</MobileExerciseName>
      </Wrapper>
    </Collapse>
  );
};

export default ExerciseName;
