export const quizTextMap = {
  1: "ENTER YOUR EMAIL",
  2: "HOW OFTEN  DO YOU WORKOUT",
  3: "WHAT IS YOUR:",
  4: "WHAT IS YOUR GENDER?",
  5: "SELECT BODY PARTS YOU WANT TO TRAIN",
};

export const howOftenWorkout = [
  { text: "Once a day", howOften: 4 },
  { text: "2-3 times per week", howOften: 3 },
  { text: "Once per week", howOften: 2 },
  { text: "Once per month", howOften: 1 },
];