import { useDispatch, useSelector } from "react-redux";
import {
  selectAccountUser,
  selectAccountUserLoading,
  setUser,
} from "../store/fit/account";
import { useAuthenticator } from "@aws-amplify/ui-react";

const useAuth = () => {
  const dispatch = useDispatch();
  const { user, route, signOut } = useAuthenticator((context) => [
    context?.user,
    context?.route,
  ]);

  const reduxUser = useSelector(selectAccountUser);
  const reduxUserLoading = useSelector(selectAccountUserLoading);

  if (reduxUser?.tokenValid) {
    const username = reduxUser.user?.username;
    const name = reduxUser.user?.name;
    const email = reduxUser.user?.email;

    const logoutReduxUser = () => {
      localStorage.removeItem("jwt");
      dispatch(setUser(undefined));
    };

    return {
      username,
      name,
      email,
      status: reduxUser?.tokenValid ? "authenticated" : "not-authenticated",
      loading: reduxUserLoading,
      signOut: logoutReduxUser,
    };
  }

  return {
    username: user?.username,
    name: user?.attributes?.name,
    email: user?.attributes?.email,
    status: route,
    loading: reduxUserLoading,
    signOut,
  };
};

export default useAuth;
