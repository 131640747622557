import React, { useContext } from "react";
import { useEffect } from "react";
import { useStopwatch } from "react-timer-hook";
import EnduranceTimeout from "../EnduranceTimeout";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { forceStartStopSoundComponentTypes } from "../Countdown/countdownConfig";
import { getCompanyNameFromUrl } from "../../../../../utils/urls";
import clients from "../../../../../constants/clients";

const Stopwatch = ({ showEnduranceTimeOut, startTimer }) => {
  const { setTimePassed, componentData, setStaticStartTimer } =
    useContext(WorkoutContext);

  const companyName = getCompanyNameFromUrl();
  const { forcePlayStartStopSound } = clients(companyName);

  const { seconds, minutes, start, isRunning } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    if (startTimer) start();
  }, [startTimer]);

  // Calculate and set time passed when seconds or minutes change
  useEffect(() => {
    // Convert minutes to seconds and add them to seconds
    const totalSecondsPassed = minutes * 60 + seconds;
    setTimePassed(totalSecondsPassed);
  }, [seconds, minutes]);

  ///// REACTION TIMER SETTING FOR SOUNDS
  useEffect(() => {
    if (
      forcePlayStartStopSound &&
      forceStartStopSoundComponentTypes.includes(componentData?.type)
    )
      setStaticStartTimer(isRunning);
  }, [isRunning]);

  if (showEnduranceTimeOut === true) return <EnduranceTimeout />;

  return (
    <p>
      {minutes}:{seconds < 10 ? "0" + seconds : seconds}
    </p>
  );
};

export default Stopwatch;
