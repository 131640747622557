import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";
import { getCompanyNameFromUrl } from "../../../../utils/urls";
import clients from "../../../../constants/clients";
import { css } from "@mui/material";

const ProgressContainer = styled.div`
  color: #7cbb7e;
  position: relative;
  min-width: 61px;
  min-height: 63px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(110, 244, 107, 0.3) 0%,
    rgba(244, 107, 107, 0.006) 100%
  );
`;

const RepCountContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CurrentRepsText = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
`;

const TotalRepsText = styled.p`
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const Progress = ({ totalReps, repCount }) => {
  const companyName = getCompanyNameFromUrl();
  const { styles } = clients(companyName);

  return (
    <ProgressContainer>
      {totalReps ? (
        <CircularProgress
          color="inherit"
          style={{ minWidth: 61, minHeight: 63 }}
          variant="determinate"
          value={(repCount / totalReps) * 100}
        />
      ) : null}
      <RepCountContainer>
        <CurrentRepsText>{repCount}</CurrentRepsText>
        {totalReps ? <>/</> : null}
        {totalReps ? <TotalRepsText>{totalReps}</TotalRepsText> : null}
      </RepCountContainer>
    </ProgressContainer>
  );
};

export default Progress;
