import { CONFIG } from "../../../../config";

export const repetitionSoundPlayComponentTypes = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
];

export const voicePlayComponentTypes = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const playFiveSecondsLeftVoice = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
];

export const playThirtySecondsLeftVoice = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const exerciseNamePlayComponentTypes = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const exerciseStartSoundPlayComponentTypes = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
];

export const startStopSoundsPlayComponentTypes = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const playTimerVoiceComponentTypes = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
];

export const clientNamesToPlayTimeLeft = ["reaction-club"];

export const TimerVoiceTempMap = {
  10: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Ten.mp3",
  20: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Twenty.mp3",
  30: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Thirty.mp3",
  40: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Forty.mp3",
  50: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Fifty.mp3",
  60: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/One-minute.mp3",
  120: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Two-minutes.mp3",
  180: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Three-minutes.mp3",
  240: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Four-minutes.mp3",
  300: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Five-minutes.mp3",
  360: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Six-Minutes.mp3",
  420: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Seven-Minutes.mp3",
  480: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Eight-Minutes.mp3",
  540: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Nine-minutes.mp3",
  600: "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Ten-minutes.mp3",
  timeIsUp:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/timerVoice/man_1/Time-is-up.mp3",
};

export const playVoiceStartStopClientNames = ["reaction-club"];

export const challengeSoundLevels = {
  losingHard: "losingHard",
  winningHard: "winningHard",
  losingSlightly: "losingSlightly",
  slightlyWinning: "slightlyWinning",
  evenlyMatched: "evenlyMatched",
};

export const challengeStatusVoicePlayEvery = 10;

export const challengeVoiceMap = {
  youAreWayBehind:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-way-behind-Move-it.mp3",
  youAreGettingSmoked:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-getting-smoked-pick-up.mp3",
  youAreGettingCrushed:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-getting-crushed-step-i.mp3",
  youAreFallingBehind:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-falling-behind-Catch-u.mp3",
  wayInFront:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-way-in-front-finish-st.mp3",
  youAreMilesAhead:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-miles-ahead-dont-slow.mp3",
  oppomentEatingDust:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_The-opponent-is-eating-your-du.mp3",
  crushingCompetitor:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-crushing-the-competitor.mp3",
  closeTheGap:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Close-the-gap-the-competitor.mp3",
  youAreTrailing:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-trailing-push-it.mp3",
  rivalPullingAhead:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_The-rival-is-pulling-ahead-do.mp3",
  dontLetOutpace:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Dont-let-the-challenger-outpa.mp3",
  youAreSlightlyWinning:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-slightly-ahead-keep-pu.mp3",
  youGotAnEdge:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youve-got-the-edge-dont-los.mp3",
  stayInFront:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Stay-in-front-the-challenger.mp3",
  youAreLeading:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-leading-Keep-up-the-pr.mp3",
  youAreAhead:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-ahead-dont-get-compla.mp3",
  soClose:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_So-close-dont-let-the-conten.mp3",

  deadHeat:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Its-a-dead-heat-dont-let-up.mp3",
  evenlyMatched:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Youre-evenly-matched-show-th.mp3",
  tightRace:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_This-is-a-tight-race-give-it.mp3",
  neckAndNeck:
    "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/SubBlock_Its-neck-and-neck-push-harde.mp3",
};

export const challengeStartSound =
  "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/challenge-beginning-message.mp3";

export const losingHardArray = [
  "youAreWayBehind",
  "youAreGettingSmoked",
  "youAreGettingCrushed",
  "youAreFallingBehind",
];
export const winningHardArray = [
  "wayInFront",
  "youAreMilesAhead",
  "oppomentEatingDust",
  "crushingCompetitor",
];
export const losingSlightlyArray = [
  "closeTheGap",
  "youAreTrailing",
  "rivalPullingAhead",
  "dontLetOutpace",
];
export const slightlyWinningArray = [
  "youAreSlightlyWinning",
  "youGotAnEdge",
  "stayInFront",
  "youAreLeading",
  "youAreAhead",
];
export const evenlyMatchedArray = [
  "soClose",
  "deadHeat",
  "evenlyMatched",
  "tightRace",
  "neckAndNeck",
];
