import { Tab, Tabs, styled } from "@mui/material";
import Chart from "react-apexcharts";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { GRAPH_OPTIONS } from "./graphOptions";
import { useEffect, useState } from "react";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import {
  selectWorkoutsDashboard,
  selectWorkoutsDashboardLoading,
} from "../../../../store/fit/dashboard";
import DashboardSkeleton from "./DashboardSkeleton";

const graphTypeArray = ["kcal", "accuracy", "score"];

const graphTypes = {
  score: "performanceScore",
  accuracy: "performanceAccuracyPercentage",
  kcal: "performanceBurnedKcal",
};

const data = [];

const WorkoutsGraph = () => {
  const [graphType, setGraphType] = useState(graphTypes.score);
  const [graphOptions, setGraphOptions] = useState(GRAPH_OPTIONS);
  const { palette } = useMuiTheme();
  const [graphSeries, setGraphSeries] = useState([
    { name: graphType, data: data },
  ]);
  const [graphName, setGraphName] = useState("score");
  const [value, setValue] = useState(0);

  const width = useWindowWidth();
  const summaries = useSelector(selectWorkoutsDashboard);
  const workoutsDashboardLoading = useSelector(selectWorkoutsDashboardLoading);

  useEffect(() => {
    if (!summaries) return;
    //reseting graph data
    data.splice(0, data.length);
    //setting new graph data
    summaries.forEach((summary, index) => {
      data.push([index, summary?.meta[graphType]]);
      // data.push([summary.timestamp * 1000, summary?.meta[graphType]]);
    });
    setGraphOptions({
      ...GRAPH_OPTIONS,
      title: {
        text: graphName.charAt(0).toUpperCase() + graphName.slice(1),
      },
      yaxis: { labels: { style: { colors: [palette.primary.main] } } },
      stroke: {
        colors: [palette.primary.main],
      },
    });
    setGraphSeries([{ name: graphName, data: data }]);
  }, [graphType, workoutsDashboardLoading]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setGraphName(graphTypeArray[value]);

    setGraphType(graphTypes[graphTypeArray[value]]);
  }, [value]);

  if (workoutsDashboardLoading) return <DashboardSkeleton />;

  return (
    <>
      <Tabs
        onChange={handleChange}
        value={value}
        selectionFollowsFocus
        style={{ justifyContent: "flex-end" }}
      >
        <Tab label="kcal" />
        <Tab label="accuracy" />
        <Tab label="score" />
      </Tabs>
      <Chart
        options={graphOptions}
        height={width < 600 ? 180 : 250}
        series={graphSeries}
      />
    </>
  );
};

export default WorkoutsGraph;
