import { styled } from "@mui/material";
import { useContext } from "react";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { isMobile } from "react-device-detect";
import useWindowWidth from "../../../hooks/useWindowWidth";
import useWindowHeight from "../../../hooks/useWindowHeight";

const FitInScreenText = styled("p")`
  color: ${({ theme }) => theme.palette.primary.main};
  font-height: 600;
  font-size: 40px;
  text-align: center;
  width: 275px;
  ${({ $isHorizontalMode }) => ($isHorizontalMode ? `width: 500px` : "")}
`;

const Card = styled("div")`
  position: absolute;
  bottom: 50px;
  margin-right: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  z-index: 100;
  padding: 10px;
  background-color: rgba(74, 63, 22, 0.5);
  border: solid 3px;
  border-color: #f8d34a;
  border-radius: 20px;
`;

const textMap = {
  fitInScreen: "FIT IN SCREEN",
  lieDown: "LIE DOWN \n  HORIZONTALLY",
};

const ReactionClubFitInScreen = () => {
  const { componentData } = useContext(WorkoutContext);

  const screenWidth = useWindowWidth();
  const screenHeight = useWindowHeight();
  const isHorizontalMode = screenHeight < screenWidth && isMobile;

  return (
    <Card data-testid="fitInScreenCounterTest">
      <FitInScreenText $isHorizontalMode={isHorizontalMode}>
        {textMap[componentData?.validation]}
      </FitInScreenText>
    </Card>
  );
};

export default ReactionClubFitInScreen;
