import { useContext } from "react";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { getCompanyNameFromUrl } from "../../../../../utils/urls";
import clients from "../../../../../constants/clients";

const EnduranceTimeout = () => {
  const { nextStageWorkout, setTimeLeft, setTimeIsUp } = useContext(WorkoutContext);
  const clientName = getCompanyNameFromUrl();
  const { disableCloseOnEnd } = clients(clientName);

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 5);

  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire: () => {
      if (disableCloseOnEnd) {
        setTimeIsUp(true);
      } else nextStageWorkout(true);
    },
  });

  useEffect(() => {
    const secondsLeft = minutes * 60 + seconds;

    setTimeLeft(secondsLeft);
  }, [seconds, minutes]);

  return (
    <p data-testid={"enduranceExerciseTimerTest"}>
      {minutes}:{seconds < 10 ? "0" + seconds : seconds}
    </p>
  );
};

export default EnduranceTimeout;
