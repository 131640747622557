import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectWorkoutSummary } from "../../../../../store/fit/summary";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(8, 23, 62, 0.2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 25px;
  width: fit-content;
  padding: 24px;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0.25px;
  color: #ffffff;

  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const TipsTextCard = ({ currentSlide }) => {
  const { exerciseTips } = useSelector(selectWorkoutSummary) || {};

  const currentTip = exerciseTips?.[currentSlide];

  if (!currentTip?.tips?.length) return null;

  return (
    <Wrapper>
      <InnerWrapper>
        {currentTip?.tips.map((x, i) => {
          return <Text key={i}>&#x2022; {x}</Text>;
        })}
      </InnerWrapper>
    </Wrapper>
  );
};

export default TipsTextCard;
