import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "../../../../../components/Buttons/Button";
import { devices } from "../../../../../config";
import { GENDERS, GENDER_OPTIONS } from "../../../../../constants";
import { setGender } from "../../../../../store/fit/quiz";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledButton = styled(Button)`
  max-width: 300px;
  width: 95vw;

  @media ${devices.tablet} {
    max-width: 350px;
  }

  @media ${devices.laptop} {
    max-width: 420px;
  }
`;

const GenderForm = ({ setQuizProgress, quizProgress }) => {
  const dispatch = useDispatch();

  const genderHandler = (gender) => {
    dispatch(setGender(gender));
    setQuizProgress(() => quizProgress + 1);
  };

  return (
    <Wrapper>
      {Object.values(GENDERS).map((x, i) => (
        <StyledButton size="large" key={i} onClick={() => genderHandler(x)}>
          {GENDER_OPTIONS[x]}
        </StyledButton>
      ))}
    </Wrapper>
  );
};

export default GenderForm;
