import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";
import useVideoPlayer from "../../../../../hooks/useVideoPlayer";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  flex-direction: column;
  min-width: 140px;
  height: 270px;
  max-height: 270px;
  width: 90%;
  background: rgba(217, 217, 217, 0.9);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(21px);
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 140px;
  min-height: 55px;
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 30px 30px;
  background-color: rgba(18, 2, 11, 0.35);
  position: relative;
  top: 5px;
  padding: 8px;
`;

const Name = styled.h2`
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const VideoWrapper = styled.video`
  height: 80%;

  @media (max-width: 600px) {
    height: 100%;
  }
`;

const TipsCard = ({ play, name, videoUrl, video, videoPoster, onClick }) => {
  const videoElement = useRef(null);

  const { setPlaying } = useVideoPlayer(videoElement);

  useEffect(() => {
    setPlaying(play);
  }, [play]);

  return (
    <Wrapper className="tips-video-card" onClick={onClick}>
      <VideoWrapper
        muted
        loop
        autoPlay={play}
        src={videoUrl || video}
        poster={videoPoster}
        ref={videoElement}
      />
      <NameWrapper>
        <Name>{name}</Name>
      </NameWrapper>
    </Wrapper>
  );
};

export default TipsCard;
