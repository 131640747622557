import React from "react";

import styled from "styled-components";
// import { borderRadius, height, space, width } from "styled-system";
/* ${space};
  ${width};
  ${height};
  ${borderRadius}; */
import shimmer from "../../common/animations/shimmer";

const SkeletonStyle = styled.div`
  position: relative;
  display: ${({ $display }) => $display || "block"};
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); //Safari bug https://discourse.webflow.com/t/safari-not-hiding-overflow-on-rounded-corner-divs/55060

  &::before {
    position: absolute;
    left: 0px;
    top: 0px;
    content: "";
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation: ${shimmer} 2s infinite;
  }
`;

const SkeletonStyleDark = styled(SkeletonStyle)`
  background: #12284a;

  &::before {
    background-image: linear-gradient(
      90deg,
      rgba(18, 40, 74, 0.7) 0%,
      rgba(255, 255, 255, 0.07) 53%,
      rgba(18, 40, 74, 0.7) 100%
    );
  }
`;

const SkeletonStyleLight = styled(SkeletonStyle)`
  background: #e7e7e7;

  &::before {
    background-image: linear-gradient(
      90deg,
      #e7e7e7 0%,
      #dfdfdf 53%,
      #e7e7e7 100%
    );
  }
`;

const Skeleton = ({
  theme = "dark",
  width = 200,
  height = 200,
  borderRadius = 8,
  display = "block",
  ...props
}) => {
  return theme === "dark" ? (
    <SkeletonStyleDark
      width={width}
      height={height}
      borderRadius={borderRadius}
      $display={display}
      {...props}
    />
  ) : (
    <SkeletonStyleLight
      width={width}
      height={height}
      borderRadius={borderRadius}
      $display={display}
      {...props}
    />
  );
};

export default Skeleton;
