import { useEffect, useState } from "react";

export const MOBILE_TYPE = {
  IOS: "iOS",
  ANDROID: "android",
  SAMSUNG: "samsung",
};

export const useMobileTypeDetector = () => {
  const [mobileType, setMobileType] = useState(null);

  const iOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const samsungBrowser = () => {
    const userAgent = navigator.userAgent;
    return /SamsungBrowser/.test(userAgent);
  };

  useEffect(() => {
    if (iOS()) setMobileType(MOBILE_TYPE.IOS);
    else if (samsungBrowser()) setMobileType(MOBILE_TYPE.SAMSUNG);
    else setMobileType(null);
  }, []);

  return mobileType;
};
