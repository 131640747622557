import { ChevronLeft } from "@mui/icons-material";
import { LinearProgress, styled } from "@mui/material";
import Button from "../../../../components/Buttons/Button";
import { devices } from "../../../../config";

const StyledLinearProgress = styled(LinearProgress)`
  background-color: #7a7a7a;
  border-radius: 50px;

  .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  padding-top: 8px;

  @media ${devices.mobileL} {
    padding-top: 30px;
  }

  @media ${devices.laptop} {
    padding-top: 40px;
  }
`;

const ControlsContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ProgressContainer = styled("div")`
  padding-top: 24px;
  width: 100%;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: ${({ button }) =>
    button === "back" ? "flex-start" : "flex-end"};
`;

const SkipButtonText = styled("p")`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    color: #a8a8ab;
  }
`;

const TextBox = styled("div")`
  display: flex;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CurrentPageText = styled("p")`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
`;

const OutOfPagesText = styled("p")`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #7a7a7a;
`;

const QuizProgress = ({ quizProgress, setQuizProgress }) => {
  const clickHandler = (buttonType) => {
    if (buttonType === "skip") setQuizProgress(() => quizProgress + 1);
    if (buttonType === "back") setQuizProgress(() => quizProgress - 1);
  };

  return (
    <Wrapper>
      <ControlsContainer>
        <ButtonWrapper button={"back"}>
          {quizProgress !== 1 && (
            <Button onClick={() => clickHandler("back")}>
              <ChevronLeft />
            </Button>
          )}
        </ButtonWrapper>
        <TextBox>
          <CurrentPageText>{quizProgress} </CurrentPageText>
          <OutOfPagesText>&nbsp;of 5</OutOfPagesText>
        </TextBox>
        <ButtonWrapper button={"skip"}>
          {quizProgress !== 5 && (
            <Button onClick={() => clickHandler("skip")}>skip</Button>
          )}
        </ButtonWrapper>
      </ControlsContainer>
      <ProgressContainer>
        <StyledLinearProgress
          variant="determinate"
          value={(quizProgress / 5) * 100}
        />
      </ProgressContainer>
    </Wrapper>
  );
};

export default QuizProgress;
