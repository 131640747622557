import styled, { css } from "styled-components";
import { getIconByName } from "../../../../../../utils/getIconByName";

const IconWrapper = styled.div`
  ${({ $height = "100%", $width = "100%" }) => css`
    display: flex;
    justify-content: right;
    height: ${$height};
    width: ${$width};
  `}
`;

const iconMap = {
  legs: { name: "LegsIcon", height: "90%" },
  chest: { name: "ChestIcon", width: "65%" },
  back: { name: "BackIcon", width: "70%" },
  shoulders: { name: "ShouldersIcon", height: "90%" },
  arms: { name: "ArmsIcon", height: "90%" },
  abs: { name: "AbsIcon", height: "80%", width: "80%" },
};

const FormIcon = ({ iconName }) => {
  const { name, height, width } = iconMap[iconName];
  const Icon = getIconByName(name);

  return (
    <IconWrapper $height={height} $width={width}>
      <Icon />
    </IconWrapper>
  );
};

export default FormIcon;
