import { ChevronLeft } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, styled } from "@mui/material";
import Button from "../../../../../components/Buttons/Button";
import { CONFIG } from "../../../../../config";
import useWindowWidth from "../../../../../hooks/useWindowWidth";

import { useState } from "react";
import MenuPopup from "./MenuPopup";

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 24px auto;
  position: relative;
  width: 100%;
  max-width: 1000px;
`;

const ScreenName = styled("h1")`
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
  width: 100%;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  height: min-content;
`;

const Header = () => {
  const [sideMenuOpen, setSideMenuOpen] = useState(null);
  const breakpoint = useWindowWidth() < CONFIG.SelectionScreenBreakpoint;
  const navigate = useNavigate();
  const { appType, clientId } = useParams();
  const open = Boolean(sideMenuOpen);

  const clickHandler = () => {
    navigate(`/${appType}/${clientId}/quiz`);
  };

  const handleMenuState = (open) => {
    setSideMenuOpen(open);
  };

  return (
    <Wrapper>
      <StyledButton
        startIcon={!breakpoint && <ChevronLeft />}
        onClick={clickHandler}
      >
        {breakpoint ? (
          <ChevronLeft />
        ) : (
          <>
            <p>Quiz</p>
          </>
        )}
      </StyledButton>
      <ScreenName>CHOOSE YOUR {breakpoint && <br />} WORKOUT</ScreenName>
      <>
        <StyledButton onClick={() => handleMenuState(true)}>Menu</StyledButton>
        <Drawer
          anchor="right"
          open={open}
          onClose={() => handleMenuState(false)}
        >
          <MenuPopup handleMenuState={handleMenuState} />
        </Drawer>
      </>
    </Wrapper>
  );
};

export default Header;
