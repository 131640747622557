const Message = {
  closeRequested: "closeRequested",
  initiationCompleted: "initiationCompleted",
  indexUpdated: "indexUpdated",
  willShowTrainingSummary: "willShowTrainingSummary",
};

export class NativeBridge {
  //   static shared = new NativeBridge();

  //   eventsBase = new EventsBase();

  get isAndroid() {
    return !window.webkit?.messageHandlers?.main ?? false;
  }

  sendIOSmessage(message) {
    window.webkit?.messageHandlers?.main?.postMessage(JSON.stringify(message));
  }
  sendAndroidMessage(message) {
    window.Android?.sendMessage(JSON.stringify(message));
  }

  send(message) {
    if (this.isAndroid) {
      this.sendAndroidMessage(message);
    } else {
      this.sendIOSmessage(message);
    }
  }

  // App calls the method to send a request (event) that should be handled by WebView
  //   execute(command) {
  //     this.eventsBase.emitter(command.type).emit(command.type, command);
  //   }
  // Subscribe on events that come from App
  //   on(type, listener) {
  //     this.eventsBase.emitter(type).on(type, listener);
  //   }

  // It's not clear code so, you might move the code that doesn't belong to the class, somewhere else
  close() {
    const param = new URL(document.location).searchParams
      .get("suppressFinish")
      ?.toLowerCase();
    const shouldSupressFinish = param === "true";
    if (shouldSupressFinish) {
      this.send({ type: Message.closeRequested });
    } else {
      // do your own logic
    }
  }

  // The better approach, that keeps the class responsible only for reasonable scope of tasks
  closeRequested() {
    console.log(Message.closeRequested);
    this.send({ type: Message.closeRequested });
  }

  initiationCompleted() {
    console.log(Message.initiationCompleted);
    this.send({ type: Message.initiationCompleted });
  }

  sendIndex(index) {
    console.log(Message.indexUpdated);
    this.send({ type: Message.indexUpdated, index: index });
  }

  willShowTrainingSummary() {
    console.log(Message.willShowTrainingSummary);
    this.send({ type: Message.willShowTrainingSummary });
  }
}
