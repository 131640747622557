import { Howl } from "howler";

export const createSounds = (object, volume, onLoad, onEnd) => {
  let soundsObject = {};
  Object.keys(object).forEach((key) => {
    const url = object[key];
    if (!url) return;
    soundsObject[key] = new Howl({
      src: [url],
      volume: volume,
      // html5: true, // Force the use of HTML5 Audio API
      onloaderror: (id, message) => {
        console.error(id, message);
      },
      onend: () => onEnd && onEnd(),
      onload: () => onLoad && onLoad(),
    });
  });
  return soundsObject;
};
