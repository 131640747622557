import axios from "axios";
import { getCookie } from "../utils/Cookie";

const xhrRequest = ({
  url,
  method = "GET",
  data = {},
  headers = {},
  tryAuth = true,
  jwt,
}) => {
  let options = {
    headers: { ...headers },
    timeout: 30000,
  };

  if (tryAuth) {
    const cookiejwt = getCookie("jwt");
    // Use `jwt` if it's defined; otherwise, use `cookiejwt`.
    const authJwt = jwt || cookiejwt;
    if (authJwt) {
      options = {
        ...options,
        headers: {
          ...headers,
          authorization: `Bearer ${authJwt}`,
        },
      };
    }
  }

  if (method === "POST") {
    options.headers["Content-Type"] = "application/json";
  }

  if (method === "POST") {
    return axios.post(url, data, options);
  }
  if (method === "PUT") {
    return axios.put(url, data, options);
  }

  if (method === "GET") {
    return axios.get(url, options);
  }
};

export default xhrRequest;
