import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { fittyWorkoutTutorialFeature } from "../../../utils/features";

const Wrapper = styled("div")`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 200px;
  height: 200px;
  background-color: #54b862;
  border-radius: 70px;
  z-index: 100;

  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both,
    slide-out-fwd-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;

  animation: scale-in-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both,
    slide-out-fwd-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;

  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-out-fwd-center {
    0% {
      -webkit-transform: translateZ(1);
      transform: translateZ(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(600px);
      transform: translateZ(600px);
      opacity: 0;
    }
  }

  @keyframes slide-out-fwd-center {
    0% {
      -webkit-transform: translateZ(1);
      transform: translateZ(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(600px);
      transform: translateZ(600px);
      opacity: 0;
    }
  }
`;

const StyledText = styled("div")`
  color: #fff;
  // color: ${({ theme }) => theme.palette.primary.main};
  font-height: 600;
  font-size: 75px;
`;

const Go = () => {
  return (
    <Wrapper data-testid="goTest">
      <StyledText>GO</StyledText>
    </Wrapper>
  );
};

export default Go;
