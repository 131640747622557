import { useEffect } from "react";

import { useNavigate, useLocation, useParams } from "react-router";
import styled from "styled-components";
import FloatingLogo from "../../../components/FloatingLogo";
import { getUserAsync, selectAccountUser, setUser } from "../../../store/fit/account";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { getCompanyNameFromUrl } from "../../../utils/urls";

const ExternalAuthLanding = () => {
  const { appType, clientId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectAccountUser);
  const { reduxUser } = useAuth();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const clientName = getCompanyNameFromUrl();

  const token = query.get("token");

  const logOut = () => {};
  useEffect(() => {
    if (token) {
      dispatch(getUserAsync({ token }));
      navigate(`/${appType}/${clientId}/selection`, { replace: true });
      localStorage.setItem("jwt", token);
      localStorage.setItem("clientName", clientName);
    }
  }, [token]);

  return (
    <Wrapper>
      Loading...
      <FloatingLogo />
    </Wrapper>
  );
};

export default ExternalAuthLanding;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;
