"use client";
import React from "react";

import { Authenticator } from "@aws-amplify/ui-react";

import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import styled from "styled-components";
import CookieBanner from "./components/CookieBanner";
import {
  CONFIG,
  environments,
  replaysOnErrorSampleRate,
  replaysSessionSampleRate,
  tracesSampleRate,
} from "./config";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import Mirror from "./containers/Mirror";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Testing from "./containers/Testing";
import { ROUTE } from "./routing";
import { selectCookieId } from "./store/fit/quiz";
import { getCompanyNameFromUrl } from "./utils/urls";
import clients from "./constants/clients";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

import { Amplify } from "aws-amplify";
import { getUserAsync } from "./store/fit/account";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackComponent from "./errorHandler/ErrorFallbackComponent";
import * as Sentry from "@sentry/react";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

// Get the clientId from the URL path
const pathSegments = window.location.pathname.split("/");
const sessionId = pathSegments[pathSegments.length - 1]; // Assuming clientId is the first-to-last segment

Sentry.init({
  dsn: "https://c00aebb21d30576662ab17fecdd1c908@o4506591006294016.ingest.sentry.io/4506598737248256",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  environment:
    sessionId === CONFIG.TESTING_CLIENT_ID ? `automated_${environments}` : environments,

  // Performance Monitoring
  tracesSampleRate: sessionId === CONFIG.TESTING_CLIENT_ID ? 0 : tracesSampleRate, //  Capture 25% of the transactions
  // Session Replay

  replaysSessionSampleRate:
    sessionId === CONFIG.TESTING_CLIENT_ID ? 0 : replaysSessionSampleRate, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.

  replaysOnErrorSampleRate:
    sessionId === CONFIG.TESTING_CLIENT_ID ? 0 : replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  const dispatch = useDispatch();
  const cookieId = useSelector(selectCookieId);
  const { appType } = useParams();

  const clientName = getCompanyNameFromUrl();
  //   const client = clientId ? clientId : CONFIG.defaultCompanyName;

  const checkNav = (cookie) => {
    const app = appType ? appType : CONFIG.defaultAppType;
    return `/${app}/${clientName}/${
      cookie ? ROUTE.FIT_SELECTION.PATH : ROUTE.FIT_QUIZ.PATH
    }`;
  };

  const { colors, title, tabIcon, backgroundImage, backgroundColor, cognito } =
    clients(clientName);

  Amplify.configure({
    Auth: {
      region: cognito.REGION,
      userPoolId: cognito.USER_POOL_ID,
      userPoolWebClientId: cognito.USER_POOL_APP_CLIENT_ID,
    },
  });

  // background - color;
  // background - image;
  // background - position;
  // background - size;
  // background - repeat;
  // background - origin;
  // background - clip;
  // background - attachment;
  useEffect(() => {
    if (backgroundImage) {
      document.body.style.backgroundImage = `url("${backgroundImage}")`;
      document.body.style.backgroundPosition = `center`;
      document.body.style.backgroundRepeat = `no-repeat`;
      document.body.style.backgroundSize = `cover`;
    }

    if (backgroundColor) {
      document.body.style.backgroundColor = backgroundColor;
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const storedClientName = localStorage.getItem("clientName", clientName);

    if (token && storedClientName === clientName) {
      dispatch(getUserAsync({ token }));
    } else {
      localStorage.getItem("clientName");
      localStorage.removeItem("jwt");
    }
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000",
        // default: `url("/clients/fitnessgoesoffice/istockphoto-1287504319-170667a.jpg")`,
      },
      ...colors,
    },
  });

  return (
    <Authenticator.Provider>
      <ThemeProvider theme={darkTheme}>
        <Helmet>
          <title>{title}</title>
          <link rel="icon" type="image/png" href={tabIcon} sizes="16x16" />
        </Helmet>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <Wrapper>
            <Routes>
              <Route exact path={"/test"} element={<Testing />} />
              <Route exact path={ROUTE.PRIVACY_POLICY.PATH} element={<PrivacyPolicy />} />
              <Route
                exact
                path={ROUTE.HOME.PATH}
                element={<Navigate to={checkNav(cookieId)} />}
              />
              <Route
                exact
                path={ROUTE.FIT_LANDING.PATH}
                element={<Navigate to={checkNav(cookieId)} />}
              />
              <Route exact path={ROUTE.FIT.PATH} element={<Mirror />} />
            </Routes>

            <CookieBanner />
          </Wrapper>
        </ErrorBoundary>
      </ThemeProvider>
    </Authenticator.Provider>
  );
};

export default App;
