import { CONFIG } from "../config";
import { GUID } from "./GUID";
export let id = GUID();

export const createPayload = (parameters, sessionId) => {
  const eventType = parameters.event_type;
  const payload = { data: {} };
  payload.session_id = sessionId || id;
  payload.timestamp = Date.now().toString();

  switch (eventType) {
    case CONFIG.EVENT_TYPES.session_start:
      payload.company_name = parameters.company_name;
      payload.event_type = eventType;
      payload.user_bio_information = parameters.user_bio_information;
      payload.jwt = parameters.jwt;
      payload.workout_id = parameters.workout_id;
      payload.username = parameters.username;
      payload.name = parameters.name;
      payload.cookie_id = parameters.cookie_id;
      break;

    case CONFIG.EVENT_TYPES.check_connection:
      payload.event_type = eventType;
      break;

    case CONFIG.EVENT_TYPES.session_end:
      payload.event_type = eventType;
      break;

    case CONFIG.EVENT_TYPES.start_exercise:
      payload.exercise_id = parameters.exercise_id;
      payload.event_type = eventType;
      break;

    case CONFIG.EVENT_TYPES.state_update:
      payload.event_type = eventType;
      payload.data.landmarks = optimizeLandmarks(parameters.data.landmarks);
      payload.data.worldLandmarks = optimizeLandmarks(
        parameters.data.worldLandmarks
      );
      payload.company_name = parameters.companyName;
  }

  return JSON.stringify(payload);
};

const optimizeLandmarks = (landmarks) => {
  return landmarks.map((landmark) => {
    return {
      x: reduceDecimal(landmark.x),
      y: reduceDecimal(landmark.y),
      z: reduceDecimal(landmark.z),
      v: reduceDecimal(landmark.visibility),
    };
  });
};

const reduceDecimal = (number) => {
  return Number(number.toFixed(4));
};
