import * as React from "react";

const FlagIcon = (props) => (
  <svg
    width={17}
    height={21}
    fill="#08173E"
    viewBox="0 0 17 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.364 3.323H5.197V.906h2.167v2.417ZM11.697.906H9.53v2.417h2.167V.906Zm-6.5 12.084h2.167v-2.417H5.197v2.417ZM16.03 8.156V5.74h-2.166v2.416h2.166Zm0 4.834v-2.417h-2.166v2.417h2.166Zm-6.5 0h2.167v-2.417H9.53v2.417ZM16.03.906h-2.166v2.417h2.166V.906ZM9.53 5.74V3.323H7.364V5.74H9.53Zm-6.5 2.416V5.74h2.167V3.323H3.03V.906H.864V20.24H3.03v-9.667h2.167V8.156H3.03Zm8.667 2.417h2.167V8.156h-2.167v2.417ZM7.364 8.156v2.417H9.53V8.156H7.364ZM5.197 5.74v2.416h2.167V5.74H5.197ZM9.53 8.156h2.167V5.74H9.53v2.416Zm2.167-4.833V5.74h2.167V3.323h-2.167Z" />
  </svg>
);

export default FlagIcon;
