import { styled } from "@mui/material";
import Button from "../../../components/Buttons/Button";
import {
  selectErrorMessage,
  selectShowError,
  setShowError,
} from "../../../store/fit/error";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCompanyNameFromUrl } from "../../../utils/urls";
import { clientNamesToIgnoreMessages, defaultErrorMessages } from "./errors";

const Card = styled("div")`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 120;
  padding: 10px;
  top: 0;
  background-color: black;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  justify-content: center;
  // margin-top: 40px;
  gap: 40px;
`;

const StyledText = styled("h2")`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  text-align: center;
  margin-top: 40px;
`;

const IconWrapper = styled("div")`
  max-width: 150px;
  margin: 0 auto;
`;

const ErrMsg = styled("p")`
  margin-top: 20px;
  color: white;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 80px;
  background-color: black;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const ErrorScreen = () => {
  const errorMessage = useSelector(selectErrorMessage);
  const showError = useSelector(selectShowError);
  const dispatch = useDispatch();
  const clientName = getCompanyNameFromUrl();

  const reloadHandler = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (clientNamesToIgnoreMessages.includes(clientName)) dispatch(setShowError(false));
  }, [showError]);

  const shownErrorMessage = defaultErrorMessages.includes(errorMessage)
    ? errorMessage
    : "Whoops! Our tech is taking a brief snack break. 🍔 Refresh in a moment, and we'll be right back at your service! 🚀";

  if (showError && errorMessage)
    return (
      <Card>
        <Wrapper>
          <IconWrapper></IconWrapper>
          <StyledText>{"An error has occurred :("}</StyledText>
          <ErrMsg>{shownErrorMessage}</ErrMsg>
          <ButtonWrapper>
            {/* <StyledButton
              size="large"
              onClick={() => {
                dispatch(setShowError(false));
              }}
            >
              CLOSE
            </StyledButton> */}
            <StyledButton onClick={reloadHandler}>REFRESH</StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </Card>
    );

  return null;
};

export default ErrorScreen;
