import styled from "styled-components";
import EmailForm from "./EmailForm";
import FirstForm from "./FirstForm";
import GenderForm from "./GenderForm";
import SecondForm from "./SecondForm";
import ThirdForm from "./ThirdForm";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCard = ({ quizProgress, setQuizProgress }) => {
  return (
    <Wrapper>
      {quizProgress === 1 && (
        <EmailForm
          setQuizProgress={setQuizProgress}
          quizProgress={quizProgress}
        />
      )}
      {quizProgress === 2 && (
        <FirstForm
          quizProgress={quizProgress}
          setQuizProgress={setQuizProgress}
        />
      )}
      {quizProgress === 3 && (
        <SecondForm
          setQuizProgress={setQuizProgress}
          quizProgress={quizProgress}
        />
      )}
      {quizProgress === 4 && (
        <GenderForm
          setQuizProgress={setQuizProgress}
          quizProgress={quizProgress}
        />
      )}
      {quizProgress === 5 && <ThirdForm />}
    </Wrapper>
  );
};

export default FormCard;
