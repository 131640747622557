import styled from "styled-components";
import { getCompanyNameFromUrl } from "../../../utils/urls";
import StatusText from "../StatusText";
import useWindowWidth from "../../../hooks/useWindowWidth";
import RepCount from "../RepCount";
import Trainer from "../Trainer";
import { CONFIG } from "../../../config";
import ReactionClubWorkoutUI from "../../../constants/clientsStyles/ReactionClubWorkoutUI";
import StaticExerciseChart from "../StaticExerciseChart";
import { useContext, useEffect, useRef } from "react";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

const Wrapper = styled.div`
  display: flex;
  height: -webkit-fill-available;
  width: 100%;
  ${({ $screenWidth }) => $screenWidth < CONFIG.DesktopTrigger && `width: 0;`}
`;

const clientUIMap = {
  "reaction-club": <ReactionClubWorkoutUI />,
};

const WorkoutUI = () => {
  const clientName = getCompanyNameFromUrl();
  const {
    beginWorkout,
    componentData,
    showMobileComponent,
    setShowMobileComponent,
    startTimer,
    setShowGo,
    setShowGoOnStart,
    showGoOnStart,
    challengeMode,
  } = useContext(WorkoutContext);

  const screenWidth = useWindowWidth();
  const workoutUIRef = useRef(null);
  const timerTimeout = useRef(null);

  useEffect(() => {
    if (showGoOnStart) {
      setShowGo(true);
      setShowGoOnStart(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const handleClick = (e) => {
    e.stopPropagation();
    setShowMobileComponent((current) => !current);
  };

  useEffect(() => {
    if (showMobileComponent && startTimer) {
      clearTimeout(timerTimeout.current);
      timerTimeout.current = setTimeout(() => {
        setShowMobileComponent(false);
      }, 5000);
    } else {
      clearTimeout(timerTimeout.current);
    }

    return () => {
      clearTimeout(timerTimeout.current);
    };
  }, [showMobileComponent, startTimer]);

  useEffect(() => {
    setShowMobileComponent(true);
  }, [componentData]);

  if (clientName in clientUIMap) return clientUIMap[clientName];

  return (
    <Wrapper $screenWidth={screenWidth} ref={workoutUIRef} onClick={handleClick}>
      {(componentData?.data === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise ||
        componentData?.data ===
          CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise) &&
        beginWorkout &&
        screenWidth < CONFIG.DesktopTrigger && <StaticExerciseChart />}

      <DesktopMenu />
      <MobileMenu />
      <Trainer />

      {screenWidth > CONFIG.MobileBreakpoint && <RepCount />}
      {!challengeMode && <StatusText />}
    </Wrapper>
  );
};
export default WorkoutUI;
