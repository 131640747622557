import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectWorkoutSummary,
  selectWorkoutSummaryLoading,
} from "../../../../store/fit/summary";

import { useEffect } from "react";
import ChallengeResultsData from "./ChallengeResultsData";
import { getCompanyNameFromUrl } from "../../../../utils/urls";
import { useParams, useSearchParams } from "react-router-dom";
import { getChallengeSummaryAsync } from "../../../../store/fit/challenge";

const ChallengeResultScreen = () => {
  const companyName = getCompanyNameFromUrl();
  const [searchParams] = useSearchParams();
  const challengeId = searchParams.get("challenge");
  const { sessionId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!sessionId || !companyName || !challengeId) return;
    dispatch(getChallengeSummaryAsync(sessionId, companyName, challengeId));
  }, [challengeId, sessionId, companyName]);
  return (
    <Wrapper>
      <ChallengeResultsData />
    </Wrapper>
  );
};

export default ChallengeResultScreen;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 128px;
`;
