import { TextField, styled as muiStyled, Alert } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import React, { useState } from "react";
import styled from "styled-components";
import StarsRatings from "../../../../components/StarsRatings";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFeedbackStatus,
  selectSendFeedbackLoading,
  sendFeedbackAsync,
} from "../../../../store/fit/summary";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin-bottom: 24px;
  background: #171d29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 24px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 100%;
  margin-bottom: 24px;
  width: fit-content;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
`;

const StyledButton = muiStyled(LoadingButton)`
    margin-top: 16px;
    margin-left: auto;
`;

const StyledAlert = muiStyled(Alert)`
    width: 100%;
    margin-top: 16px;
`;

const FeedbackCard = () => {
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const sendFeedbackLoading = useSelector(selectSendFeedbackLoading);
  const feedbackStatus = useSelector(selectFeedbackStatus);
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState("");

  const handleDescriptionChange = (e) => {
    setComment(e.target.value);
  };

  const submit = () => {
    dispatch(sendFeedbackAsync({ rating, comment, sessionId }));
  };

  return (
    <Wrapper>
      <Title>Leave feedback</Title>
      <CardWrapper>
        <StarsRatings $mb={"16px"} value={rating} setValue={setRating} />
        <TextField
          value={comment}
          autoFocus
          margin="dense"
          id="name"
          label="Comment"
          fullWidth
          variant="outlined"
          multiline={true}
          minRows={2}
          onChange={handleDescriptionChange}
        />
        {feedbackStatus === "success" ? (
          <StyledAlert variant="outlined" severity="success">
            Thanks for feedback!
          </StyledAlert>
        ) : (
          <StyledButton
            variant="outlined"
            loading={sendFeedbackLoading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            onClick={submit}
            color={feedbackStatus || "primary"}
          >
            {feedbackStatus === "error" ? "Please try again" : "Submit"}
          </StyledButton>
        )}
      </CardWrapper>
    </Wrapper>
  );
};

export default FeedbackCard;
