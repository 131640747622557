import { css } from "styled-components";

const carouselStyles = css`
  .carousel {
    height: fit-content;
    width: 100%;
  }

  /* max-width constrains the width of our carousel to 550, but shrinks on small devices */
  .carousel__container {
    max-width: 550px;
    margin: auto;
  }

  /* gives us the illusion of a "centered" slide */
  .carousel__slider {
    padding-left: 25%;
    padding-right: 25%;
    padding-top: 24px;
    padding-bottom: 24px;
    max-height: 350px;

    > * {
      max-height: 350px;
    }
  }

  /* gives us the illusion of spaces between the slides */
  .carousel__inner-slide {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    left: 10px;
  }

  .carousel__slide {
    max-height: 350px;

    > * {
      max-height: 350px;
    }
  }

  .carousel__slide--visible {
    * .tips-video-card {
      transform: scale(1.15);
      transition: all 0.3s;
    }
  }

  .carousel__slide--hidden {
    * .tips-video-card {
      transform: scale(1);
      transition: all 0.3s;
    }
  }
`;

export default carouselStyles;
