import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import "pure-react-carousel/dist/react-carousel.es.css";

import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { CONFIG } from "./config";
import ReactGA from "react-ga4";

// Get the clientId from the URL path
const pathSegments = window.location.pathname.split("/");
const sessionId = pathSegments[pathSegments.length - 1]; // Assuming clientId is the first-to-last segment

const TRACKING_ID = "G-QKZ2SXYPNZ";

if (sessionId !== CONFIG.TESTING_CLIENT_ID) {
  ReactGA.initialize(TRACKING_ID);
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
