import React from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { getIconByName } from "../../../../../utils/getIconByName";
import { makeStyles } from "@material-ui/core/styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  width: 50px;
`;

const InnerCircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  circle: {
    strokeLinecap: "round",
  },
}));

const CompletedCircle = ({
  iconName,
  iconWeight,
  iconHeight,
  percentage = 0,
}) => {
  const Icon = getIconByName(iconName);

  const StyledIcon = styled(Icon)`
    width: ${iconWeight};
    height: ${iconHeight};
    /* fill: #08173e; */
    fill-opacity: 0.4;
    fill: #fff;
  `;

  const classes = useStyles();

  return (
    <Wrapper>
      <CircularProgressContainer>
        <Circle>
          <CircularProgress
            color="inherit"
            style={{
              position: "absolute",
              top: "2px",
              left: "2px",
              color: "white",
              zIndex: 0,
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            }}
            thickness={2}
            variant="determinate"
            value={100}
            size={45}
          />
          <CircularProgress
            color="inherit"
            style={{
              color: "#54B862",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
            thickness={6}
            variant="determinate"
            value={percentage}
            classes={{
              circle: classes.circle,
            }}
            size={50}
          />
          <InnerCircleContainer>
            <StyledIcon />
          </InnerCircleContainer>
        </Circle>
      </CircularProgressContainer>
    </Wrapper>
  );
};

export default CompletedCircle;
