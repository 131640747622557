import { createSlice } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { GENDERS } from "../../constants";

const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    gender: GENDERS.OTHER,
    cookieID: undefined,
    email: "",
    enteredName: "",
    emailUsageAgreement: false,
    height: 175,
    weight: 75,
    rarity: 0,
    bodyParts: {
      legs: false,
      chest: false,
      back: false,
      shoulders: false,
      arms: false,
      abs: false,
    },
    firstTimeUser: true,
  },
  reducers: {
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setCookieId: (state, action) => {
      state.cookieID = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setEmailUsageAgreement: (state, action) => {
      state.emailUsageAgreement = action.payload;
    },
    setEnteredName: (state, action) => {
      state.enteredName = action.payload;
    },
    setHeightStore: (state, action) => {
      state.height = action.payload;
    },
    setWeightStore: (state, action) => {
      state.weight = action.payload;
    },
    setRarity: (state, action) => {
      state.rarity = action.payload;
    },
    setBodyPart: (state, action) => {
      state.bodyParts[action.payload.key] = action.payload.value;
    },
    setFirstTimeUser: (state, action) => {
      state.firstTimeUser = action.payload;
    },
  },
});

export const {
  setGender,
  setCookieId,
  setEmail,
  setEmailUsageAgreement,
  setEnteredName,
  setHeightStore,
  setWeightStore,
  setRarity,
  setBodyPart,
  setFirstTimeUser,
} = quizSlice.actions;

export const selectGender = (state) => state.fit.quiz.gender;
export const selectCookieId = (state) => state.fit.quiz.cookieID;
export const selectEmail = (state) => state.fit.quiz.email;
export const selectEmailUsageAgreement = (state) =>
  state.fit.quiz.emailUsageAgreement;
export const selectEnteredName = (state) => state.fit.quiz.enteredName;
export const selectHeight = (state) => state.fit.quiz.height;
export const selectWeight = (state) => state.fit.quiz.weight;
export const selectRarity = (state) => state.fit.quiz.rarity;
export const selectBodyParts = (state) => state.fit.quiz.bodyParts;
export const selectFirstTimeUser = (state) => state.fit.quiz.firstTimeUser;

const quizReducer = persistReducer(
  {
    key: "userData",
    storage,
  },
  quizSlice.reducer
);

export default quizReducer;
