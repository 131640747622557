import { Collapse, styled } from "@mui/material";
import { CONFIG } from "../../../../config";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { useContext } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import PauseIcon from "../../../Icons/PauseIcon";
import NextIcon from "../../../Icons/NextIcon";
import EndIcon from "../../../Icons/EndIcon";
import ContinueIcon from "../../../Icons/ContinueIcon";
import { useNavigate, useParams } from "react-router";
import TrainerIcon from "../../../Icons/TrainerIcon";
import clients, { clientNamesToIgnoreNewUI } from "../../../../constants/clients";
import { NativeBridge } from "../../../../containers/Mirror/WorkoutScreen/MobileCommunication";
import { useSearchParams } from "react-router-dom";
import {
  disablePauseButtonExerciseType,
  disableSkipButtonExerciseType,
} from "./MenuConfig";
import { useDispatch } from "react-redux";
import { resetState } from "../../../../store/fit/workout";
import ReactGA from "react-ga4";
import { resetChallenge } from "../../../../store/fit/challenge";

const Wrapper = styled("div")`
  display: flex;
  position: absolute;
  align-items: center;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 50px;
  z-index: 10;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 58px;
  height: 250px;
  margin: 10px, 0, 5px, 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #690808;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
  align-items: center;
  justify-content: space-evenly;
`;

const IconWrapper = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const nativeBridge = new NativeBridge();

const MobileMenu = () => {
  const {
    showMobileComponent,
    paused,
    setPaused,
    nextStageWorkout,
    setShowTrainer,
    componentData,
    challengeMode,
  } = useContext(WorkoutContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const suppressFinish = searchParams.get("suppressFinish");
  const challengeId = searchParams.get("challenge");

  const screenWidth = useWindowWidth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appType, clientId, sessionId } = useParams();
  const { disableSkipButtonClient, disablePauseButtonClient, removeSkipButtonClient } =
    clients(clientId);

  const disablePauseButton =
    disablePauseButtonClient === true ||
    disablePauseButtonExerciseType.includes(componentData?.type) ||
    challengeMode;

  const disableSkipButton =
    disableSkipButtonClient === true ||
    disableSkipButtonExerciseType.includes(componentData?.type) ||
    challengeMode;

  ///// ending session
  const endWorkout = () => {
    ReactGA.event({
      category: "workout_end",
      action: "premature_workout_end",
      label: clientId, // optional
    });

    if (suppressFinish === "true") {
      nativeBridge.closeRequested();
    } else {
      dispatch(resetState());
      dispatch(resetChallenge());
      endSessionInParent();
      navigate(
        `/${appType}/${clientId}/summary/${sessionId}${
          challengeMode ? `?challenge=${challengeId}` : ""
        }`
      );
    }
  };

  /// message to iframe parent
  const endSessionInParent = () => {
    window?.parent?.postMessage("sessionEnd", "*");
  };

  const trainerButtonClickHandler = () => {
    setShowTrainer((current) => !current);
  };

  if (clientNamesToIgnoreNewUI.includes(clientId)) return null;

  if (screenWidth < CONFIG.MobileBreakpoint)
    return (
      <Wrapper>
        <Collapse
          in={showMobileComponent || paused}
          orientation="horizontal"
          collapsedSize={10}
        >
          <Container>
            <IconWrapper onClick={trainerButtonClickHandler}>
              <TrainerIcon />
            </IconWrapper>
            {!disablePauseButton ? (
              paused ? (
                <IconWrapper
                  onClick={() => {
                    setPaused(false);
                  }}
                >
                  <ContinueIcon />
                </IconWrapper>
              ) : (
                <IconWrapper
                  onClick={() => {
                    setPaused(true);
                  }}
                >
                  <PauseIcon />
                </IconWrapper>
              )
            ) : (
              <IconWrapper>
                <PauseIcon fill={"#333333"} />
              </IconWrapper>
            )}
            {!removeSkipButtonClient &&
              (!disableSkipButton ? (
                <IconWrapper onClick={nextStageWorkout} data-testid={"skipButton"}>
                  <NextIcon />
                </IconWrapper>
              ) : (
                <IconWrapper>
                  <NextIcon fill={"#333333"} />
                </IconWrapper>
              ))}

            <IconWrapper onClick={endWorkout} data-testid={"endWorkoutButton"}>
              <EndIcon />
            </IconWrapper>
          </Container>
        </Collapse>
      </Wrapper>
    );
  return null;
};
export default MobileMenu;
