import styled, { css } from "styled-components";
import { useContext, useState, useRef, useEffect } from "react";
import { CONFIG } from "../../../config";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { getStatusText } from "../../../helpers/displayHelpers";
import { useTheme } from "@mui/material";
import { getCompanyNameFromUrl } from "../../../utils/urls";
import { useSelector } from "react-redux";
import { selectCurrentEventIndex } from "../../../store/fit/workout";
import { REDUCED_JOINT_SET_MODE } from "../Canvas/canvasConfig";

//////After first five seconds start checking if person fits in camera
let firstFiveSecondsPassed = false;

const StatusText = () => {
  const {
    setNewMessage,
    initiation,
    componentData,
    newMessage,
    poseLandmarks,
    setFeedbackArray,
    message,
    setMessage,
    tutorial,
    currentExerciseApiName,
  } = useContext(WorkoutContext);

  const reducedJointsFitInScreen =
    componentData?.validation === CONFIG.WORKOUTFLOW_VALIDATION_TYPES.notFullyVisible;
  const reducedJointSetMode =
    REDUCED_JOINT_SET_MODE.includes(componentData?.type) || reducedJointsFitInScreen;

  const fiveSecondsTimeout = useRef(null);

  const companyName = getCompanyNameFromUrl();

  const [color, setColor] = useState();

  /// if there is new message from back, show it
  useEffect(() => {
    if (!newMessage) return;
    if (newMessage.status === "negative" && newMessage.message) {
      setFeedbackArray((current) => [...current, newMessage.message]);
    }
    if (message) return;
    if (!message) {
      setMessage(newMessage.message);
      setColor(resolveColor(newMessage.status));
      clearFeedback();
    }
  }, [newMessage]);

  const clearFeedback = () => {
    setTimeout(() => {
      setMessage(null);
      setNewMessage(null);
    }, CONFIG.feedbackTimer * 1000);
  };

  useEffect(() => {
    firstFiveSecondsPassed = false;
    fiveSecondsTimeout.current = setTimeout(() => {
      firstFiveSecondsPassed = true;
    }, 5000);
    return () => {
      clearTimeout(fiveSecondsTimeout.current);
    };
  }, [componentData]);

  ////// Checking for bad positioning- if person does not fit inside camera view, shows message

  const statusText = firstFiveSecondsPassed
    ? getStatusText(poseLandmarks, initiation, reducedJointSetMode)
    : null;

  useEffect(() => {
    if (!statusText) return;

    if (newMessage === null) {
      setNewMessage({
        message: statusText,
        status: "neutral",
        id: reducedJointSetMode
          ? "reducedJointSetModeInstructions"
          : "visibilityInstructions",
      });
    }
  }, [statusText]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const resolveColor = (status) => {
    switch (status) {
      case "neutral":
        return main;
      case "positive":
        return CONFIG.colors.feedBackColorPositive;
      case "negative":
        return CONFIG.colors.feedBackColorNegative;
      default:
        return main;
    }
  };

  // const color = resolveColor(statusType);
  if (componentData === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.tutorial)
    return (
      <Wrapper $tutorial={tutorial}>
        <Typography $color={CONFIG.colors.feedBackColorNeutral}>
          {companyName === "fitq"
            ? CONFIG.FITQ_TUTORIAL_MESSAGE_MAP[currentExerciseApiName]
            : CONFIG.TUTORIAL_MESSAGE}
        </Typography>
      </Wrapper>
    );

  return newMessage ? (
    <Wrapper $color={color}>
      {message && <Typography $color={color}>{message}</Typography>}
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  position: absolute;
  top: 60%;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 55px;
  right: 0;
  z-index: 6;

  @media (max-width: 600px) {
    top: 70%;
    border-radius: 25px;
    background-color: rgba(131, 131, 131, 0.7);
  }

  @media (min-width: 601px) {
    top: 70%;
    left: 8px;
    max-width: calc(100% - 200px);
  }

  @media (min-width: 900px) {
    top: 70%;
    left: 0px;
    max-width: calc(100% - 200px);
  }

  @media (min-width: 1350px) {
    top: 20px;
    max-width: 100%;
  }

  ${({ $tutorial }) =>
    $tutorial &&
    ` @media (max-width: 600px) {
      top: 75%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media (min-width: 601px) {
    left: 50%;  
    transform: translate(-50%, 0);

  }

  @media (min-width: 900px) {
    left: 50%;
    transform: translate(-50%, 0);
  }
 `}
`;

const Typography = styled.p`
  ${({ $color }) => css`
    background-color: black;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    color: ${$color};
    border: 1px solid ${$color};
    border-radius: 25px;
    padding: 8px 25px;

    @media (min-width: 800px) {
      font-size: 32px;
    }

    @media (min-width: 1350px) {
      font-size: 44px;
    }
  `}
`;

export default StatusText;
