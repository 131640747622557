import { useState, useEffect } from "react";

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState("");
  useEffect(() => {
    const mediaQueryList = window.matchMedia("(orientation: landscape)");

    const handleOrientationChange = (mediaQueryList) => {
      if (mediaQueryList.matches) {
        setOrientation("landscape");
      }
      if (!mediaQueryList.matches) {
        setOrientation("portrait");
      }
    };
    handleOrientationChange(mediaQueryList);

    mediaQueryList.addEventListener("change", handleOrientationChange);
  }, []);

  return orientation;
};

export default useScreenOrientation;
