export const GENDERS = {
  MAN: 1,
  WOMAN: 2,
  OTHER: 3,
};

export const GENDER_OPTIONS = {
  [GENDERS.MAN]: "Man",
  [GENDERS.WOMAN]: "Woman",
  [GENDERS.OTHER]: "Other",
};

export const DEFAULT_REP_SOUND =
  "https://fittyai-utils.s3.amazonaws.com/audio-files/singleClap.mp3";

export const DEFAULT_EXERCISE_ID = "3lGu5VbwfbRTysFxrdT2";

export const DEFAULT_VIDEO =
  "https://firebasestorage.googleapis.com/v0/b/fitty-1.appspot.com/o/flamelink%2Fmedia%2Ffitty_jumping_jack.webm?alt=media";

export const DEFAULT_AI_AUDIO =
  "https://38en04sov6.execute-api.us-west-2.amazonaws.com/textvoicetest";
