const CameraIcon = (props) => {
  return (
    <svg
      width={11}
      height={7}
      viewBox="0 0 11 7"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.4C0 1.0287 0.144866 0.672601 0.402728 0.41005C0.660591 0.1475 1.01033 9.69585e-09 1.375 9.69585e-09H6.53125C6.86475 -3.99336e-05 7.1869 0.123335 7.4377 0.347149C7.68851 0.570964 7.85087 0.879961 7.89456 1.2166L10.0327 0.2492C10.1373 0.20172 10.252 0.181625 10.3662 0.19074C10.4804 0.199855 10.5906 0.237892 10.6867 0.301394C10.7828 0.364895 10.8618 0.451847 10.9166 0.554347C10.9713 0.656846 11 0.771642 11 0.8883V6.1117C10.9999 6.22827 10.9712 6.34296 10.9165 6.44538C10.8619 6.54779 10.7829 6.63469 10.6869 6.69817C10.5909 6.76165 10.4808 6.79972 10.3667 6.80891C10.2526 6.8181 10.138 6.79813 10.0334 6.7508L7.89456 5.7834C7.85087 6.12004 7.68851 6.42904 7.4377 6.65285C7.1869 6.87667 6.86475 7.00004 6.53125 7H1.375C1.01033 7 0.660591 6.8525 0.402728 6.58995C0.144866 6.3274 0 5.9713 0 5.6V1.4Z"
      />
    </svg>
  );
};

export default CameraIcon;
