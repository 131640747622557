import React, { useEffect, useRef, useState } from "react";

import cx from "classnames";
import styled from "styled-components";

import Skeleton from "../Skeleton";
import fadeIn from "../../common/animations/fadeIn";

const ERROR_URL = "";
const ERROR_URL_LIGHT = "";

const ImageLoader = ({
  src,
  onError,
  fallbackTimer = 20000,
  fallbackSrcs,
  fallbackSvgr,
  theme = "dark",
  className,
  style,
  showLoader = true,
  alt,
  onClick,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);
  const loadedRef = useRef(loaded);
  loadedRef.current = loaded;
  const [finalSrc, setFinalSrc] = useState(src);
  const [fallbackIndex, setFallbackIndex] = useState(0);
  const [shouldRenderFallbackSVGR, setShouldRenderFallbackSVGR] =
    useState(false);
  const errorUrl = theme === "dark" ? ERROR_URL : ERROR_URL_LIGHT;

  // image onLoad handler to update state to loaded
  const onLoad = () => {
    setLoaded(true);
  };

  const handleError = () => {
    if (fallbackSvgr) {
      setShouldRenderFallbackSVGR(true);
      setLoaded(true);
    } else {
      //fallbackSrcs can be both string or array of strings.
      if (!fallbackSrcs || typeof fallbackSrcs === "string") {
        setFinalSrc(fallbackSrcs || errorUrl);
      } else if (typeof fallbackSrcs === "object" && fallbackSrcs?.length) {
        // sometimes there are couple fallbacks passed, but some of them are undefined.
        // we will filter out undefined ones and only deal with values that exist.
        const filteredFallbackSrcs = fallbackSrcs?.filter(Boolean);

        // no more defined fallbacks, use default error image.
        if (!filteredFallbackSrcs[fallbackIndex]) {
          setFinalSrc(errorUrl);
          onError && onError();
        } else {
          setFinalSrc(filteredFallbackSrcs[fallbackIndex]);
          setFallbackIndex(fallbackIndex + 1);
        }
      }
    }
  };

  useEffect(() => {
    const fallbackTimerInterval = setTimeout(() => {
      if (!loadedRef.current) {
        handleError();
      }
    }, fallbackTimer);

    return () => {
      clearTimeout(fallbackTimerInterval);
    };
  }, [finalSrc]);

  useEffect(() => {
    setLoaded(false);
  }, [src]);

  return (
    <React.Fragment>
      {!loaded && showLoader && (
        <ImageLoaderPlaceholder theme={theme} style={style || {}} />
      )}
      {shouldRenderFallbackSVGR ? (
        fallbackSvgr
      ) : (
        <ImageLoaderStyle
          style={style || {}}
          src={finalSrc}
          onClick={onClick}
          className={cx({
            [`${className}`]: className,
            "img-loaded": loaded,
            "img-loading": !loaded,
          })}
          onLoad={onLoad}
          onError={handleError}
          alt={alt || "image"}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export const PlaceholderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageLoaderStyle = styled.img`
  &.img-loading {
    display: none;
  }

  &.img-loaded {
    position: relative;
    opacity: 0;
    animation: ${fadeIn} cubic-bezier(0.23, 1, 0.32, 1) 1;
    animation-delay: 0.1s;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
  }
`;

export const ImageLoaderPlaceholder = ({ style, theme }) => {
  return (
    <PlaceholderStyled style={style}>
      <Skeleton width="100%" height="100%" theme={theme} />
    </PlaceholderStyled>
  );
};

export default ImageLoader;
