import { isStaging } from "./env";

export const drawLandmarksFeature = true;
export const forceShowCanvasFeature = false;
export const forceShowVideoFeature = false;
export const showCanvasProdFeature = true;
export const printFPSFeature = false;
// export const printFPSFeature = true;
export const printAverageFPSFeature = false;
export const displayFpsFeature = isStaging;
// export const displayFpsFeature = true;
export const alwaysInitiatedFeature = false;
export const displayCanvasFeature = true;
export const disableCameraCheck = false;
export const chatGptFeedback = false;
export const visualInitiationFeature = true;
export const handsUpInitiationFeature = false;
export const fittyWorkoutTutorialFeature = true;
