import React, { useEffect } from "react";
import styled from "styled-components";
import WebsocketContextComponent from "../WorkoutScreen/WebsocketContext/WebsocketContextComponent";
import FitCamera from "./FitCamera";
import WorkoutContextComponent from "../WorkoutScreen/WorkoutContext/WorkoutContextComponent";

const FIttyCameraScreen = () => {
  return (
    <WorkoutContextComponent>
      <WebsocketContextComponent>
        <FitCamera />
      </WebsocketContextComponent>
    </WorkoutContextComponent>
  );
};

export default FIttyCameraScreen;
