import RegistrationName from "./RegistrationName";
import { useState } from "react";
import RegistrationEmail from "./RegistrationEmail";
import { devices } from "../../../config";
import Button from "../../../components/Buttons/Button";
import { ChevronLeft } from "@mui/icons-material";
import ContentWrapper from "../../../components/ContentWrapper";
import { styled } from "@mui/material";

const Modal = styled("div")`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  left: 0;
  top: 0;
  z-index: 20;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 24px auto;
  position: relative;
  width: 100%;
  max-width: 1000px;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px;
  padding-right: 8px;
`;

const FormWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled("h1")`
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  color: #ffffff;
  padding: 0 24px 0 24px;
  margin-bottom: 40px;
  @media ${devices.mobileL} {
    font-size: 36px;
    line-height: 55px;
  }

  @media ${devices.tablet} {
    font-size: 36px;
    line-height: 65px;
  }
  @media ${devices.tabletL} {
    font-size: 40px;
    line-height: 65px;
  }

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CompetitionRegistration = ({ startWorkout, setCompetitionSignUp }) => {
  const [emailWindow, setEmailWindow] = useState(false);

  const backHandler = () => {
    if (!emailWindow) {
      setCompetitionSignUp(false);
    } else setEmailWindow(false);
  };

  return (
    <Modal>
      <ContentWrapper>
        <Wrapper>
          <ButtonWrapper>
            <Button onClick={backHandler}>
              <ChevronLeft />
            </Button>
          </ButtonWrapper>
          <FormWrapper>
            <StyledText>{"BEFORE YOU START:"}</StyledText>
            {emailWindow ? (
              <RegistrationEmail startWorkout={startWorkout} />
            ) : (
              <RegistrationName setEmailWindow={setEmailWindow} />
            )}
          </FormWrapper>
        </Wrapper>
      </ContentWrapper>
    </Modal>
  );
};

export default CompetitionRegistration;
