import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import WebsocketContext from ".";
import { useRef, useEffect, useContext } from "react";
import WorkoutContext from "../WorkoutContext";
import { useParams } from "react-router-dom";
import { CONFIG } from "../../../../config";
import { createPayload } from "../../../../helpers/createPayload";
import {
  getJWTAsync,
  selectToken,
  setKcalBurned,
  setToken,
  setWorkoutScore,
} from "../../../../store/fit/workout";
import { useState } from "react";
import { getCompanyNameFromUrl } from "../../../../utils/urls";
import clients from "../../../../constants/clients";
import useAuth from "../../../../hooks/useAuth";
import {
  selectCookieId,
  selectEmail,
  selectEnteredName,
  setCookieId,
} from "../../../../store/fit/quiz";
import { Guid } from "js-guid";
import {
  selectShowError,
  setErrorMessage,
  setShowError,
} from "../../../../store/fit/error";
import { ERROR_MESSAGES } from "../../ErrorScreen/errors";
import {
  MessageTypesAllowedWithoutConnection,
  connectionTimeoutTimeMS,
} from "./WebsocketContextConfig";
import { getUserAsync, selectAccountUser } from "../../../../store/fit/account";

let reconnectAttempts = 0;
let connectionFailed = false;
let connectionErrorShown = false;
let unauthorized = false;
let reconnecting = false;

let messageTimeout;
let connectionCheckTimer;
let reconnectInterval;

const WebsocketContextComponent = ({ children }) => {
  const { sessionId, workoutId } = useParams();
  const jwt = new URLSearchParams(window.location.search).get("JWT");
  const storedJwt = localStorage.getItem("jwt");

  const [sentEventsInSecond, setSentEventsInSecond] = useState(0);
  const { name, username } = useAuth();

  const clientName = getCompanyNameFromUrl();

  const { requireAuth } = clients(clientName);
  const cookieId = useSelector(selectCookieId);
  const email = useSelector(selectEmail);
  const enteredName = useSelector(selectEnteredName);

  const {
    setConnected,
    connected,
    setRepCount,
    setNewScore,
    setNewScoreTimestamp,
    newMessage,
    setNewMessage,
    startSessionParams,
    endSessionParams,
    checkConnectionParams,
    currentExerciseApiName,
    setPing,
    timeIsUp,
    ping,
  } = useContext(WorkoutContext);

  const dispatch = useDispatch();

  const userToken = useSelector(selectToken);
  const user = useSelector(selectAccountUser);
  const [contextType, setContextType] = useState();
  const showError = useSelector(selectShowError);

  const ws = useRef();

  ////// calcucalting ping
  const [sentTimestamp, setSentTimestamp] = useState();
  const [receivedTimestamp, setReceivedTimestamp] = useState();
  const [unrespondedChecks, setUnrespondedChecks] = useState(0);

  useEffect(() => {
    loadJWT();
    if (!cookieId) dispatch(setCookieId(Guid.newGuid().StringGuid));
  }, []);

  useEffect(() => {
    if (!userToken) return;

    if (!user) dispatch(getUserAsync({ token: userToken }));
  }, [userToken]);
  /// checks if token from url exists, if not, gets jwt from backend

  const loadJWT = () => {
    if (jwt) dispatch(setToken(jwt));
    else if (storedJwt) dispatch(setToken(storedJwt));
    else dispatch(getJWTAsync(clientName, enteredName, email));
  };

  /////////////////When user token exists, starts websocket connection
  useEffect(() => {
    if (!userToken) return;

    ws.current = new WebSocket(CONFIG.SOCKET_URL);
    //// on socket open, sends session start message

    ws.current.onopen = () => {
      startSession();
    };

    ws.current.onclose = () => {
      console.log("Session End");
      setConnected(false);
    };

    ws.current.onmessage = (data) => onMessage(JSON.parse(data.data));

    ws.current.onerror = (error) => {
      // Capture the error as an exception in Sentry, including the original error message
      const sentryError = new Error(
        `WebSocket server connection error: ${error.message || "Unknown error"}`
      );
      Sentry.captureException(sentryError, {
        extra: { websocket_error_details: error }, // Attach the error details directly during capture
      });

      console.error("WebSocket error:", error.message || "Unknown error");

      // Optionally show an error to the user
      if (!showError && !connectionErrorShown) {
        connectionErrorShown = true;
        dispatch(setShowError(true));
        dispatch(setErrorMessage(ERROR_MESSAGES.websockets?.connection_error));
      }
    };

    return () => {
      // Only attempt to close the WebSocket if it's connected
      if (connected) {
        ws.current.close();
      }
      endSession();
    };
  }, [userToken]);

  useEffect(() => {
    if (ping >= 1000) {
      console.log(`High ping detected: ${ping}ms`);
    }
  }, [ping]);

  ///////////////////////////////////
  //////////////////RECONNECTION
  //////////////////////////////////

  const reconnectWebSocket = () => {
    if (!reconnecting) return;
    reconnectAttempts++;

    if (reconnectAttempts >= CONFIG.MAX_RECONNECT_ATTEMPTS) {
      Sentry.captureMessage("Too many attempts to reconnect detected");
      connectionFailed = true;
      clearInterval(reconnectInterval);

      if (!showError && !connectionErrorShown) {
        connectionErrorShown = true;
        dispatch(setShowError(true));
        dispatch(setErrorMessage(ERROR_MESSAGES.websockets.too_many_reconnect_attempts));
      }
    }

    if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
      console.log("Closing existing WebSocket connection...");
      ws.current.close();
    }

    // Reinitialize WebSocket connection
    ws.current = new WebSocket(CONFIG.SOCKET_URL);

    ws.current.onopen = () => {
      reconnecting = false;
      setUnrespondedChecks(0);
      Sentry.captureMessage("Reconnect successful");
      console.log("Reconnect successful");
      clearInterval(reconnectInterval);
      startSession();
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
      setConnected(false);
    };

    ws.current.onmessage = (data) => onMessage(JSON.parse(data.data));
    ws.current.onerror = (error) => {
      Sentry.setExtra("websocket_error", error.message || "Unknown error");
      Sentry.captureException(error);

      console.error("WebSocket error:", error);

      if (!showError && !connectionErrorShown) {
        connectionErrorShown = true;
        dispatch(setShowError(true));
        dispatch(setErrorMessage(ERROR_MESSAGES.websockets.connection_error));
      }
    };
  };

  const attemptReconnect = () => {
    if (connectionFailed || unauthorized || !reconnecting) return;
    reconnectInterval = setInterval(() => {
      reconnectWebSocket();
    }, 5000);
  };

  useEffect(() => {
    if (unrespondedChecks >= 5) {
      reconnecting = true;
      attemptReconnect();
    }
  }, [unrespondedChecks]);

  useEffect(() => {
    if (!connected) {
      clearInterval(connectionCheckTimer);
      return;
    }

    connectionCheckTimer = setInterval(() => {
      checkConnection();
    }, 10000);

    return () => {
      clearTimeout(messageTimeout);
      clearInterval(connectionCheckTimer);
    };
  }, [connected]);

  ///////////////////////////////////////////////////////
  /////// MESSAGES TO BACK-END SERVER
  //////////////////////////////////////////////////////

  const WSsendMessage = (parameters, messageType) => {
    try {
      let params = { ...parameters };

      if (username && requireAuth) {
        params = {
          ...params,
          username: username,
          name: name,
        };
      }

      if (connected || MessageTypesAllowedWithoutConnection.includes(messageType)) {
        const payload = createPayload(params, sessionId);
        const parsed = JSON.parse(payload);
        if (parsed?.event_type === "connection_check")
          setSentTimestamp(parsed?.timestamp);
        ws.current.send(payload);
      }
    } catch (error) {
      console.error(error);

      Sentry.setExtra("send_message_error", error.message || "Unknown error");
      Sentry.captureException(error);
    }
  };

  const checkConnection = () => {
    const parameters = {
      ...checkConnectionParams,
      event_type: CONFIG.EVENT_TYPES.check_connection,
    };

    WSsendMessage(parameters, CONFIG.EVENT_TYPES.check_connection);
  };

  const resetMessageTimeout = () => {
    setUnrespondedChecks(0);
    clearTimeout(messageTimeout);
    messageTimeout = setTimeout(() => {
      // Handle timeout, such as attempting to reconnect or alerting the user
      console.log("Unresponded connection check detected");
      setUnrespondedChecks((count) => {
        return count + 1;
      });
    }, 15000);
  };

  const startSession = () => {
    ///////////////////// Receive via props
    const parameters = {
      ...startSessionParams,
      cookie_id: cookieId,
      event_type: CONFIG.EVENT_TYPES.session_start,
    };
    parameters.workout_id = workoutId;
    if (userToken) parameters.jwt = userToken;
    else parameters.jwt = "tempjwt";

    WSsendMessage(parameters, CONFIG.EVENT_TYPES.session_start);
  };

  const endSession = () => {
    const parameters = {
      ...endSessionParams,
      event_type: CONFIG.EVENT_TYPES.session_end,
    };
    WSsendMessage(parameters, CONFIG.EVENT_TYPES.session_end);
    console.log("endSessionCalled");
  };

  const stateUpdate = (parameters) => {
    if (ws?.current?.readyState === WebSocket.OPEN) {
      WSsendMessage(parameters, CONFIG.EVENT_TYPES.state_update);
    }
    setSentEventsInSecond((sentEventsInSecond) => {
      return sentEventsInSecond + 1;
    });
  };

  ////////////////////////////////////////////////////
  /////////Calculating ping
  ///////////////////////////////////////////////////
  useEffect(() => {
    const currentTimestamp = new Date().getTime();
    const ping = currentTimestamp - sentTimestamp;
    setPing(ping);
  }, [receivedTimestamp]);

  ////////////////////////////////////////////////////
  /////////
  ///////////////////////////////////////////////////

  ///// setting new onMessage value
  useEffect(() => {
    if (!ws.current) return;
    ws.current.onmessage = (data) => onMessage(JSON.parse(data.data));
  }, [currentExerciseApiName]);

  const onMessage = (message) => {
    if (!message) return;

    switch (message.event_type) {
      case CONFIG.RESPONSE_TYPES.STATE_UPDATE:
        if (timeIsUp) return;
        if (!message.data.hasOwnProperty("metrics")) {
          console.error("No metrics", message);
          return;
        }
        /////Check if current event matches backend event (for late responses)
        if (
          CONFIG.CONTEXT_TYPES.MIRROR === contextType &&
          currentExerciseApiName !== message?.exercise_name
        ) {
          return;
        }

        ///Create empty objects
        let repCountObj = {};
        let newScoreObj = {};
        let kcalObj = {};
        let totalScoreObj = {};
        let feedbackObj = {};
        ///Find and set data in received message
        if (message?.data.hasOwnProperty(CONFIG.METRIC_NAMES.FEEDBACK)) {
          feedbackObj = message.data[CONFIG.METRIC_NAMES.FEEDBACK];
        }

        if (message?.data.hasOwnProperty(CONFIG.METRIC_NAMES.METRIC_NAME)) {
          repCountObj = message.data.metrics.find(
            (el) => el.name === CONFIG.METRIC_NAMES.REPS
          );
          newScoreObj = message.data.metrics.find(
            (el) => el.name === CONFIG.METRIC_NAMES.SCORE
          );
          kcalObj = message.data.metrics.find(
            (el) => el.name === CONFIG.METRIC_NAMES.KCAL
          );
          totalScoreObj = message.data.metrics.find(
            (el) => el.name === CONFIG.METRIC_NAMES.TOTAL_SCORE
          );
        }

        if (repCountObj.value === undefined) {
          console.error("No repetition count", message);
          return;
        }

        //////Reset states for upcoming back-end events

        if (!newMessage && feedbackObj?.text) {
          setNewMessage({
            message: feedbackObj?.text,
            status: feedbackObj?.status,
            id: feedbackObj?.id,
          });
        }
        /////// Set new states

        setRepCount(repCountObj?.value);
        dispatch(setWorkoutScore(totalScoreObj?.value));
        dispatch(setKcalBurned(Math.floor(kcalObj?.value)));
        setNewScore(newScoreObj?.value);
        if (newScoreObj?.value > 0) setNewScoreTimestamp(Date.now());

        break;

      case CONFIG.RESPONSE_TYPES.STATUS:
        if (!message.status) {
          console.error("No status number", message);
          return;
        }
        if (message.status === 200) {
          unauthorized = false;
          connectionErrorShown = false;
          setConnected(true);
          reconnectAttempts = 0;
          console.log("Connected");
        }
        break;

      case CONFIG.RESPONSE_TYPES.START_EXERCISE:
        break;

      case CONFIG.RESPONSE_TYPES.CONNECTION_CHECK:
        resetMessageTimeout();
        setReceivedTimestamp(message?.timestamp);
        break;

      case CONFIG.RESPONSE_TYPES.ERROR:
        const errorText = message?.data?.feedback?.text;
        Sentry.captureException(new Error(errorText));
        console.error("ERROR", message);

        if (!showError && !connectionErrorShown) {
          connectionErrorShown = true;
          dispatch(setShowError(true));
          dispatch(setErrorMessage(errorText));
        }

        if (errorText === CONFIG.BACKEND_ERROR_MESSAGES.UNAUTHORIZED) {
          unauthorized = true;
        }

        break;

      default:
        console.error("Unknown response", message);
    }
  };

  return (
    <WebsocketContext.Provider
      value={{
        stateUpdate,
        sentEventsInSecond,
        setSentEventsInSecond,
        setContextType,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketContextComponent;
