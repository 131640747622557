export const BE_AWS_URL = {
  localdev: "https://38en04sov6.execute-api.us-west-2.amazonaws.com",
  dev: "https://5mb90qr663.execute-api.us-west-2.amazonaws.com",
  staging: "https://38en04sov6.execute-api.us-west-2.amazonaws.com",
  production: "https://38en04sov6.execute-api.us-west-2.amazonaws.com",
}[process.env.REACT_APP_ENV];

export const BE_SOCKETS_URL = {
  // "ws://localhost:9191" - change development to this if running BE locally otherwise we use staging
  localdev: "wss://backenddev.fittyai.com:443",
  dev: "wss://backenddev.fittyai.com:443",
  staging: "wss://backend.fittyai.com:443",
  production: "wss://backend.fittyai.com:443",
}[process.env.REACT_APP_ENV];

export const FE_URL = {
  localdev: "http://localhost:3000",
  dev: "https://dev.fittyai.com",
  staging: "https://demost.fittyai.com",
  production: "https://demo.fittyai.com",
}[process.env.REACT_APP_ENV];
