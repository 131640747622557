import { useEffect } from "react";

import { useNavigate, useLocation } from "react-router";
import styled from "styled-components";
import FloatingLogo from "../../../components/FloatingLogo";
import { ChevronLeft } from "@mui/icons-material";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { CONFIG } from "../../../config";
import Button from "../../../components/Buttons/Button";
import AuthScreen from "../../../components/Auth";
import useAuth from "../../../hooks/useAuth";

const AuthLanding = () => {
  const breakpoint = useWindowWidth() < CONFIG.SelectionScreenBreakpoint;

  const { status } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (status === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [status, navigate, from]);

  return (
    <Wrapper>
      <Header>
        <StyledButton
          startIcon={!breakpoint && <ChevronLeft />}
          onClick={goBack}
        >
          {breakpoint ? (
            <ChevronLeft />
          ) : (
            <>
              <p>Back</p>
            </>
          )}
        </StyledButton>
      </Header>
      <AuthScreen />
      <FloatingLogo showEmail />
    </Wrapper>
  );
};

export default AuthLanding;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: -webkit-fill-available;
`;

const Header = styled("div")`
  display: flex;
  align-content: center;
  margin: 24px auto;
  position: relative;
  width: 95vw;
  max-width: 1000px;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  height: min-content;
`;
