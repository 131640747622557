import { styled, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectWorkoutsDashboard,
  selectWorkoutsDashboardLoading,
} from "../../../../store/fit/dashboard";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

const Wrapper = styled("div")`
  margin-top: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 572px;
  background: #171d29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 15px;
`;

const StyledDataGrid = styled(DataGrid)`
  width: 100%;
  backdrop-filter: blur(12px);
  border-radius: 15px;
`;

const InnerWrapper = styled("div")`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled("div")`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 40px;
  width: fit-content;
  text-align: center;
`;

let options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const WorkoutHistory = ({ historyClickHandler }) => {
  const workoutsDashboardLoading = useSelector(selectWorkoutsDashboardLoading);
  const summaries = useSelector(selectWorkoutsDashboard);
  const columns = [
    { field: "workoutName", headerName: "Workout", flex: 1, minWidth: 100 },
    { field: "date", headerName: "Date", flex: 1, minWidth: 150 },
    { field: "accuracy", headerName: "Accuracy, %", flex: 1, minWidth: 90 },
    { field: "reps", headerName: "Reps", flex: 1, minWidth: 70 },
    { field: "score", headerName: "Score", flex: 1, minWidth: 70 },
    { field: "kcal", headerName: "Kcal", flex: 1, minWidth: 70 },
  ];
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!summaries) return;
    const tempRows = [];
    summaries.map((summary) => {
      const { SkKey, WorkoutName, timestamp, meta } = summary || {};
      const {
        performanceScore,
        performanceTotalReps,
        performanceAccuracyPercentage,
        performanceBurnedKcal,
      } = meta || {};
      tempRows.push({
        workoutName: WorkoutName,
        accuracy: performanceAccuracyPercentage,
        reps: performanceTotalReps,
        score: performanceScore,
        kcal: performanceBurnedKcal,
        id: SkKey,
        date: new Date(parseInt(timestamp) * 1000).toLocaleDateString(
          "en-US",
          options
        ),
      });
    });
    setRows(tempRows);
  }, [summaries]);

  if (workoutsDashboardLoading)
    return (
      <Wrapper>
        <InnerWrapper>
          <Title>Past Workouts</Title>
          <CircularProgress />
        </InnerWrapper>
      </Wrapper>
    );

  return (
    <Wrapper>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 8,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableMultipleRowSelection={true}
        onRowClick={historyClickHandler}
      />
    </Wrapper>
  );
};

export default WorkoutHistory;
