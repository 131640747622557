import * as React from "react";

const FlameIcon = (props) => (
  <svg
    width={18}
    height={22}
    viewBox="0 0 18 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="#08173E"
    {...props}
  >
    <path d="M6.54 8.663a.227.227 0 0 0 .34.058l.013-.01c.358-.299.68-.606.982-.933C10.817 4.585 9.1.666 9.085.628a.458.458 0 0 1 .138-.544.452.452 0 0 1 .563.03c9.97 9.272 7.33 16.334 7.137 16.81-.798 2.879-3.974 4.962-7.74 5.069C9.055 22 8.96 22 8.85 22 4.402 22 .62 19.26.62 15.76v-.055c0-4.855 4.396-9.645 4.592-9.848a.402.402 0 0 1 .482-.097c.165.07.272.234.266.414-.04.95.153 1.774.579 2.475v.014h.002Z" />
  </svg>
);

export default FlameIcon;
