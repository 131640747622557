import styled from "styled-components";
import SliderComponent from "./SliderComponent";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Body = () => {
  return (
    <Wrapper>
      <SliderComponent />
    </Wrapper>
  );
};

export default Body;
