export const ERROR_MESSAGES = {
  selection: {
    noWorkouts: "No workouts exist.",
  },
  workout: {
    mediapipe_load_failed:
      "Pose estimation loading timed out. Please try reloading page.",
  },

  device: {
    samsung:
      "It appears you are currently using Samsung Internet browser, which is not currently supported. For the best experience we suggest using Google Chrome.",
  },
  websockets: {
    too_many_reconnect_attempts:
      "Whoops! Our tech is taking a brief snack break. 🍔 Refresh in a moment, and we'll be right back at your service! 🚀",
    connection_error:
      "Whoops! We're having a connection problem. Refresh in a moment, or contact support if it continues! 🚀",
  },
};

export const defaultErrorMessages = [
  "It appears you are currently using Samsung Internet browser, which is not currently supported. For the best experience we suggest using Google Chrome.",
  "Whoops! Our tech is taking a brief snack break. 🍔 Refresh in a moment, and we'll be right back at your service! 🚀",
  "Pose estimation loading timed out. Please try reloading page.",
  "No workouts exist.",
  "WebSocket server refused the connection. Unauthorized",
  "Fitty Trainer is not available right now, please contact the FittyAI support.",
  "Whoops! We're having a connection problem. Refresh in a moment, or contact support if it continues! 🚀",
];

export const clientNamesToIgnoreMessages = ["playwright"];
