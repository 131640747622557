import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "../../../../../components/Buttons/Button";
import { devices } from "../../../../../config";
import { setRarity } from "../../../../../store/fit/quiz";
import { howOftenWorkout } from "../../constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 153px;
  gap: 30px;

  @media ${devices.tablet} {
    gap: 35px;
  }

  @media ${devices.laptop} {
    gap: 50px;
  }
`;

const StyledButton = styled(Button)`
  max-width: 300px;
  width: 95vw;

  @media ${devices.tablet} {
    max-width: 350px;
  }

  @media ${devices.laptop} {
    max-width: 420px;
  }
`;

const FirstForm = ({ quizProgress, setQuizProgress }) => {
  const dispatch = useDispatch();

  const clickHandler = (howOften) => {
    dispatch(setRarity(howOften));
    setQuizProgress(() => quizProgress + 1);
  };

  return (
    <Wrapper>
      {howOftenWorkout.map(({ howOften, text }) => {
        return (
          <StyledButton
            size="large"
            key={howOften}
            onClick={() => clickHandler(howOften)}
          >
            {text}
          </StyledButton>
        );
      })}
    </Wrapper>
  );
};

export default FirstForm;
