import styled from "styled-components";

const StyledText = styled.p`
  color: red;
  font-size: 20px;
  position: absolute;
`;

const MobileRecordingIndicator = () => {
  return <StyledText>REC</StyledText>;
};

export default MobileRecordingIndicator;
