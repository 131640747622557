import { Button } from "@mui/material";
import React, { memo, useState } from "react";

import styled from "styled-components";
import { getCookie, setCookie } from "../../utils/Cookie";
import SmartLink from "../Primitives/SmartLink";

import CookieIcon from "@mui/icons-material/Cookie";
import { getCompanyNameFromUrl } from "../../utils/urls";
import { clientNamesToIgnoreCookies } from "../../constants/clients";

export default memo(CookieBanner);

function CookieBanner() {
  const clientName = getCompanyNameFromUrl();

  const [hasAgreed, setHasAgreed] = useState(() => hasAgreedToTerms());

  const handleAgreeClick = () => {
    setCookie("agreed-to-cookies", "1", 365);
    setHasAgreed(true);
  };

  if (clientNamesToIgnoreCookies.includes(clientName)) return null;

  if (hasAgreed) {
    return null;
  }

  return (
    <Wrapper>
      <InnerWrapper>
        <Flex>
          <Line>
            <Icon />
            <span>&nbsp;</span>
            <span>
              This website uses cookies for functionality, analytics and
              advertising purposes as described in our{" "}
              <PrivacySmartLink to="/privacy-policy" target="_blank">
                Privacy Policy
              </PrivacySmartLink>
              . If you agree to our use of cookies, please continue to use our
              site and click AGREE. &nbsp;
            </span>
          </Line>
          &nbsp; &nbsp; &nbsp;
          <Button variant="outlined" size="small" onClick={handleAgreeClick}>
            Agree
          </Button>
        </Flex>
      </InnerWrapper>
    </Wrapper>
  );
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PrivacySmartLink = styled(SmartLink)`
  color: #90caf9;
`;

const Wrapper = styled.div`
  position: fixed;
  padding: 16px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  z-index: 99999;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px white solid;
  border-radius: 10px;
`;

const InnerWrapper = styled.div`
  max-width: 1200px;
`;

const Line = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(CookieIcon)`
  flex-shrink: 0;
`;

function hasAgreedToTerms() {
  const cookieValue = getCookie("agreed-to-cookies");
  return cookieValue === "1";
}
