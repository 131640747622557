export const ROUTE = {
  HOME: {
    ID: "HOME",
    PATH: "/",
  },
  PRIVACY_POLICY: {
    ID: "PRIVACY_POLICY",
    PATH: "/privacy-policy",
  },
  BLOCKER: {
    ID: "BLOCKER",
    PATH: "blocker",
  },
  FIT: {
    ID: "FIT",
    PATH: "/:appType/:clientId/*",
  },
  FIT_LANDING: {
    ID: "FIT_LANDING",
    PATH: "/:appType/:clientId",
  },
  FIT_LEADERBOARD: {
    ID: "FIT_LEADERBOARD",
    PATH: "leaderboard/:workoutId",
  },
  FIT_WORKOUT: {
    TITLE: "Workout",
    ID: "FIT_WORKOUT",
    PATH: "/:workoutId/:workoutStep/:sessionId",
  },
  FIT_WORKOUT: {
    TITLE: "Workout",
    ID: "FIT_WORKOUT",
    PATH: "/:workoutId/:sessionId",
  },
  OLD_FIT_WORKOUT: {
    TITLE: "Workout",
    ID: "FIT_WORKOUT",
    PATH: "/:workoutId/:workoutStep/:sessionId",
  },
  FIT_SELECTION: {
    TITLE: "Workout Selection",
    ID: "FIT_SELECTION",
    PATH: "selection",
  },
  FIT_SUMMARY: {
    TITLE: "Workout Summary",
    ID: "FIT_SUMMARY",
    PATH: "summary/:sessionId",
  },
  FIT_SUMMARY_TIPS: {
    TITLE: "Workout Summary Tips",
    ID: "FIT_SUMMARY_TIPS",
    PATH: "summary/:sessionId/tips",
  },
  FIT_FITTY_CAMERA: {
    ID: "FIT_FITTY_CAMERA",
    PATH: "/fitty-camera/:sessionId",
  },
  FIT_FITTY_WORKOUT: {
    ID: "FIT_FITTY_WORKOUT",
    PATH: "/fitty-workout/:workoutId/:sessionId",
  },
  OLD_FIT_FITTY_WORKOUT: {
    ID: "FIT_FITTY_WORKOUT",
    PATH: "/fitty-workout/:workoutId/:workoutStep/:sessionId",
  },

  FIT_DASHBOARD: {
    TITLE: "User Dashboard",
    ID: "FIT_DASHBOARD",
    PATH: "dashboard",
  },
  FIT_QUIZ: {
    ID: "FIT_QUIZ",
    PATH: "quiz",
  },

  FIT_AUTH: {
    ID: "AUTH",
    PATH: "/auth",
  },
  FIT_AUTH_EXTERNAL: {
    ID: "AUTH",
    PATH: "/auth-external",
  },
};
