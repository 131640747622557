import { CircularProgress, styled } from "@mui/material";
import { useContext, useRef } from "react";
import { useState } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { isIOS, isSafari } from "react-device-detect";

const StyledVideo = styled("video")`
  width: 100%;
  height: auto;
  max-height: 70vh;
  ${({ $isLoading }) => $isLoading && "display: none;"}

  @media (max-width: 990px) {
    width: 95%;
  }
  @media (max-width: 836px) {
    width: 95%;
  }
  @media (max-width: 666px) {
    width: 95%;
  }
  @media (max-width: 500px) {
    width: 95%;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #a6d5fa;
  }
`;

const VideoWrapper = styled("div")`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 86px;
  min-height: 188px;
`;

const TrainerVideo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { componentData } = useContext(WorkoutContext);

  const videoUrl = componentData?.videoUrl;
  const videoUrlMov = componentData?.videoUrlMov;

  const videoref = useRef();

  return (
    <VideoWrapper>
      {isLoading && <StyledCircularProgress />}
      {videoUrl && (
        <StyledVideo
          data-testid="tipsVideoTest"
          ref={videoref}
          playsInline={true}
          autoPlay
          muted
          loop
          onCanPlay={() => {
            setIsLoading(false);
          }}
          $isLoading={isLoading}
        >
          {(isIOS || isSafari) && videoUrlMov ? (
            <source src={videoUrlMov} />
          ) : (
            <>
              <source src={videoUrl} />
              <source src={videoUrlMov} />
            </>
          )}
        </StyledVideo>
      )}
    </VideoWrapper>
  );
};

export default TrainerVideo;
