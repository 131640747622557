import styled from "styled-components";
import React from "react";
import { Slide, Slider, WithStore } from "pure-react-carousel";
import TipsCard from "../TipsCard";

import TipsTextCard from "../TipsTextCard";
import { useSelector } from "react-redux";
import { selectWorkoutSummary } from "../../../../../store/fit/summary";

const Wrapper = styled.div``;

const InnerCarouselWithStore = ({
  carouselStore: { setStoreState },
  currentSlide,
}) => {
  const { exerciseTips } = useSelector(selectWorkoutSummary) || {};

  return (
    <Wrapper>
      <Slider>
        {exerciseTips?.map((x, i) => {
          const handleCardClick = () => {
            setStoreState({
              currentSlide: i,
            });
          };
          return (
            <Slide key={i} index={i}>
              <TipsCard
                key={i}
                play={currentSlide === i}
                onClick={handleCardClick}
                {...x}
              />
            </Slide>
          );
        })}
      </Slider>
      <TipsTextCard currentSlide={currentSlide} />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  currentSlide: state.currentSlide,
});

export default WithStore(InnerCarouselWithStore, mapStateToProps);
