import styled from "styled-components";
import RadarChart from "./RadarChart";

const StyledContainer = styled.div`
  color: #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin-bottom: 24px;
  margin-top: 24px;
  background: #171d29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 24px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
`;

const RadarCard = () => {
  return (
    <StyledContainer>
      <Title>Physical assessment results</Title>
      <RadarChart />
    </StyledContainer>
  );
};

export default RadarCard;
