import { useSelector } from "react-redux";
import { styled } from "@mui/material";
import {
  selectWorkoutKcalBurned,
  selectWorkoutScore,
} from "../../../../store/fit/workout";
import { useContext, useEffect, useRef, useState } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { selectAccountUser } from "../../../../store/fit/account";

const StatisticsText = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StatisticsNumber = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Wrapper = styled("div")`
  flex: 1;
  display: flex;
  padding: 0 20px 0 20px;
  justify-content: center;

  @media (max-width: 540px) {
    flex: unset;
  }
`;

const InnerWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
// flex: 1 1 0px;

const NewScoreNumber = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Kcal = () => {
  const kcal = useSelector(selectWorkoutKcalBurned);
  const { challengeMode } = useContext(WorkoutContext);
  const user = useSelector(selectAccountUser);
  const name = user?.name?.split(" ")[0]; // Extract the first name
  const { newScore, setNewScore, newScoreTimestamp } = useContext(WorkoutContext);
  const [showNewScore, setShowNewScore] = useState(false);
  const workoutScore = useSelector(selectWorkoutScore);
  const timeout = useRef(null);

  useEffect(() => {
    if (newScore <= 0 || !newScore) return;

    timeout.current = setShowNewScore(true);
    setTimeout(() => {
      setShowNewScore(false);
    }, 900);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [newScoreTimestamp]);

  const displayName =
    name === undefined || name === null || name === "" ? "Your Score" : name;

  if (challengeMode)
    return (
      <Wrapper>
        {showNewScore && (
          <InnerWrapper>
            <NewScoreNumber>+{Math.floor(newScore)}</NewScoreNumber>
          </InnerWrapper>
        )}
        {!showNewScore && (
          <InnerWrapper>
            <StatisticsText>{displayName}</StatisticsText>
            <StatisticsNumber>{workoutScore}</StatisticsNumber>
          </InnerWrapper>
        )}
      </Wrapper>
    );

  return (
    <Wrapper>
      <InnerWrapper>
        <StatisticsText>Calories</StatisticsText>
        <StatisticsNumber>{kcal}</StatisticsNumber>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Kcal;
