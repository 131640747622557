import * as React from "react";

const ChartIcon = (props) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="#08173E"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M22 11c0 6.072-4.928 11-11 11S0 17.072 0 11c0-3.036 1.232-5.786 3.223-7.777L11 11V0c6.072 0 11 4.928 11 11Z" />
  </svg>
);

export default ChartIcon;
