import { useEffect } from "react";
import styled from "styled-components";
import TopNav from "./TopNav";
import WorkoutHistory from "./WorkoutHistory";
import Achievement from "./Achievement";
import WorkoutsGraph from "./WorkoutsGraph";
import { useDispatch } from "react-redux";
import { resetState } from "../../../store/fit/workout";
import { getWorkoutsDashboardAsync } from "../../../store/fit/dashboard";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { resetChallenge } from "../../../store/fit/challenge";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 128px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  padding: 12px;
  background: #171d29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #000;
  backdrop-filter: blur(12px);
  border-radius: 15px;
  align-items: center;
  min-height: -webkit-fill-available;
`;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appType, clientId } = useParams();
  const { username, status } = useAuth();

  useEffect(() => {
    if (!username) return;
    if (status === "authenticated") {
      dispatch(resetState());
      dispatch(resetChallenge());

      dispatch(getWorkoutsDashboardAsync(username));
    } else if (status === "not-authenticated") localStorage.removeItem("jwt");
  }, [username, status]);

  useEffect(() => {
    // HACK TILL WE CHaNGE DESIGN TO HAVE BLACK BACKGROUND
    document.body.style.background =
      "linear-gradient(0deg, rgb(0 0 0) 0%, rgb(107 116 139) 150.91%)";
    document.body.style.height = "auto";
    return () => {
      document.body.style.background = "black";
      document.body.style.height = "100%";
    };
  }, []);

  const historyClickHandler = (summary) => {
    navigate(`/${appType}/${clientId}/summary/${summary.id.slice(8)}`);
  };

  return (
    <Wrapper>
      <TopNav />
      <Achievement />
      <WorkoutHistory historyClickHandler={historyClickHandler} />
      <ChartWrapper>
        <WorkoutsGraph />
      </ChartWrapper>
    </Wrapper>
  );
};

export default Dashboard;
