import { css } from "@mui/material";
import { ROUTE } from "../routing";
import cognitoDefault from "./cognito/cognitoDefault";
import cognitoR1Sportclub from "./cognito/cognitoR1Sportsclub";
import cognitoMowellio from "./cognito/cognitoMowellio";

export const competitionClients = ["websummit"];

export const clientNamesToIgnoreCookies = ["fitq", "fitfi", "fitlynk"];

export const clientNamesToIgnoreNewUI = [];

const DEFAULT_PROTECTED_ROUTES = [ROUTE.FIT_DASHBOARD.ID];

const DEFAULT_SETTINGS = {
  showRepCount: false,
  showScore: false,
  showTimer: false,
  showFeedback: false,
};

// ----------- fitty -----------------

const fittyPrimaryColors = {
  light: "#4dabf5",
  main: "#2196f3",
  dark: "#1769aa",
};

const fitty = {
  title: "FittyAI",
  description: "FittyAI Demo",
  settings: DEFAULT_SETTINGS,
  cognito: cognitoDefault,
  requireAuth: true,
  protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  colors: {
    primary: fittyPrimaryColors,
  },
  homeUrl: "https://www.fittyai.com",
  logo: "/favicon.png",
  tabIcon: "/favicon.png",
  logoStyles: css`
    background: none;
    border-radius: none;
  `,
};

// ----------- fitty -----------------

const r1SportsclubPrimaryColors = {
  light: "#4dabf5",
  main: "#2196f3",
  dark: "#1769aa",
};

const r1Sportsclub = {
  title: "R1 Sportsclub",
  description: "R1 Sportsclub",
  cognito: cognitoR1Sportclub,
  settings: DEFAULT_SETTINGS,
  requireAuth: true,
  // protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  protectedRoutestes: [
    ROUTE.FIT_SELECTION.ID,
    ROUTE.FIT_WORKOUT.ID,
    ROUTE.FIT_SUMMARY.ID,
    ROUTE.FIT_SUMMARY_TIPS.ID,
    ROUTE.FIT_DASHBOARD.ID,
  ],
  colors: {
    primary: r1SportsclubPrimaryColors,
  },
  homeUrl: "https://www.r1-sportsclub.de/",
  backgroundColor: "#373737",
  logo: "/clients/r1-sportsclub/favicon.png",
  tabIcon: "/clients/r1-sportsclub/favicon.png",

  logoStyles: css`
    background: none;
    border-radius: none;

    img {
      width: 72px;
    }
  `,
};

// ----------- mowellio --------------------------

const mowellioPrimaryColors = {
  light: "#295fff",
  main: "#2196f3",
  dark: "#1769aa",
};

const mowellio = {
  title: "Mowellio",
  description: "Mowellio Workout",
  cognito: cognitoMowellio,
  settings: DEFAULT_SETTINGS,
  requireAuth: true,
  protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  colors: {
    primary: mowellioPrimaryColors,
  },
  homeUrl: "https://mowellio.com/",
  logo: "/clients/mowellio/mowellio-logo.png",
  tabIcon: "/clients/mowellio/mowellio-tab-icon.jpg",
  logoStyles: css`
    border-radius: 5px;

    img {
      width: 135px;
      height: 28px;

      @media (max-width: 1000px) {
        width: 135px;
        height: 28px;
      }
    }
  `,
};

// ----------- persikas --------------------------

const persikasPrimaryColors = {
  light: "#FFD5C1",
  main: "#FF9966",
  dark: "#824E35",
};

const persikas = {
  title: "Persikas",
  description: "Persikas Workout",
  cognito: cognitoDefault,
  ssoLink: "https://persikas.bubbleapps.io/version-test/",
  requireAuth: true,
  protectedRoutes: [
    ROUTE.FIT_SELECTION.ID,
    ROUTE.FIT_WORKOUT.ID,
    ROUTE.FIT_SUMMARY.ID,
    ROUTE.FIT_SUMMARY_TIPS.ID,
    ROUTE.FIT_DASHBOARD.ID,
  ],
  colors: {
    primary: persikasPrimaryColors,
  },
  homeUrl: "https://persikas.bubbleapps.io/version-test/",
  logo: "/clients/persikas/persikas-logo.png",
  tabIcon: "/clients/persikas/persikas-logo.png",
  logoStyles: css`
    border-radius: 5px;

    img {
      width: 100px;
      height: 100px;

      @media (max-width: 1000px) {
        width: 70x;
        height: 70px;
      }
    }
  `,
};

// ----------- fitnessgoesoffice -----------------
const fitnessgoesofficePrimaryColors = {
  light: "#ffb06b",
  main: "#FF9D47",
  dark: "#b26d31",
};

const fitnessgoesoffice = {
  title: "Fitness Goes Office",
  description: "Fitness Goes Office Workout",
  cognito: cognitoDefault,
  settings: DEFAULT_SETTINGS,
  requireAuth: true,
  protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  colors: {
    primary: fitnessgoesofficePrimaryColors,
  },
  homeUrl: "https://fitnessgoesoffice.com/en/",
  logo: "/clients/fitnessgoesoffice/favicon.png",
  tabIcon: "/clients/fitnessgoesoffice/favicon.png",
  logoStyles: css`
    border-radius: 5px;

    img {
      width: 65px;
      height: 65px;

      @media (max-width: 1000px) {
        width: 48px;
        height: 48px;
      }
    }
  `,
};

// ----------- fitnessgoesoffice bg -----------------
const fitnessgoesofficeV2PrimaryColors = {
  light: "#ffb06b",
  main: "#FF9D47",
  dark: "#b26d31",
};

const fitnessgoesofficev2 = {
  title: "Fitness Goes Office",
  description: "Fitness Goes Office Workout",
  cognito: cognitoDefault,
  settings: DEFAULT_SETTINGS,
  requireAuth: true,
  protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  colors: {
    primary: fitnessgoesofficeV2PrimaryColors,
  },
  homeUrl: "https://fitnessgoesoffice.com/en/",
  backgroundImage: "/clients/fitnessgoesofficev2/darkbg.jpg",
  logo: "/clients/fitnessgoesofficev2/favicon.png",
  tabIcon: "/clients/fitnessgoesofficev2/favicon.png",
  logoStyles: css`
    border-radius: 5px;

    img {
      width: 65px;
      height: 65px;

      @media (max-width: 1000px) {
        width: 48px;
        height: 48px;
      }
    }
  `,
};

// ----------- yellow -----------------

const yellowPrimaryColors = {
  light: "#ffb74d",
  main: "#ffb74d",
  dark: "#f57c00",
};

const yellow = {
  title: "Yellow",
  description: "Yellow Demo",
  cognito: cognitoDefault,
  requireAuth: false,
  protectedRoutes: [],
  colors: {
    primary: yellowPrimaryColors,
  },
  homeUrl: "https://www.fittyai.com",
  logo: "/favicon.png",
  tabIcon: "/favicon.png",
  logoStyles: css`
    background: none;
    border-radius: none;
  `,
};

// ----------- green -----------------

const greenPrimaryColors = {
  light: "#81c784",
  main: "#66bb6a",
  dark: "#388e3c",
};

const green = {
  title: "Green",
  settings: {
    showTips: true,
  },
  description: "Green Demo",
  cognito: cognitoDefault,
  ssoLink: "https://www.r1-sportsclub.de/",
  settings: DEFAULT_SETTINGS,
  requireAuth: true,
  protectedRoutes: [
    ROUTE.FIT_SELECTION.ID,
    ROUTE.FIT_WORKOUT.ID,
    ROUTE.FIT_SUMMARY.ID,
    ROUTE.FIT_SUMMARY_TIPS.ID,
    ROUTE.FIT_DASHBOARD.ID,
  ],
  colors: {
    primary: greenPrimaryColors,
  },
  homeUrl: "https://www.fittyai.com",
  tabIcon: "/favicon.png",
  logoStyles: css`
    background: none;
    border-radius: none;
  `,
};

// ------------------ reaction-club ------------

const reacionClubPrimaryColors = {
  light: "#f9dc6e",
  main: "#f8d34a",
  dark: "#7c6a25",
};

const reactionclub = {
  title: "reaction-club",
  settings: {
    showRepCount: true,
    showScore: true,
    showTimer: false,
    showFeedback: false,
    showTips: false,
    showVideoTutorial: true,
  },
  defaultEnduranceTimeout: 3000,
  forcePlayStartStopSound: true,
  disableCloseOnEnd: true,
  description: "FittyAI Demo",
  cognito: cognitoDefault,
  requireAuth: true,
  disableSummaryLoading: true,
  protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  colors: {
    primary: reacionClubPrimaryColors,
  },
  homeUrl: "https://www.fittyai.com",
  logo: "/favicon.png",
  tabIcon: "/favicon.png",
  logoStyles: css`
    background: none;
    border-radius: none;
  `,
};

const fitq = {
  title: "fitq",
  settings: {
    showRepCount: true,
    showScore: true,
    showTimer: false,
    showFeedback: true,
    showTips: false,
    showVideoTutorial: false,
  },
  showGoAfterTips: true,
  ////// skips video tutorial for second+ time users
  skipVideoTutorialFeature: true,
  description: "FittyAI Demo",
  cognito: cognitoDefault,
  requireAuth: false,
  showSummaryAnyway: true,
  protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  disableSummaryButtons: true,
  colors: {
    primary: fittyPrimaryColors,
  },
  homeUrl: "https://www.fittyai.com",
  logo: "/favicon.png",
  tabIcon: "/favicon.png",
  logoStyles: css`
    background: none;
    border-radius: none;
  `,
};

const fitfi = {
  title: "Fitfi",
  description: "Fitfi",
  settings: DEFAULT_SETTINGS,
  cognito: cognitoDefault,
  requireAuth: false,
  removeSkipButtonClient: true,
  protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  disableSummaryLoading: true,
  colors: {
    primary: fittyPrimaryColors,
  },
  homeUrl: "https://www.fittyai.com",
  logo: "/favicon.png",
  tabIcon: "/favicon.png",
  logoStyles: css`
    background: none;
    border-radius: none;
  `,
};

const fitlynk = {
  title: "Fitlynk",
  description: "Fitlynk",
  settings: DEFAULT_SETTINGS,
  cognito: cognitoDefault,
  requireAuth: true,
  protectedRoutes: DEFAULT_PROTECTED_ROUTES,
  disableSummaryButtons: true,
  disableLeaderboard: true,
  colors: {
    primary: fittyPrimaryColors,
  },
  homeUrl: "https://www.fittyai.com",
  logo: "/favicon.png",
  tabIcon: "/favicon.png",
  logoStyles: css`
    background: none;
    border-radius: none;
  `,
};

// ----------- CONFIGURATION -----------------

const clientsMap = {
  fitty,
  fitnessgoesoffice,
  yellow,
  green,
  fitnessgoesofficev2,
  fgotest: fitnessgoesofficev2,
  mowellio,
  persikas,
  ["r1-sportsclub"]: r1Sportsclub,
  ["reaction-club"]: reactionclub,
  fitq,
  fitfi,
  fitlynk,
};

export default (companyName) => {
  return clientsMap[companyName] || fitty;
};
