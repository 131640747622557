import styled from "styled-components";
import MobileRepCount from "./MobileRepCount";
import MobileScore from "./MobileScore";
import MobileStaticTimer from "./MobileStaticTimer";

const MobileWrapper = styled.div`
  display: flex;
  position: absolute;
  height: -webkit-fill-available;
  width: 100%;
`;

const MobileStats = ({ expiryTimestamp, startCountdown, staticExpiryTimestamp }) => {
  return (
    <MobileWrapper>
      <MobileScore expiryTimestamp={expiryTimestamp} startCountdown={startCountdown} />
      <MobileRepCount />
      <MobileStaticTimer
        staticExpiryTimestamp={staticExpiryTimestamp}
        startCountdown={startCountdown}
      />
    </MobileWrapper>
  );
};

export default MobileStats;
