import { CONFIG } from "../config";

export const getCompanyNameFromUrl = () => {
  const parts = window.location.pathname?.split("/");
  const clientName = parts
    ? parts[2]
      ? parts[2]
      : CONFIG.defaultCompanyName
    : CONFIG.defaultCompanyName;
  //   const client = clientId ? clientId : CONFIG.defaultCompanyName;
    
    return clientName;
};
