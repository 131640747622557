export const cheatersIds = [
  "17214107",
  "16053001",
  "17248362",
  "17246957",
  "17263642",
  "17238767",
  "17243239",
  "17253923",
  "17238095",
  "17264697",
  "17251460",
  "17227216",
  "17242741",
  "17210338",
  "17240759",
  "17246233",
  "17215756",
  "17262835",
  "17143889",
  "16133975",
  "17239641",
  "17250902",
  "17143900",
  "17248232",
  "17244603",
  "17257027",
  "17241104",
  "17215754",
  "17246655",
  "17238469",
  "17218290",
  "17227272",
  "17213148",
  "17247672",
  "17242693",
  "17246409",
  "17258027",
  "17263650",
  "17261174",
  "17241099",
  "17247137",
  "17260705",
  "17238326",
  "17264684",
  "17253200",
  "17227217",
  "17220052",
  "17217162",
  "16131137",
  "17234755",
  "17248927",
  "17216490",
  "17245979",
  "17251207",
  "17241666",
  "17248359",
  "17143886",
  "16103910",
  "17242087",
  "17244601",
  "17236580",
  "17247546",
  "17259745",
  "17215539",
  "17236583",
  "16131061",
  "17142438",
  "17256482",
  "17252401",
  "17227883",
  "17256198",
  "17244725",
  "17238005",
  "17210346",
  "17252405",
  "17239714",
  "17231542",
  "17245797",
  "17244719",
  "17251076",
  "17239790",
  "17253396",
  "17251667",
  "17252588",
  "17245140",
  "17241239",
  "17253199",
  "17217692",
  "17141636",
  "17227065",
  "17248181",
  "17232775",
  "17261009",
  "17247878",
  "16066800",
  "17254926",
  "17239246",
  "16103926",
  "17215098",
  "16058135",
  "17143887",
  "17236030",
  "17254920",
  "17243529",
  "16081189",
  "17249597",
  "17264095",
  "17264680",
  "17242379",
  "17210542",
  "16064887",
  "17248416",
  "17210694",
  "16080559",
  "17264662",
  "17207345",
  "17248354",
  "17244354",
  "17255987",
  "16107186",
  "17252411",
  "17253019",
  "17143888",
  "16103917",
  "17249733",
  "16143486",
  "17249738",
  "17221543",
  "17208773",
  "17264722",
  "16071235",
  "17264317",
  "17264281",
  "17247176",
  "17245430",
  "17218780",
  "17240489",
  "17227246",
  "17150124",
  "17210446",
  "17231756",
  "17217041",
  "17237760",
  "17255387",
  "17226094",
  "17253714",
  "17240664",
  "17255373",
  "16172291",
  "16073262",
  "17225773",
  "17264380",
  "17237749",
  "16058929",
  "17248641",
  "17254965",
  "17230242",
  "17264466",
  "17255871",
  "16103851",
  "17253915",
  "17208438",
  "17244548",
  "17254969",
  "17253109",
  "17260952",
  "17262072",
  "16054651",
  "17238216",
  "17238214",
  "17245152",
  "17206886",
  "17227208",
  "17251212",
  "16061931",
  "17143891",
  "16174145",
  "17237630",
  "17243582",
  "17227238",
  "17218813",
  "17231415",
  "17248432",
  "17227232",
  "17221159",
  "17241092",
  "17253283",
  "17262861",
  "17242357",
  "17264385",
  "17215044",
  "17229321",
  "17203558",
  "16091412",
  "17254543",
  "17143894",
  "17225796",
  "17249141",
  "16055869",
  "17224991",
  "17255821",
  "17227247",
  "17254307",
  "17247903",
  "17248379",
  "17242241",
  "17251204",
  "17227188",
  "17253117",
  "17246411",
  "16103858",
  "17255483",
  "17248261",
  "17251078",
];

// Convert the array to a Set for faster lookup
const cheaterIdsSet = new Set(cheatersIds);

// Function to check if an ID is in the set
const isCheater = (id) => {
  return cheaterIdsSet.has(id);
};

export default isCheater;
