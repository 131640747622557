import React from "react";
import { useSelector } from "react-redux";
import {
  selectChallengeSummary,
  selectChallengeSummaryLoading,
} from "../../../../../store/fit/challenge";
import { styled } from "@mui/material";
import Divider from "@mui/material/Divider";
import ProgressBar from "./ProgressBar"; // Import ProgressBar
import { Metric } from "../../PerformanceCard";

const ChallengeResultsData = () => {
  const challengeSummary = useSelector(selectChallengeSummary);
  const challengeSummaryLoading = useSelector(selectChallengeSummaryLoading);

  if (challengeSummaryLoading) {
    return <div>Loading...</div>;
  }

  if (!challengeSummary) {
    return <div>No data available</div>;
  }

  const { YourData, ChallengerData } = challengeSummary;
  const yourScore = YourData.performanceScore;
  const yourTotalReps = YourData?.total_reps;
  const challengerTotalReps = ChallengerData?.total_reps;
  const challengerScore = ChallengerData.performanceScore;
  const totalPossibleScore = yourScore + challengerScore; // Assuming the total possible score is 100
  const myScorePercentage = (yourScore / totalPossibleScore) * 100;
  const myTotalPossibleScorePercentage = (yourScore / (yourTotalReps * 100)) * 100;
  const challengerPossibleScorePercentage =
    (challengerScore / (challengerTotalReps * 100)) * 100;
  const resultText = yourScore > challengerScore ? "You WIN!" : "You LOST!";
  const resultColor = yourScore > challengerScore ? "#90ee90" : "#f08080";

  return (
    <>
      <Card>
        <TitleContainer>
          <ResultTitle style={{ color: resultColor }}>{resultText}</ResultTitle>
        </TitleContainer>
        <MedalBox></MedalBox>
        <SubtitleContainer>
          <SubtitleLeft>{YourData.name || "Your"}</SubtitleLeft>
          <SubtitleRight>{ChallengerData.name || "Challenger's"}</SubtitleRight>
        </SubtitleContainer>
        <ProgressBar myScorePercentage={myScorePercentage} /> {/* Add ProgressBar here */}
        <ScoreContainer>
          <SubtitleLeft>
            <Metric
              dataLabelText="score"
              iconName="FlagIcon"
              iconWidth="15px"
              iconHeight="19px"
              dataText={yourScore}
              percentage={myTotalPossibleScorePercentage}
            />
          </SubtitleLeft>
          <SubtitleRight>
            <Metric
              dataLabelText="score"
              iconName="FlagIcon"
              iconWidth="15px"
              iconHeight="19px"
              dataText={challengerScore}
              percentage={challengerPossibleScorePercentage}
            />
          </SubtitleRight>
        </ScoreContainer>
      </Card>
    </>
  );
};

export default ChallengeResultsData;

const MedalBox = styled("div")`
  display: flex;
  height: 200px;
`;

const Card = styled("div")`
  display: flex;
  flex-direction: column; /* Ensure column direction for title and content */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  width: 100%;
  height: fit-content;
  background: #171d29;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(21px);
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 24px;
`;

const TitleContainer = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;

const ResultTitle = styled("div")`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
`;

const SubtitleContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const SubtitleLeft = styled("div")`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  text-align: left;
  flex: 1;
`;

const SubtitleRight = styled("div")`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  text-align: right;
  flex: 1;
`;

const DividerContainer = styled("div")`
  width: 100%;
  margin-bottom: 20px; /* Margin to add spacing around the divider */
`;

const TotalScoreContainer = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const ScoreContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;
