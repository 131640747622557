import { styled } from "@mui/material";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useContext } from "react";
import { useState } from "react";
import Countdown from "../../../../../components/WorkoutComponents/Stats/Timer/Countdown";
import Stopwatch from "../../../../../components/WorkoutComponents/Stats/Timer/Stopwatch";
import { useRef } from "react";
import { useEffect } from "react";
import { CONFIG } from "../../../../../config";
import { selectWorkoutFlow, selectWorkoutScore } from "../../../../../store/fit/workout";
import { useSelector } from "react-redux";
import { getCompanyNameFromUrl } from "../../../../../utils/urls";

const TimerContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f5f5f5;
  position: relative;
  min-width: 100px;
  min-height: 100px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(74, 63, 22, 1) 0%,
    rgba(244, 107, 107, 0.006) 100%
  );
`;

const ReactionTimer = () => {
  const { startTimer, setTimeLeft, componentData, timeIsUp, repCount } =
    useContext(WorkoutContext);
  const workoutScore = useSelector(selectWorkoutScore);
  const [startCountdown, setStartCountdown] = useState(false);
  const [showEnduranceTimeOut, setShowEnduranceTimeOut] = useState(false);
  const expiryTimestamp = useRef(null);
  const enduranceTimer = useRef(null);
  const duration = componentData?.duration;
  const companyName = getCompanyNameFromUrl();
  /////Timer timestamp, for it to not reset after style change
  useEffect(() => {
    setStartCountdown(startTimer);
    if (startTimer) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + duration);

      expiryTimestamp.current = time;
    }
  }, [startTimer]);

  ////endurance exercise logic
  useEffect(() => {
    if (
      componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticEnduranceExercise
    ) {
      clearTimeout(enduranceTimer.current);
      setShowEnduranceTimeOut(false);
      setTimeLeft(undefined);

      if (workoutScore > 0) {
        enduranceTimer.current = setTimeout(() => {
          setShowEnduranceTimeOut(true);
        }, 5000);
      }
    }

    if (
      componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise ||
      componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise
    ) {
      clearTimeout(enduranceTimer.current);
      setShowEnduranceTimeOut(false);

      if (workoutScore > 0) {
        enduranceTimer.current = setTimeout(
          () => {
            setShowEnduranceTimeOut(true);
            /////reset timer called
          },
          companyName === "reaction-club" ? 3000 : 6000
        );
      }
    }

    return () => {
      clearTimeout(enduranceTimer.current);
    };
  }, [workoutScore]);

  const timerText = (time) => {
    if (!time) return null;
    return `${Math.floor(time / 60)}:${time % 60 < 10 ? "0" + (time % 60) : time % 60}`;
  };

  return (
    <TimerContainer data-testid={"reactionTimerTest"}>
      <TimerText $showEnduranceTimeOut={showEnduranceTimeOut} $timeIsUp={timeIsUp}>
        {timeIsUp ? (
          <> {timerText(repCount) ?? "0:00"}</>
        ) : (
          <>
            {startCountdown &&
              (duration ? (
                <Countdown
                  expiryTimestamp={expiryTimestamp.current}
                  startTimer={startTimer}
                  setTimeLeft={setTimeLeft}
                  showEnduranceTimeOut={showEnduranceTimeOut}
                />
              ) : (
                <Stopwatch
                  showEnduranceTimeOut={showEnduranceTimeOut}
                  startTimer={startTimer}
                />
              ))}
            {!startCountdown && (
              <>
                {Math.floor(duration / 60)}:
                {duration % 60 < 10 ? "0" + (duration % 60) : duration % 60}
              </>
            )}
          </>
        )}
      </TimerText>
    </TimerContainer>
  );
};

export default ReactionTimer;

const TimerText = styled("p")`
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  font-size: 40px;
  color: ${({ theme }) => theme.palette.primary.main};
  ${({ $showEnduranceTimeOut, $timeIsUp }) =>
    $showEnduranceTimeOut || $timeIsUp
      ? `
      animation: reactionBlink 1s linear 0s infinite normal forwards;
      `
      : ""}
  @keyframes reactionBlink {
    0%,
    50%,
    100% {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    25%,
    75% {
      color: #cf1908;
    }
  }
`;
