import { TextField, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/Buttons/Button";
import { selectEnteredName, setEnteredName } from "../../../../store/fit/quiz";

const Wrapper = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 420px;
  width: 100%;
  gap: 104px;
`;

const RegistrationName = ({ setEmailWindow }) => {
  const dispatch = useDispatch();
  const enteredName = useSelector(selectEnteredName);

  const submitHandler = (e) => {
    const formElements = e.target.elements;
    e.preventDefault();
    dispatch(setEnteredName(formElements[0].value));
    setEmailWindow(true);
  };

  return (
    <Wrapper onSubmit={submitHandler}>
      <TextField
        type="name"
        defaultValue={enteredName}
        label="Name"
        variant="outlined"
      />

      <Button type="submit" size="large">
        NEXT
      </Button>
    </Wrapper>
  );
};

export default RegistrationName;
