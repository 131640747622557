import { CircularProgress, styled } from "@mui/material";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useContext, useEffect, useRef } from "react";
import BeginWorkoutScreen from "./BeginWorkoutScreen";
import SoundContext from "../../../containers/Mirror/WorkoutScreen/SoundContext";
import { Howl } from "howler";
import { useSelector } from "react-redux";
import {
  selectChallengeError,
  selectChallengeLoading,
} from "../../../store/fit/challenge";
import { challengeStartSound } from "../../../containers/Mirror/WorkoutScreen/SoundContext/soundContextConfig";

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #dbeefd;
  }
`;

const Card = styled("div")`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  padding: 10px;
  top: 0;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(107, 116, 139) 150.91%);
`;

const LoadingText = styled("p")`
  margin-top: 20px;
  color: white;
  text-align: center;
`;

const CanvasLoader = () => {
  const { canvasLoaded, challengeMode } = useContext(WorkoutContext);
  const howl = useRef(null);
  const challengeLoading = useSelector(selectChallengeLoading);
  const challengeError = useSelector(selectChallengeError);
  useEffect(() => {
    howl.current = new Howl({
      src: [
        challengeMode && !challengeError
          ? "https://fittyai-utils.s3.amazonaws.com/audio-files/challenge/challenge-beginning-message.mp3"
          : "https://fittyai-utils.s3.amazonaws.com/audio-files/timer_start_indicator.mp3",
      ],
      autoplay: false, // Prevent autoplay
      html5: true,
      loop: false, // Looping is off, but you can enable if needed
      volume: challengeMode && !challengeError ? 1 : 0.1, // Adjust volume as needed
    });
  }, [challengeError]);

  if (challengeMode) {
    if (canvasLoaded && !challengeLoading) return <BeginWorkoutScreen howl={howl} />;
  } else if (canvasLoaded) return <BeginWorkoutScreen howl={howl} />;

  const loadingText = !canvasLoaded
    ? "Initiating body tracking..."
    : challengeLoading
    ? "Challenge data loading..."
    : "";

  return (
    <Card>
      <StyledCircularProgress />
      <LoadingText>{loadingText}</LoadingText>
    </Card>
  );
};

export default CanvasLoader;
