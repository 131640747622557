export const GRAPH_OPTIONS = {
  chart: {
    animations: {
      type: "area",
      enabled: true,
      easing: "linear",
      dynamicAnimation: {
        speed: 500,
      },
    },

    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  legend: {
    show: false,
  },

  tooltip: {
    enabled: true,
    x: {
      show: false,
    },
    marker: {
      show: false,
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
  },
  title: {
    align: "center",
    style: { fontSize: "28px", fontWeight: "700", color: "#fff" },
  },
  markers: {
    size: 0,
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: false,
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },

  xaxis: {
    type: "datetime",

    tickAmount: 10,
    axisBorder: {
      show: false,
    },
    labels: {
      show: true,
      rotate: 0,
      style: {
        colors: "#fff",
      },
    },
    axisTicks: {
      show: true,
    },
  },
  yaxis: {
    tickAmount: 5,
    labels: {
      style: {},
      hideOverlappingLabels: true,
    },

    min: 0,
  },
};
