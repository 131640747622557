import WorkoutCard from "../WorkoutCard";
import React from "react";
import { Slide, Slider, WithStore } from "pure-react-carousel";
import { useSelector } from "react-redux";
import { selectWorkouts } from "../../../../../../../store/fit/selection";
import styled from "styled-components";

const Wrapper = styled.div``;

const InnerCarouselWithStore = ({
  carouselStore: { setStoreState },
  currentSlide,
}) => {
  const workouts = useSelector(selectWorkouts);

  return (
    <Wrapper>
      <Slider>
        {workouts.map((x, i) => {
          const workoutName = x.name;
          const time = x.durationInSeconds;
          const id = x.id;

          const handleCardClick = () => {
            setStoreState({
              currentSlide: i,
            });
          };

          return (
            <Slide key={i} index={i}>
              <WorkoutCard
                slideIndex={i}
                time={time}
                key={i}
                workoutName={workoutName}
                id={id}
                index={i}
                play={currentSlide === i}
                onClick={handleCardClick}
              />
            </Slide>
          );
        })}
      </Slider>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  currentSlide: state.currentSlide,
});

export default WithStore(InnerCarouselWithStore, mapStateToProps);
