import styled from "styled-components";
import Canvas from "../../../../../components/WorkoutComponents/Canvas";
import WorkoutContext from "../../WorkoutContext";
import { useContext, useEffect, useState } from "react";
import CanvasLoader from "../../../../../components/WorkoutComponents/CanvasLoader";
import VideoTutorial from "../../../../../components/WorkoutComponents/VideoTutorial";
import Tips from "../../../../../components/WorkoutComponents/Tips";
import Tutorial from "../../../../../components/WorkoutComponents/Tutorial";
import WorkoutUI from "../../../../../components/WorkoutComponents/WorkoutUI";
import Stats from "../../../../../components/WorkoutComponents/Stats";
import Go from "../../../../../components/WorkoutComponents/Go";
import { useSelector } from "react-redux";
import {
  selectStartedUploadProcess,
  selectWaitForUpload,
} from "../../../../../store/fit/account";
import {
  selectCurrentEventIndex,
  selectWorkoutFlow,
} from "../../../../../store/fit/workout";
import FinishingUploadScreen from "../../../../../components/WorkoutComponents/WorkoutUI/FinishingUploadScreen";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: -webkit-fill-available;
  width: 100%;
  @media (min-width: 1350px) {
    gap: 50px;
  }
  max-width: 1400px;
  margin: 0 auto;
`;

const CanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 800px;
  margin: 0 auto;
`;

const componentMap = {
  setupTutorial: <VideoTutorial />,
  cameraSetupValidation: <></>,
  tutorial: <Tutorial />,
  exercise: <WorkoutUI />,
  break: <WorkoutUI />,
  staticExercise: <WorkoutUI />,
  officeStaticExercise: <WorkoutUI />,
  officeExercise: <WorkoutUI />,
  enduranceExercise: <WorkoutUI />,
  staticEnduranceExercise: <WorkoutUI />,
  tips: <Tips />,
};

const statsMap = {
  exercise: <Stats />,
  officeExercise: <Stats />,
  staticExercise: <Stats />,
  officeStaticExercise: <Stats />,
  break: <Stats />,
  enduranceExercise: <Stats />,
  staticEnduranceExercise: <Stats />,
  setupTutorial: <></>,
  cameraSetupValidation: <></>,
  tutorial: <></>,
  tips: <></>,
};

const Composition = () => {
  const {
    canvasLoaded,
    componentData,
    showGo,
    setShowGo,
    userReady,
    unmountAnimationFinished,
  } = useContext(WorkoutContext);
  const waitForUpload = useSelector(selectWaitForUpload);
  const workout = useSelector(selectWorkoutFlow);
  const currentEventIndex = useSelector(selectCurrentEventIndex);
  const startedUploadingProcess = useSelector(selectStartedUploadProcess);

  const showFinishingUploadScreen =
    currentEventIndex >= workout?.workoutFlow.length &&
    waitForUpload &&
    startedUploadingProcess;
  const duration = 3;
  useEffect(() => {
    let timer = setTimeout(() => {
      setShowGo(false);
    }, duration * 1000);
    //

    return () => {
      clearTimeout(timer);
    };
  }, [showGo]);

  if (showFinishingUploadScreen) return <FinishingUploadScreen />;

  return (
    <>
      {(!canvasLoaded || !unmountAnimationFinished) && <CanvasLoader />}
      <Wrapper>
        <CanvasWrapper>
          <Canvas />
          {userReady && statsMap[componentData?.type]}
        </CanvasWrapper>

        {userReady && componentMap[componentData?.type]}
        {showGo && <Go />}
      </Wrapper>
    </>
  );
};

export default Composition;
