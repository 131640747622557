import { useSelector } from "react-redux";
import { useEffect, useContext } from "react";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useTimer } from "react-timer-hook";
import { selectShowError } from "../../../../../store/fit/error";
import {
  countdownTimerTimeLeftComponentTypes,
  forceStartStopSoundComponentTypes,
} from "./countdownConfig";
import { getCompanyNameFromUrl } from "../../../../../utils/urls";
import clients from "../../../../../constants/clients";

const Countdown = ({
  expiryTimestamp,
  startTimer,
  setTimeLeft,
  showEnduranceTimeOut,
}) => {
  const { nextStageWorkout, paused, componentData, setStaticStartTimer, repCount } =
    useContext(WorkoutContext);
  const showError = useSelector(selectShowError);
  const companyName = getCompanyNameFromUrl();
  const { forcePlayStartStopSound } = clients(companyName);

  const { seconds, minutes, start, restart, pause, resume, isRunning } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: () => {
      nextStageWorkout(true);
      restart(expiryTimestamp);
    },
  });

  useEffect(() => {
    startTimer && paused ? pause() : resume();
  }, [paused]);

  useEffect(() => {
    if (showEnduranceTimeOut) {
      // Calculate the total remaining time in seconds
      const remainingTime = componentData?.duration - repCount;
      // Calculate the new expiry timestamp
      const newExpiryTimestamp = new Date();
      newExpiryTimestamp.setSeconds(newExpiryTimestamp.getSeconds() + remainingTime);
      restart(newExpiryTimestamp, false); // Restart without auto-starting
    } else {
      resume();
    }
  }, [showEnduranceTimeOut]);

  useEffect(() => {
    if (!countdownTimerTimeLeftComponentTypes.includes(componentData?.type)) return;
    const secondsLeft = minutes * 60 + seconds;

    setTimeLeft(secondsLeft);
  }, [seconds, minutes]);

  useEffect(() => {
    if (startTimer && !showError) start();
  }, [startTimer, showError]);

  useEffect(() => {
    if (startTimer && showError) pause();
    if (startTimer && !showError) resume();
  }, [showError]);

  ///// REACTION TIMER SETTING FOR SOUNDS
  useEffect(() => {
    if (
      forcePlayStartStopSound &&
      forceStartStopSoundComponentTypes.includes(componentData?.type)
    )
      setStaticStartTimer(isRunning);
  }, [isRunning]);

  return (
    <>
      {minutes}:{seconds < 10 ? "0" + seconds : seconds}
    </>
  );
};

export default Countdown;
