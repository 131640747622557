import SoundContextComponent from "./SoundContext/SoundContextComponent";
import WebsocketContextComponent from "./WebsocketContext/WebsocketContextComponent";
import WorkoutContextComponent from "./WorkoutContext/WorkoutContextComponent";
// import WorkoutLogic from "./WorkoutLogic";
import WorkoutFlow from "./NewLogic/WorkoutFlow";

const WorkoutScreen = () => {
  return (
    <WorkoutContextComponent>
      <WebsocketContextComponent>
        <SoundContextComponent>
          <WorkoutFlow />
        </SoundContextComponent>
      </WebsocketContextComponent>
    </WorkoutContextComponent>
  );
};

export default WorkoutScreen;
