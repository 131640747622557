import styled from "styled-components";
import useWindowWidth from "../../../hooks/useWindowWidth";
import ReactionTrainer from "./ReactionTrainer";
import { CONFIG } from "../../../config";
import ReactionClubExit from "./ReactionClubExit";
import ReactionStats from "./ReactionStats";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useContext } from "react";

const Wrapper = styled.div`
  display: flex;
  height: -webkit-fill-available;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${({ $screenWidth }) => $screenWidth < CONFIG.DesktopTrigger && `width: 0;`}
`;

const ReactionClubWorkoutUI = () => {
  const screenWidth = useWindowWidth();
  const { componentData } = useContext(WorkoutContext);
  const componentType = componentData?.type;
  return (
    <Wrapper $screenWidth={screenWidth}>
      <ReactionStats />
      <ReactionTrainer />
      {(componentType !== CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise ||
        componentType !== CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise) && (
        <ReactionClubExit />
      )}
    </Wrapper>
  );
};

export default ReactionClubWorkoutUI;
