import { useContext, useEffect, useRef, useState } from "react";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { CONFIG } from "../../../config";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { CircularProgress, styled } from "@mui/material";
import { isIOS, isSafari } from "react-device-detect";

const TrainerEl = styled("video")`
  ${({ $isLoading }) => $isLoading && "display: none;"}
  transition: all 0.5s linear;
  ${({ $screenWidth }) =>
    $screenWidth > CONFIG.DesktopTrigger ? `width: 100%;` : `width: 40%;`}
  max-height: 400px;
  position: absolute;
  z-index: 6;
  left: 0;

  top: 50%;
  transform: translate(0%, -50%);
  width: 100%;
  max-width: 100%;
  filter: opacity(85%);
`;

const MobileWrapper = styled("div")`
  @media (max-width: 800px) {
    position: absolute;
    display: flex;
    justify-content: center;
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const Wrapper = styled("div")`
  height: -webkit-fill-available;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: ${({ $screenWidth }) => ($screenWidth < 1400 ? $screenWidth - 895 : 700)}px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #a6d5fa;
  }
`;

const Tutorial = () => {
  const { componentData, setShowGoOnStart } = useContext(WorkoutContext);
  const screenWidth = useWindowWidth();
  const [isLoading, setIsLoading] = useState(true);
  const videoUrl = componentData?.videoUrl;
  const videoUrlMov = componentData?.videoUrlMov;
  const videoRef = useRef(null);

  useEffect(() => {
    setShowGoOnStart(true);
  }, []);

  useEffect(() => {
    videoRef.current?.load();
  }, [videoUrl]);

  if (screenWidth < CONFIG.DesktopTrigger) {
    return (
      <MobileWrapper>
        {/* {isLoading && <StyledCircularProgress />} */}
        {videoUrl && (
          <TrainerEl
            ref={videoRef}
            data-testid="tutorialVideoTest"
            playsInline={true}
            autoPlay
            muted
            loop
            $screenWidth={screenWidth}
            onLoadedData={() => {
              setIsLoading(false);
            }}
            $isLoading={isLoading}
          >
            {(isIOS || isSafari) && videoUrlMov ? (
              <source src={videoUrlMov} />
            ) : (
              <>
                <source src={videoUrl} />
                <source src={videoUrlMov} />
              </>
            )}
          </TrainerEl>
        )}
      </MobileWrapper>
    );
  } else {
    return (
      <Wrapper>
        {videoUrl && (
          <TrainerEl
            data-testid="tutorialVideoTest"
            playsInline={true}
            autoPlay
            muted
            loop
            $screenWidth={screenWidth}
            onLoadedData={() => {
              setIsLoading(false);
            }}
            $isLoading={isLoading}
          >
            {(isIOS || isSafari) && videoUrlMov ? (
              <source src={videoUrlMov} />
            ) : (
              <>
                <source src={videoUrl} />
                <source src={videoUrlMov} />
              </>
            )}
          </TrainerEl>
        )}
      </Wrapper>
    );
  }
};

export default Tutorial;
