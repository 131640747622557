import * as React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import styled from "styled-components";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${({ $mb }) => $mb};
`;

const Label = styled.div`
  margin-left: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #ffffff;
`;

const getLabelText = (value) => {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
};

const StarsRatings = ({ value, setValue, $mb = "none" }) => {
  const [hover, setHover] = React.useState(-1);

  return (
    <Wrapper $mb={$mb}>
      <Rating
        name="hover-feedback"
        value={value}
        precision={0.5}
        getLabelText={getLabelText}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(e, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {value !== null && <Label>{labels[hover !== -1 ? hover : value]}</Label>}
    </Wrapper>
  );
};

export default StarsRatings;
