import { Button, styled, Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SmartLink from "../../../../components/Primitives/SmartLink";
import useAuth from "../../../../hooks/useAuth";
import { getCompanyNameFromUrl } from "../../../../utils/urls";
import clients from "../../../../constants/clients";
import { useSelector } from "react-redux";
import { selectWorkoutSummary } from "../../../../store/fit/summary";
import { FE_URL } from "../../../../api";
import { GUID } from "../../../../helpers/GUID";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  min-width: 120px;
`;

const ChallengeButton = styled(StyledButton)`
  color: #54b862;
  font-weight: bold;
  border-color: #54b862;
`;

const TopNav = () => {
  const { appType, sessionId, clientId } = useParams();
  const { status } = useAuth();
  const clientName = getCompanyNameFromUrl();
  const { disableSummaryButtons } = clients(clientName);
  const workoutSummary = useSelector(selectWorkoutSummary);
  const workoutId = workoutSummary?.workoutId;
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const buildChallengeUrl = () => {
    return `${FE_URL}/${appType}/${clientId}/${workoutId}/${GUID()}?challenge=${sessionId}`;
  };

  if (disableSummaryButtons) return null;

  const handleChallengeFriendClick = () => {
    const linkToCopy = buildChallengeUrl();
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Wrapper>
      {/* <Row>
        {status === "authenticated" && (
          <StyledButton
            component={SmartLink}
            variant="outlined"
            to={`/${appType}/${clientId}/dashboard`}
          >
            Dashboard
          </StyledButton>
        )}

        <StyledButton
          component={SmartLink}
          to={`/${appType}/${clientId}/summary/${sessionId}/tips`}
          variant="outlined"
        >
          Tips
        </StyledButton>
      </Row> */}
      {workoutId && (
        <Row>
          <ChallengeButton variant="outlined" onClick={handleChallengeFriendClick}>
            CHALLENGE
          </ChallengeButton>
        </Row>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default TopNav;
