import { styled } from "@mui/material";
import ScoreNumber from "./ScoreNumber";
import ScoreBar from "./ScoreBar";
import Timer from "../../../Stats/Timer";
import { showScoreNumber } from "../mobileStatsConfig";
import MobileRecordingIndicator from "../MobileRecordingIndicator";
import { useContext } from "react";
import WorkoutContext from "../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { recordComponents } from "../../../Canvas/canvasConfig";
import { useSelector } from "react-redux";
import { selectChallengeScore } from "../../../../../store/fit/challenge";
import { selectAccountUser } from "../../../../../store/fit/account";

const Wrapper = styled("div")`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ $challengeMode }) => ($challengeMode ? " align-items: flex-start;" : "")}
  height: min-content;
  ${({ $challengeMode }) => ($challengeMode ? "" : "padding:  10px 10px 10px 10px;")}
  gap: 10px;
  background: #ffffff00;
  background: linear-gradient(0deg, #ffffff00, #01010180);
`;

const ScoreWrapper = styled("div")`
  display: flex;
  width: 100%;
  justify-content: ${({ challengeMode }) =>
    challengeMode ? "space-between" : "flex-start"};
`;

const ScoreContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ $challengeMode }) =>
    $challengeMode ? " align-items: flex-start;" : "  align-items: center;"}
  gap: ${({ $challengeMode }) => ($challengeMode ? "0px" : "10px")};
`;

const MobileScore = ({ expiryTimestamp, startCountdown }) => {
  const { record, paused, componentData, challengeMode } = useContext(WorkoutContext);
  const user = useSelector(selectAccountUser);
  const name = user?.name?.split(" ")[0]; // Extract the first name
  return (
    <Wrapper $challengeMode={challengeMode}>
      <ScoreWrapper challengeMode={challengeMode}>
        {challengeMode ? (
          <ScoreBar />
        ) : (
          <>
            <ScoreBar />
            <ScoreNumber type="main" />{" "}
          </>
        )}
      </ScoreWrapper>
      {challengeMode && (
        <ScoreContainer $challengeMode={challengeMode}>
          <ScoreNumber type="user" name={name} />
          <Timer expiryTimestamp={expiryTimestamp} startCountdown={startCountdown} />
          <ScoreNumber type="challenger" />
        </ScoreContainer>
      )}
      {!challengeMode && (
        <div>
          {record && !paused && recordComponents.includes(componentData?.type) && (
            <MobileRecordingIndicator />
          )}
          <Timer expiryTimestamp={expiryTimestamp} startCountdown={startCountdown} />
        </div>
      )}
    </Wrapper>
  );
};

export default MobileScore;
