import { challengeWorkoutFlowComponentTypes } from "../containers/Mirror/WorkoutScreen/NewLogic/WorkoutFlow/workoutFlowConfig";

export const modifyChallengeData = (workoutArr, challengeArr) => {
  // Create a new array with the same length as the workoutArr array
  const mergedExercises = new Array(workoutArr.length).fill(null);

  // Create a map for quick lookup of exercises in challengeArr by apiName
  const challengeMap = new Map();
  challengeArr.forEach((exercise) => {
    const [key, value] = Object.entries(exercise)[0];
    if (!challengeMap.has(key)) {
      challengeMap.set(key, []);
    }
    challengeMap.get(key).push(value);
  });

  // Log the challenge map to verify its contents

  // Populate the new array with challenge data at the appropriate indexes
  workoutArr.forEach((item, index) => {
    if (challengeWorkoutFlowComponentTypes.includes(item.type) && item.apiName) {
      const challengeDataArray = challengeMap.get(item.apiName);
      if (challengeDataArray && challengeDataArray.length > 0) {
        mergedExercises[index] = challengeDataArray.shift(); // Place challenge data at the corresponding index
      }
    }
  });

  return mergedExercises;
};
