import { createSlice } from "@reduxjs/toolkit";
import { BE_AWS_URL } from "../../api/index.js";
import xhrRequest from "../../api/xhrRequest.js";
import persistReducer from "redux-persist/es/persistReducer.js";
import storage from "redux-persist/lib/storage";
// Reducer

const initialState = {
  kcalBurned: 0,
  score: 0,
  workoutName: undefined,
  flow: undefined,
  token: undefined,
  tokenLoading: undefined,
  timeLeft: undefined,
  timePassed: undefined,
  currentEventIndex: 0,
  workoutId: undefined,
  sessionId: undefined,
};

const workoutSlice = createSlice({
  name: "workout",
  initialState: initialState,
  reducers: {
    setWorkoutName: (state, action) => {
      state.workoutName = action.payload;
    },

    setKcalBurned: (state, action) => {
      state.kcalBurned = action.payload;
    },
    setWorkoutScore: (state, action) => {
      state.score = action.payload;
    },

    setWorkoutFlow: (state, action) => {
      state.flow = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },
    setTokenLoading: (state, action) => {
      state.tokenLoading = action.payload;
    },
    setCurrentEventIndex: (state, action) => {
      state.currentEventIndex = action.payload;
    },
    incrementCurrentEventIndex: (state) => {
      // Increment the currentEventIndex by 1
      state.currentEventIndex += 1;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setWorkoutId: (state, action) => {
      state.workoutId = action.payload;
    },

    resetAndSetIds: (state, action) => {
      state.currentEventIndex = initialState.currentEventIndex; // Reset
      state.sessionId = action.payload.sessionId;
      state.workoutId = action.payload.workoutId;
    },
    resetWorkout: (state, action) => {
      state.currentEventIndex = initialState.currentEventIndex;
      state.sessionId = initialState.sessionId;
      state.workoutId = initialState.workoutId;
    },
    resetState: (state) => (state = initialState),
  },
});

export const {
  setKcalBurned,
  setWorkoutScore,
  resetState,
  setWorkoutName,
  setWorkoutFlow,
  setToken,
  setTokenLoading,
  setCurrentEventIndex,
  incrementCurrentEventIndex,
  setSessionId,
  setWorkoutId,
  resetAndSetIds,
  resetWorkout,
} = workoutSlice.actions;

// Thunk

export const getExercisesAsync =
  ({ workoutId }) =>
  async (dispatch) => {
    try {
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/getExercisesOfWorkout?workoutId=${workoutId}`,
        tryAuth: false,
      });
      dispatch(setWorkoutFlow(response?.data));
    } catch (error) {
      console.error(error);
    }
  };

export const getJWTAsync = (clientName, enteredName, email) => async (dispatch) => {
  try {
    dispatch(setTokenLoading(true));
    const response = await xhrRequest({
      url: `${BE_AWS_URL}/issuetoken`,
      method: "POST",
      data: {
        clientName: clientName,
        name: enteredName,
        email: email,
        userInfo: { height: 172, weight: 70 },
      },
    });
    dispatch(setTokenLoading(false));
    dispatch(setToken(response?.data?.body?.userToken));
  } catch (error) {
    dispatch(setTokenLoading(false));
    console.error(error);
  }
};

// Selectors
export const selectWorkoutName = (state) => state.fit.workout.workoutName;
export const selectWorkoutKcalBurned = (state) => state.fit.workout.kcalBurned;
export const selectWorkoutScore = (state) => state.fit.workout.score;
export const selectWorkoutFlow = (state) => state.fit.workout.flow;
export const selectToken = (state) => state.fit.workout.token;
export const selectTokenLoading = (state) => state.fit.workout.tokenLoading;
export const selectCurrentEventIndex = (state) => state.fit.workout.currentEventIndex;
export const selectWorkoutId = (state) => state.fit.workout.workoutId;
export const selectSessionId = (state) => state.fit.workout.sessionId;

const workoutReducer = persistReducer(
  {
    key: "workoutData",
    whitelist: ["currentEventIndex", "sessionId", "workoutId"],
    storage,
  },
  workoutSlice.reducer
);

export default workoutReducer;
