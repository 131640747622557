import { combineReducers } from "@reduxjs/toolkit";
import quizReducer from "./quiz";
import selectionReducer from "./selection";
import summaryReducer from "./summary";
import workoutReducer from "./workout";
import dashboardReducer from "./dashboard";
import accountReducer from "./account";
import errorReducer from "./error";
import volumeReducer from "./volume";
import challengeReducer from "./challenge";

const fitReducer = combineReducers({
  summary: summaryReducer,
  workout: workoutReducer,
  dashboard: dashboardReducer,
  selection: selectionReducer,
  quiz: quizReducer,
  account: accountReducer,
  errors: errorReducer,
  volume: volumeReducer,
  challenge: challengeReducer,
});

export default fitReducer;
