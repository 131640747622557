import { Authenticator } from "@aws-amplify/ui-react";
import "./styles.css";

import AmplifyThemeProvider from "../AmplifyThemeProvider";
import { getCompanyNameFromUrl } from "../../utils/urls";
import clients from "../../constants/clients";
import Button from "../Buttons/Button";

const formFields = {
  signUp: {
    name: {
      isRequired: true,
    },
  },
};

const Auth = () => {
  const clientName = getCompanyNameFromUrl();

  const { ssoLink, title } = clients(clientName);

  if (ssoLink) {
    return (
      <Button size="large" to={ssoLink}>
        Login on {title} website
      </Button>
    );
  }

  return (
    <AmplifyThemeProvider>
      <Authenticator
        formFields={formFields}
        loginMechanisms={["email"]}
        signUpAttributes={["name"]}
      />
    </AmplifyThemeProvider>
  );
};

export default Auth;
