import { styled } from "@mui/material";
import Button from "../../../../components/Buttons/Button";
import { NativeBridge } from "../../../../containers/Mirror/WorkoutScreen/MobileCommunication";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { resetState } from "../../../../store/fit/workout";
import ReactGA from "react-ga4";
import { resetChallenge } from "../../../../store/fit/challenge";
import { useContext } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 80px;
  top: 20px;
  right: 20px;
  position: absolute;
  background-color: black;
  z-index: 100;
`;

const nativeBridge = new NativeBridge();

const ReactionClubExit = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { challengeMode } = useContext(WorkoutContext);
  const suppressFinish = searchParams.get("suppressFinish");
  const challengeId = searchParams.get("challenge");
  const { appType, clientId, sessionId } = useParams();
  const dispatch = useDispatch();
  ///// ending session
  const endWorkout = () => {
    ReactGA.event({
      category: "workout_end",
      action: "normal_workout_end",
      label: clientId, // optional
    });

    if (suppressFinish === "true") {
      nativeBridge.closeRequested();
    } else {
      dispatch(resetState());
      dispatch(resetChallenge());

      endSessionInParent();
      navigate(
        `/${appType}/${clientId}/summary/${sessionId}${
          challengeMode ? `?challenge=${challengeId}` : ""
        }`
      );
    }
  };

  /// message to iframe parent
  const endSessionInParent = () => {
    window?.parent?.postMessage("sessionEnd", "*");
  };

  return (
    <StyledButton data-testid="reactionExitButtonTest" onClick={endWorkout}>
      DONE
    </StyledButton>
  );
};

export default ReactionClubExit;
