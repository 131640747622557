import { createSlice } from "@reduxjs/toolkit";

const errorSclice = createSlice({
  name: "errors",
  initialState: {
    showError: false,
    errorMessage: "",
  },
  reducers: {
    setShowError: (state, action) => {
      state.showError = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setShowError, setErrorMessage } = errorSclice.actions;

//Thunk

//Selectors

export const selectShowError = (state) => state?.fit?.errors?.showError;
export const selectErrorMessage = (state) => state?.fit?.errors?.errorMessage;

const errorReducer = errorSclice.reducer;

export default errorReducer;
