import { Skeleton } from "@mui/material";
import styled from "styled-components";

const MetricTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;

  @media (min-width: 600px) {
    margin-left: 24px;
  }
`;

const AchievementSkeleton = () => {
  return (
    <MetricTextContainer>
      <Skeleton style={{ height: 36, width: 50 }}></Skeleton>
      <Skeleton style={{ height: 20, width: 30 }}></Skeleton>
    </MetricTextContainer>
  );
};

export default AchievementSkeleton;
