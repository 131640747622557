import { useState, useEffect } from "react";

const useVideoPlayer = (videoElement) => {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    playing ? videoElement.current?.play() : videoElement.current?.pause();
  }, [playing, videoElement]);

  return {
    playing,
    setPlaying,
  };
};

export default useVideoPlayer;
