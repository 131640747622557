const ContinueIcon = (props) => {
  return (
    <svg
      fill="#fff"
      width="35px"
      height="35px"
      viewBox="0 0 1920 1920"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M175 .024V1920l1570.845-959.927z" fillRule="evenodd" />
    </svg>
  );
};

export default ContinueIcon;
