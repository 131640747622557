import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import useWindowWidth from "../../../../../hooks/useWindowWidth";
import distributionChartData from "./distributionChartData.json";

const Wrapper = styled.div`
  width: 100%;
  height: 180px;

  @media (min-width: 600px) {
    height: 250px;
  }
`;

const CongratulationsChart = ({ inTopPercentage }) => {
  const greenData = [];
  const redData = [];
  distributionChartData.x.forEach((x) => {
    if (x <= inTopPercentage) {
      greenData.push(distributionChartData.y[x]);

      if (x === inTopPercentage) {
        redData.push(distributionChartData.y[x]);
      } else {
        redData.push(null);
      }
    } else {
      greenData.push(null);
      redData.push(distributionChartData.y[x]);
    }
  });

  const data = {
    xaxis: distributionChartData.x,
    series: [
      {
        name: "You are better than",
        data: greenData,
        // data: distributionChartData.y,
        // data: [10, 20, 30, 40, 50, 60, null, null, null, null],
      },
      {
        name: "More advanced",
        data: redData,

        // data: [null, null, null, null, null, 60, 30, 20, 20],
      },
    ],
  };

  const CHART_OPTIONS = {
    stroke: {
      curve: "smooth",
      colors: ["#00E0B5", "#E86161"],
      width: 3,
    },
    chart: {
      id: "congratulations_chart",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#00E0B5", "#E86161"],
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 0.1,
    //     opacityFrom: 0.2,
    //     opacityTo: 0.3,
    //   },
    // },
    // },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     type: "horizontal",
    //     shadeIntensity: 1,
    //     opacityFrom: 0.7,
    //     opacityTo: 0.9,
    //     stops: [0, 90, 100],
    //   },
    // },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     type: "horizontal",
    //     shadeIntensity: 1,
    //     opacityFrom: 0.4,
    //     opacityTo: 1,
    //     stops: [0, 100],
    //     colorStops: [
    //       {
    //         color: "#00E0B5",
    //         opacity: 0.3,
    //       },
    //       {
    //         color: "rgba(159, 26, 95, 0.8)",
    //         opacity: 0.3,
    //       },
    //     ],
    //   },
    // },
    xaxis: {
      categories: data.xaxis,
      tickAmount: 10,
      axisBorder: {
        show: false,
      },
      labels: {
        show: true,
        rotate: 0,
        style: {
          colors: "#fff",
        },
        // formatter: function (value) {
        //   return Math.ceil(value / 10) * 10;
        // },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      labels: {
        colors: "#fff",
        useSeriesColors: false,
      },
    },
    annotations: {
      xaxis: [
        {
          x: inTopPercentage,
          borderColor: "#999",
          yAxisIndex: 0,
          label: {
            show: true,
            text: "You!",
            style: {
              color: "#fff",
              background: "#2761E7",
            },
          },
        },
      ],
    },
  };

  const width = useWindowWidth();

  return (
    <Wrapper>
      <Chart
        type="area"
        height={width < 600 ? 180 : 250}
        options={CHART_OPTIONS}
        series={data.series}
      />
    </Wrapper>
  );
};

export default CongratulationsChart;
