import { LinearProgress, css, keyframes, styled } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import WorkoutContext from "../../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useTimer } from "react-timer-hook";
import { CONFIG } from "../../../../../../config";
import { getCompanyNameFromUrl } from "../../../../../../utils/urls";

const blinkAnimation = keyframes`
  0%, 100% {
    color: #54B862; /* Green */
  }
  50% {
    color: #FF0000; /* Red */
  }
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 100%;
  height: 25px;
  clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);
  color: #54b862;

  ${(props) =>
    props.animate &&
    css`
      animation: ${blinkAnimation} 1s ease-in-out infinite;
    `}
`;

let exerciseStarted = false;
let animate = false;

const StaticTimerProgress = ({ expiryTimestamp, startCountdown }) => {
  const {
    newScore,
    componentData,
    nextStageWorkout,
    setStaticStartTimer,
    setTimeLeft,
    newScoreTimestamp,
  } = useContext(WorkoutContext);
  const pauseTimeout = useRef(null);
  const companyName = getCompanyNameFromUrl();
  const { seconds, minutes, start, restart, pause, resume, isRunning } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: () => {
      nextStageWorkout(true);
      restart(expiryTimestamp);
    },
  });

  useEffect(() => setStaticStartTimer(isRunning), [isRunning]);

  useEffect(() => {
    if (newScore <= 0 || !newScore) return;
    if (newScore > 50) {
      if (!exerciseStarted) {
        exerciseStarted = true;
        start();
      } else if (!isRunning) resume();

      clearTimeout(pauseTimeout?.current);

      pauseTimeout.current = setTimeout(
        () => {
          pause();
        },
        companyName === "reaction-club" ? 3000 : 6000
      );
    }
  }, [newScoreTimestamp]);

  useEffect(() => {
    const secondsLeft = minutes * 60 + seconds;

    setTimeLeft(secondsLeft);
  }, [seconds, minutes]);

  animate = exerciseStarted === true && !isRunning;

  const totalTime = componentData?.duration - 60;

  return (
    <StyledLinearProgress
      variant="determinate"
      color="inherit"
      animate={animate}
      value={((totalTime - (minutes * 60 + seconds)) / totalTime) * 100}
    />
  );
};

export default StaticTimerProgress;
