import React from "react";
import styled from "styled-components";
import AccuracyChart from "./AccuracyChart";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background: #171d29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 24px 16px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
`;

const AccuracyCard = () => {
  return (
    <Wrapper>
      <Title>Accuracy chart</Title>
      <AccuracyChart />
    </Wrapper>
  );
};

export default AccuracyCard;
