import { styled } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectWorkoutsDashboard,
  selectWorkoutsDashboardLoading,
} from "../../../../store/fit/dashboard";
import AchievementSkeleton from "./AchievementSkeleton";
import { useEffect } from "react";
import { useState } from "react";

const Wrapper = styled("div")`
  margin-top: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  background: #171d29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 15px;
`;

const Title = styled("div")`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 40px;
  width: fit-content;
  text-align: center;
`;

const AchievementsContainer = styled("div")`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const StyledValues = styled("div")`
  font-size: 24px;
`;

const StyledNames = styled("div")`
  font-size: 20px;
`;

const AchievementWrapper = styled("div")`
  display: flex;
  gap: 20px;
  align-items: flex-end;
`;

const Achievement = () => {
  const workoutsDashboardLoading = useSelector(selectWorkoutsDashboardLoading);
  const [totalKcal, setTotalKcal] = useState();
  const [totalReps, setTotalReps] = useState();
  const summaries = useSelector(selectWorkoutsDashboard);

  useEffect(() => {
    if (!summaries) return;
    let allKcal = 0;
    let allReps = 0;

    summaries.forEach((summary) => {
      allKcal += parseInt(summary?.meta?.performanceBurnedKcal);
      if (summary?.meta?.performanceTotalReps)
        allReps += parseInt(summary?.meta?.performanceTotalReps);
    });
    setTotalKcal(allKcal);
    setTotalReps(allReps);
  }, [summaries]);

  return (
    <Wrapper>
      <Title>Total:</Title>
      <AchievementsContainer>
        <AchievementWrapper>
          {workoutsDashboardLoading ? (
            <AchievementSkeleton />
          ) : (
            <>
              <StyledValues>{totalKcal}</StyledValues>
              <StyledNames> kcal</StyledNames>
            </>
          )}
        </AchievementWrapper>
        <AchievementWrapper>
          {workoutsDashboardLoading ? (
            <AchievementSkeleton />
          ) : (
            <>
              <StyledValues>{totalReps}</StyledValues>
              <StyledNames>Repetitions</StyledNames>
            </>
          )}
        </AchievementWrapper>
      </AchievementsContainer>
    </Wrapper>
  );
};

export default Achievement;
