import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import xhrRequest from "../../../../../api/xhrRequest";
import Button from "../../../../../components/Buttons/Button";
import { BE_AWS_URL } from "../../../../../api";
import { devices } from "../../../../../config";
import {
  selectBodyParts,
  selectCookieId,
  selectEmail,
  selectEmailUsageAgreement,
  selectGender,
  selectHeight,
  selectRarity,
  selectWeight,
  setBodyPart,
} from "../../../../../store/fit/quiz";
import FormIcon from "./FormIcon";
import { Checkbox, styled } from "@mui/material";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BodyPartsWrapper = styled("div")`
  display: flex;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media ${devices.tablet} {
    max-width: 700px;
    gap: 30px;
  }

  @media ${devices.desktop} {
    max-width: 800px;
    gap: 40px;
  }
`;

const Card = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 180px;
  width: 47%;
  background: red;
  height: 9vw;
  min-height: 80px;
  background: radial-gradient(
    166.07% 166.07% at 47.39% 50%,
    rgba(217, 217, 217, 0.2) 0%,
    rgba(217, 217, 217, 0) 100%
  );

  @media ${devices.tablet} {
    max-width: 190px;
    max-height: 110px;
    border-radius: 15px;
  }

  @media ${devices.desktop} {
    max-width: 230px;
    max-height: 130px;
    border-radius: 20px;
  }
`;

const CardText = styled("span")`
  color: #ffffff;
  margin-top: auto;

  @media (max-width: 420px) {
    font-size: 12px;
  }
`;

const InputTextWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

const StyledButton = styled(Button)`
  max-width: 400px;
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0px;
`;

const ThirdForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appType, clientId } = useParams();
  const bodyParts = useSelector(selectBodyParts);
  const cookieId = useSelector(selectCookieId);
  const email = useSelector(selectEmail);
  const receiveMarketing = useSelector(selectEmailUsageAgreement);
  const workoutFrequency = useSelector(selectRarity);
  const height = useSelector(selectHeight);
  const weight = useSelector(selectWeight);
  const gender = useSelector(selectGender);

  const sendQuizData = async () => {
    try {
      xhrRequest({
        url: `${BE_AWS_URL}/questionnaire`,
        method: "POST",
        data: {
          cookieId: cookieId,
          meta: {
            email: email,
            receiveMarketing: receiveMarketing,
            workoutFrequency: workoutFrequency,
            height: height,
            weight: weight,
            gender: gender,
            companyName: clientId,
          },
        },
        tryAuth: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const bodyPartsIntoIndexes = (object) => {
    return Object.values(object).reduce(
      (out, bool, index) => (bool ? out.concat(index) : out),
      []
    );
  };

  const submitHandler = () => {
    sendQuizData();
    navigate(`/${appType}/${clientId}/selection`);
  };

  const bodyPartsInputHandler = (key) => {
    dispatch(setBodyPart({ key: key, value: !bodyParts[key] }));
  };

  return (
    <Wrapper>
      <BodyPartsWrapper>
        {Object.entries(bodyParts).map(([key, value]) => {
          return (
            <Card key={key} onClick={() => bodyPartsInputHandler(key)}>
              <InputTextWrapper>
                <StyledCheckbox checked={value} />
                <CardText>{key}</CardText>
              </InputTextWrapper>
              <FormIcon iconName={key} />
            </Card>
          );
        })}
      </BodyPartsWrapper>
      <StyledButton size="large" width="300px" onClick={submitHandler}>
        NEXT
      </StyledButton>
    </Wrapper>
  );
};

export default ThirdForm;
