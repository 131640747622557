import React, { useEffect } from "react";
import styled from "styled-components";
import WorkoutScreen from "./WorkoutScreen";
import ResultScreen from "./ResultScreen";
import SelectionScreen from "./SelectionScreen";
import { ROUTE } from "../../routing";
import { Route, Routes } from "react-router-dom";
import ResultsTipsScreen from "./ResultsTipsScreen";
import QuizScreen from "./QuizScreens";
import FittyCameraScreen from "./FittyCameraScreen";
import ContextComponent from "./WorkoutScreen/WorkoutContext/WorkoutContextComponent";
import Leaderboard from "./Leaderboard";
import ProtectedRoute from "../../components/ProtectedRoute";
import Dashboard from "./Dashboard";
import AuthLanding from "./AuthLanding";
import ExternalAuthLanding from "./ExternalAuthLanding";
import FittyWorkoutScreen from "./FittyWorkoutScreen";
import ErrorScreen from "./ErrorScreen";
import {
  MOBILE_TYPE,
  useMobileTypeDetector,
} from "../../hooks/useMobileTypeDetector";
import { useDispatch } from "react-redux";
import { setErrorMessage, setShowError } from "../../store/fit/error";
import { ERROR_MESSAGES } from "./ErrorScreen/errors";

const Wrapper = styled.div`
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Mirror = () => {
  const mobileType = useMobileTypeDetector();
  const dispatch = useDispatch();
  useEffect(() => {
    if (mobileType === MOBILE_TYPE.SAMSUNG) {
      dispatch(setShowError(true));
      dispatch(setErrorMessage(ERROR_MESSAGES.device.samsung));
    }
  }, [mobileType]);

  return (
    <ContextComponent>
      <Wrapper>
        <ErrorScreen />
        <Routes>
          <Route exact path={ROUTE.FIT_AUTH.PATH} element={<AuthLanding />} />
          <Route
            exact
            path={ROUTE.FIT_AUTH_EXTERNAL.PATH}
            element={<ExternalAuthLanding />}
          />
          <Route
            exact
            path={ROUTE.FIT_LEADERBOARD.PATH}
            element={
              <ProtectedRoute route={ROUTE.FIT_LEADERBOARD}>
                <Leaderboard />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.FIT_QUIZ.PATH}
            element={
              <ProtectedRoute route={ROUTE.FIT_QUIZ}>
                <QuizScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.FIT_SELECTION.PATH}
            element={
              <ProtectedRoute route={ROUTE.FIT_SELECTION}>
                <SelectionScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.FIT_WORKOUT.PATH}
            element={
              <ProtectedRoute route={ROUTE.FIT_WORKOUT}>
                <WorkoutScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.OLD_FIT_WORKOUT.PATH}
            element={
              <ProtectedRoute route={ROUTE.OLD_FIT_WORKOUT}>
                <WorkoutScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.FIT_SUMMARY.PATH}
            element={
              <ProtectedRoute route={ROUTE.FIT_SUMMARY}>
                <ResultScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.FIT_SUMMARY_TIPS.PATH}
            element={
              <ProtectedRoute route={ROUTE.FIT_SUMMARY_TIPS}>
                <ResultsTipsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.FIT_FITTY_CAMERA.PATH}
            element={
              <ProtectedRoute route={ROUTE.FIT_FITTY_CAMERA}>
                <FittyCameraScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.FIT_FITTY_WORKOUT.PATH}
            element={
              <ProtectedRoute route={ROUTE.FIT_FITTY_WORKOUT}>
                <FittyWorkoutScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.OLD_FIT_FITTY_WORKOUT.PATH}
            element={
              <ProtectedRoute route={ROUTE.OLD_FIT_FITTY_WORKOUT}>
                <FittyWorkoutScreen />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={ROUTE.FIT_DASHBOARD.PATH}
            element={<Dashboard />}
          />
        </Routes>
      </Wrapper>
    </ContextComponent>
  );
};

export default Mirror;
