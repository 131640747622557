import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { CircularProgress, styled } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { CONFIG } from "../../../../config";
import { playVideosInstantlyComponentTypes } from "../../../../components/WorkoutComponents/Trainer/TrainerConfig";
import { isMobile } from "../../../../utils/browserCheck";
import useWindowHeight from "../../../../hooks/useWindowHeight";
import { isIOS, isSafari } from "react-device-detect";

const Wrapper = styled("div")`
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  max-height: 700px;
  min-width: 200px;
`;

const TrainerEl = styled("video")`
  ${({ $isLoading }) => $isLoading && "display: none;"}
  max-height: 500px;
  min-width: 200px;
  filter: opacity(100);
  width: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(244, 107, 107, 0.006) 100%
  );
`;

const MobileWrapper = styled("div")`
  position: absolute;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 330px;
  z-index: 6;
  left: 50%;
  transform: translate(-50%, 0%);

  ${({ $isHorizontalMode }) => ($isHorizontalMode ? `bottom: 40px;` : `top: 0px;`)}
`;

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #a6d5fa;
  }
`;

const ReactionTrainer = () => {
  const screenWidth = useWindowWidth();
  const screenHeight = useWindowHeight();
  const { playSound, componentData, beginWorkout } = useContext(WorkoutContext);
  const [isLoading, setIsLoading] = useState(true);

  const videoUrl = componentData?.videoUrl;
  const videoUrlMov = componentData?.videoUrlMov;

  const startVideo =
    beginWorkout || playVideosInstantlyComponentTypes.includes(componentData?.type);

  const showMobileVideo = screenWidth < CONFIG.DesktopTrigger && startVideo;

  const mobile = isMobile();

  const isHorizontalMode = screenHeight < screenWidth && mobile;

  useEffect(() => {
    setIsLoading(true);
  }, [videoUrl, videoUrlMov]);

  if (showMobileVideo) {
    return (
      <MobileWrapper $isHorizontalMode={isHorizontalMode}>
        <TrainerEl
          key={videoUrl || videoUrlMov}
          data-testid={"workoutVideo"}
          playsInline={true}
          autoPlay
          muted={!playSound}
          loop
          $screenWidth={screenWidth}
          onLoadedData={() => {
            setIsLoading(false);
          }}
          $isLoading={isLoading}
        >
          {(isIOS || isSafari) && videoUrlMov ? (
            <source src={videoUrlMov} />
          ) : (
            <>
              <source src={videoUrl} />
              <source src={videoUrlMov} />
            </>
          )}
        </TrainerEl>
      </MobileWrapper>
    );
  }

  if (screenWidth >= CONFIG.DesktopTrigger)
    return (
      <Wrapper $screenWidth={screenWidth}>
        {startVideo && (
          <>
            {isLoading && <StyledCircularProgress />}
            <TrainerEl
              key={videoUrl || videoUrlMov}
              data-testid={"workoutVideo"}
              playsInline={true}
              autoPlay
              muted={!playSound}
              loop
              appType
              $screenWidth={screenWidth}
              onLoadedData={() => {
                setIsLoading(false);
              }}
              $isLoading={isLoading}
            >
              {(isIOS || isSafari) && videoUrlMov ? (
                <source src={videoUrlMov} />
              ) : (
                <>
                  <source src={videoUrl} />
                  <source src={videoUrlMov} />
                </>
              )}
            </TrainerEl>
          </>
        )}
      </Wrapper>
    );
  else return null;
};

export default ReactionTrainer;
