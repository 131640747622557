const ChestIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 912 352"
      enableBackground="new 0 0 912 352"
      fill="#fff"
      {...props}
    >
      <path
        opacity="1.000000"
        stroke="none"
        d=" M158.467941,353.000000   C132.917725,320.818420 106.586342,289.648743 80.980217,257.892609   C59.235176,230.924942 37.747559,203.737213 20.718836,173.390137   C11.090011,156.230484 4.385956,138.049896 1.982245,118.448730   C1.012644,110.542091 2.284269,102.837440 5.541235,95.535645   C6.469119,93.455429 7.195757,90.708961 9.851428,90.490898   C12.559872,90.268509 13.639691,92.981995 14.806036,94.879768   C21.347157,105.522942 27.373203,116.458847 33.227535,127.497536   C41.576351,143.239731 49.993607,158.932556 62.362049,172.095398   C70.684860,180.952774 80.684753,186.814240 92.110649,190.666580   C97.518463,192.489883 101.833549,191.618118 105.616829,187.395660   C113.951950,178.092957 122.268311,168.771698 130.730621,159.585419   C135.357758,154.562393 140.309891,149.839859 145.908905,145.876389   C147.806320,144.533218 149.767441,143.221619 151.856689,142.225342   C153.709976,141.341629 155.932022,139.596802 157.811554,141.528915   C159.451416,143.214645 157.945679,145.345230 157.146637,147.096832   C155.281311,151.185867 153.355484,155.255753 151.251541,159.226028   C141.370651,177.871887 133.671814,197.293915 130.817749,218.328049   C128.848679,232.840088 128.170959,247.285156 137.530579,260.042175   C138.416290,261.249390 139.190353,262.542664 140.123489,263.710297   C146.782928,272.043060 153.867722,272.684784 161.864853,265.489258   C168.761536,259.283813 173.668549,251.518417 178.241943,243.560989   C185.626038,230.713120 191.671829,217.122650 199.837540,204.702637   C202.506790,200.642700 205.508560,196.885284 209.009415,193.530869   C211.831802,190.826553 215.093262,188.878494 219.001450,188.206635   C223.647949,187.407852 225.436371,189.275803 223.848343,193.776947   C221.914627,199.257950 220.015366,204.845001 217.250168,209.922714   C204.535187,233.271149 197.374054,258.432281 191.820160,284.233887   C186.926758,306.967072 181.432266,329.570892 176.101593,352.615906   C170.311966,353.000000 164.623917,353.000000 158.467941,353.000000  z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d=" M765.458069,353.000000   C752.522095,322.601929 744.393982,290.844696 735.886902,259.219788   C730.921814,240.762085 722.562256,223.756424 714.589478,206.548233   C712.711548,202.494843 711.491760,198.127655 710.099243,193.862381   C709.510742,192.059586 709.549255,190.159027 711.203247,188.818344   C712.759766,187.556641 714.489502,188.320053 715.937622,188.984528   C724.457886,192.894043 729.869568,200.196793 735.195190,207.404297   C745.651672,221.555344 753.095215,237.328430 759.517456,253.651489   C764.584656,266.530457 772.226257,271.256134 785.722412,270.676575   C790.163269,270.485870 793.282715,268.678040 794.968018,264.415894   C801.964355,246.722885 806.466614,228.571793 803.491760,209.434906   C801.893677,199.154617 796.774231,189.974152 792.443726,180.626816   C787.406799,169.754517 782.017273,159.045929 776.923340,148.199326   C775.929565,146.083359 773.906128,143.405380 776.189026,141.372086   C778.218933,139.564163 780.977722,140.926682 783.240784,142.016113   C794.369690,147.373306 802.506714,156.115051 809.722168,165.808380   C815.981384,174.217026 822.186035,182.675369 828.102112,191.325592   C830.274658,194.502136 832.459961,193.365768 835.012451,192.597855   C859.373779,185.268539 874.757263,168.123276 886.058228,146.327347   C894.435608,130.169998 900.159424,112.950638 906.785522,96.087990   C908.366028,92.065598 910.045166,88.076622 912.754028,84.241089   C913.000000,111.354225 913.000000,138.708450 912.751587,166.760818   C903.811951,182.835495 894.486450,197.799850 883.354675,211.555511   C865.970093,233.037796 849.209045,254.986710 833.158203,277.487854   C818.567322,297.942413 804.403870,318.712921 788.434204,338.162079   C784.337402,343.151520 779.279297,347.334076 776.000000,353.000000   C772.638733,353.000000 769.277405,353.000000 765.458069,353.000000  z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d=" M826.750000,1.000000   C827.715027,2.328223 828.211853,3.687640 828.321960,5.242030   C830.812744,40.403801 822.203857,71.819191 797.762695,98.213234   C787.013428,109.821365 775.988708,121.152191 765.046387,132.568710   C763.130737,134.567337 760.858704,135.698303 758.276611,136.572662   C736.857605,143.825668 715.463379,151.152313 694.071106,158.484116   C692.358459,159.071106 690.715942,159.862839 688.628723,160.730774   C690.024963,164.964569 691.393738,168.998444 692.685364,173.056885   C695.025452,180.409378 693.639648,182.000839 686.216675,180.664185   C671.607361,178.033447 657.485229,173.544632 643.358337,169.162979   C612.216248,159.503769 581.242615,149.354568 550.840393,137.487885   C535.106506,131.346588 522.879089,120.885078 512.215454,108.324875   C496.611176,89.945137 484.567230,69.584618 479.393402,45.728848   C477.949677,39.072086 477.020874,32.286011 478.601318,25.438160   C479.190460,22.885365 479.013519,19.495621 482.239929,19.087887   C485.363068,18.693201 486.523438,21.866114 487.636841,24.221525   C490.561859,30.409637 493.125061,36.893078 494.019196,43.599995   C497.145477,67.050308 510.543762,82.427559 530.815369,93.096291   C553.626404,105.101440 577.182678,115.136627 602.747070,119.208611   C626.257568,122.953461 649.929443,124.419609 673.838745,122.922691   C707.450073,120.818344 737.985474,110.313133 765.601196,91.356163   C787.085815,76.607986 801.825684,56.374096 811.756897,32.425083   C816.074829,22.012459 818.870789,10.898637 825.817383,1.291114   C826.000000,1.000000 826.500000,1.000000 826.750000,1.000000  z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d=" M328.406586,157.498093   C302.109253,166.173859 276.081207,174.371506 249.528687,180.769241   C246.741989,181.440674 243.275436,183.488190 241.004364,181.171448   C238.602783,178.721558 241.441162,175.579620 242.067215,172.786514   C242.645752,170.205383 243.801697,167.757767 244.633545,165.228378   C246.098557,160.773697 245.071213,158.438873 240.520386,157.542923   C226.565491,154.795441 213.293945,149.837051 200.050262,144.934326   C185.577118,139.576462 170.613617,134.904846 159.108490,123.733093   C136.017029,101.310760 116.622604,76.368118 106.531143,45.270828   C103.385262,35.576618 101.560295,25.645006 103.564117,15.324614   C104.284920,11.612232 105.833885,8.396431 107.748260,5.269415   C109.629768,2.196082 111.642540,2.107604 113.026054,5.532411   C115.204933,10.926076 117.237717,16.389330 119.074463,21.908941   C132.148926,61.199028 157.374557,89.090134 195.208008,106.512451   C221.846771,118.779610 249.300629,123.698807 278.412506,123.988731   C317.814240,124.381157 355.151611,116.826881 390.453735,99.505875   C405.142578,92.298790 419.169067,84.123566 429.245819,70.441406   C435.883606,61.428631 438.702545,51.124161 441.648956,40.716537   C443.378906,34.605793 445.904205,28.804285 449.207886,23.367968   C450.061432,21.963436 450.937744,20.315212 452.941467,20.507175   C455.157715,20.719501 455.876282,22.517551 456.274933,24.356707   C458.356812,33.961830 458.021942,43.507671 455.303802,52.944763   C450.274323,70.406723 440.736725,85.406258 429.308655,99.228989   C416.814423,114.341324 403.021759,128.203445 384.964722,136.612656   C366.835663,145.055420 347.799927,151.233932 328.406586,157.498093  z"
      />
    </svg>
  );
};

export default ChestIcon;
