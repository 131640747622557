import { Skeleton } from "@mui/material";
import styled from "styled-components";

const MetricTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 600px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-left: 24px;
  }
`;

const PerformanceSkeleton = () => {
  return (
    <>
      <Skeleton variant="circular" style={{ height: 50, width: 50 }}></Skeleton>
      <MetricTextContainer>
        <Skeleton style={{ height: 15, width: 65 }}></Skeleton>
        <Skeleton style={{ height: 20, width: 45 }}></Skeleton>
      </MetricTextContainer>
    </>
  );
};

export default PerformanceSkeleton;
