import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import Button from "../../../../../../components/Buttons/Button";
import LoginButton from "../LoginButton";
import { useParams } from "react-router-dom";
import useAuth from "../../../../../../hooks/useAuth";

const MenuStyledButton = styled(Button)`
  white-space: nowrap;
  height: min-content;
  margin-top: 40px;
`;

const StyledBox = styled(Box)`
  background-color: #171d29;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  height: -webkit-fill-available;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const MenuBottomDiv = styled("div")`
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledBottomList = styled(List)`
  margin-left: 20px;
  margin-right: 20px;
`;

const MenuPopup = ({ handleMenuState }) => {
  const { appType, clientId } = useParams();

  const { status } = useAuth();

  return (
    // <Menu
    //   elevation={0}
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "right",
    //   }}
    //   transformOrigin={{
    //     vertical: "top",
    //     horizontal: "right",
    //   }}
    //   id="menu-button"
    //   aria-labelledby="menu-button"
    //   anchorEl={anchorEl}
    //   open={open}
    //   onClose={handleClose}
    // >
    //   {status === "authenticated" && (
    //     <MenuItem onClick={handleClose}>
    //       <MenuStyledButton to={`/${appType}/${clientId}/dashboard`}>
    //         My Dashboard
    //       </MenuStyledButton>
    //     </MenuItem>
    //   )}

    //   <MenuItem onClick={handleClose}>
    //     <LoginButton />
    //   </MenuItem>
    // </Menu>

    <StyledBox
      sx={{ paper: { background: "#171d29" } }}
      role="presentation"
      // onClick={handleMenuState(false)}
      // onKeyDown={handleMenuState(false)}
    >
      <List>
        {status === "authenticated" && (
          <MenuStyledButton to={`/${appType}/${clientId}/dashboard`}>
            My Dashboard
          </MenuStyledButton>
        )}
      </List>
      <MenuBottomDiv>
        <Divider />
        <StyledBottomList>
          <LoginButton />
        </StyledBottomList>
      </MenuBottomDiv>
    </StyledBox>
  );
};
export default MenuPopup;
