import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material";
import { useContext } from "react";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { selectWorkoutScore } from "../../../../store/fit/workout";
import { selectChallengeScore } from "../../../../store/fit/challenge";

const Wrapper = styled("div")`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
`;
// align-items: center;
// flex: 1 1 0px;

const InnerWrapper = styled("div")`
  display: flex;

  flex-direction: column;
  align-items: center;
`;

const StatisticsText = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StatisticsNumber = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const NewScoreNumber = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Score = () => {
  const { newScore, setNewScore, newScoreTimestamp, challengeMode } =
    useContext(WorkoutContext);
  const [showNewScore, setShowNewScore] = useState(false);
  const workoutScore = useSelector(selectWorkoutScore);
  const challengeScore = useSelector(selectChallengeScore);

  const timeout = useRef(null);

  useEffect(() => {
    if (newScore <= 0 || !newScore) return;

    timeout.current = setShowNewScore(true);
    setTimeout(() => {
      setShowNewScore(false);
    }, 900);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [newScoreTimestamp]);

  if (challengeMode)
    return (
      <Wrapper>
        <InnerWrapper>
          <StatisticsText>Challenger</StatisticsText>
          <StatisticsNumber>{challengeScore}</StatisticsNumber>
        </InnerWrapper>
      </Wrapper>
    );

  return (
    <Wrapper>
      {showNewScore && (
        <InnerWrapper>
          <NewScoreNumber>+{Math.floor(newScore)}</NewScoreNumber>
        </InnerWrapper>
      )}
      {!showNewScore && (
        <InnerWrapper>
          <StatisticsText>Score</StatisticsText>
          <StatisticsNumber>{workoutScore}</StatisticsNumber>
        </InnerWrapper>
      )}
    </Wrapper>
  );
};

export default Score;
