import { createSlice } from "@reduxjs/toolkit";
import { BE_AWS_URL } from "../../api";
import xhrRequest from "../../api/xhrRequest";

// Reducer

const summarySlice = createSlice({
  name: "summary",
  initialState: {
    workoutSummary: undefined,
    workoutSummaryLoading: undefined,
    feedback: {
      loading: false,
      status: undefined,
    },
    leaderboard: undefined,
    leaderboardLoading: undefined,
    radarChart: undefined,
    radarChartLoading: undefined,
  },
  reducers: {
    setLeaderboard: (state, action) => {
      state.leaderboard = action.payload;
    },
    setLeaderboardLoading: (state, action) => {
      state.leaderboardLoading = action.payload;
    },
    setWorkoutSummary: (state, action) => {
      state.workoutSummary = action.payload;
    },
    setWorkoutSummaryLoading: (state, action) => {
      state.workoutSummaryLoading = action.payload;
    },
    setFeedbackLoading: (state, action) => {
      state.feedback.loading = action.payload;
    },
    setFeedbackStatus: (state, action) => {
      state.feedback.status = action.payload;
    },
    setRadarChart: (state, action) => {
      state.radarChart = action.payload;
    },
    setRadarChartLoading: (state, action) => {
      state.radarChartLoading = action.payload;
    },
  },
});

export const {
  setLeaderboard,
  setLeaderboardLoading,
  setWorkoutSummary,
  setWorkoutSummaryLoading,
  setFeedbackLoading,
  setFeedbackStatus,
  setRadarChart,
  setRadarChartLoading,
} = summarySlice.actions;

// Thunk

export const getWorkoutSummaryAsync =
  ({ sessionId, clientId, username, name }) =>
  async (dispatch) => {
    try {
      dispatch(setWorkoutSummaryLoading(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/getWorkoutSummaryReport?sessionId=${sessionId}&companyName=${clientId}&username=${username}&name=${name}`,
        tryAuth: false,
      });
      if (response?.data) {
        dispatch(setWorkoutSummary(response?.data));
      }
      dispatch(setWorkoutSummaryLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setWorkoutSummaryLoading(false));
    }
  };

export const sendFeedbackAsync =
  ({ rating, comment, sessionId }) =>
  async (dispatch) => {
    try {
      dispatch(setFeedbackLoading(true));
      const res = await xhrRequest({
        url: `${BE_AWS_URL}/feedback`,
        data: {
          rating,
          comment,
          sessionId,
          area: "workout-summary",
        },
        tryAuth: false,
        method: "POST",
      });
      if (res?.data?.status) {
        dispatch(setFeedbackStatus(res?.data?.status));
      } else {
        dispatch(setFeedbackStatus("error"));
      }
      dispatch(setFeedbackLoading(false));
    } catch (error) {
      dispatch(setFeedbackStatus("error"));
      dispatch(setFeedbackLoading(false));
    }
  };

export const getLeaderboardAsync =
  ({ sessionId, username }) =>
  async (dispatch) => {
    try {
      dispatch(setLeaderboardLoading(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/leaderboard?sessionId=${sessionId}&username=${username}`,
        tryAuth: false,
      });
      if (response?.data) {
        dispatch(setLeaderboard(response?.data));
      }
      dispatch(setLeaderboardLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setLeaderboardLoading(false));
    }
  };

export const getRadarChartDataAsync =
  ({ sessionId, clientId, username, name }) =>
  async (dispatch) => {
    try {
      dispatch(setRadarChartLoading(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/getChartRadarData?sessionId=${sessionId}&companyName=${clientId}&username=${username}&name=${name}`,
        tryAuth: false,
      });
      if (response?.data) {
        dispatch(setRadarChart(response?.data));
      }
      dispatch(setRadarChartLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setRadarChart({ displayChart: false }));
      dispatch(setRadarChartLoading(false));
    }
  };

// Selectors

export const selectLeaderboard = (state) => state?.fit?.summary?.leaderboard;
export const selectLeaderboardLoading = (state) =>
  state?.fit?.summary?.leaderboardLoading;
export const selectWorkoutSummary = (state) => state.fit.summary.workoutSummary;
export const selectWorkoutSummaryLoading = (state) =>
  state.fit.summary.workoutSummaryLoading;
export const selectSendFeedbackLoading = (state) =>
  state.fit.summary.feedback.loading;
export const selectFeedbackStatus = (state) =>
  state.fit.summary.feedback.status;
export const selectRadarChart = (state) => state.fit.summary?.radarChart;
export const selectRadarChartLoading = (state) =>
  state.fit.summary?.radarChartLoading;

const summaryReducer = summarySlice.reducer;

export default summaryReducer;
