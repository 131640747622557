const PauseIcon = (props) => {
  return (
    <svg
      fill="#fff"
      height="35px"
      width="35px"
      version="1.1"
      id="Filled_Icons"
      x="0px"
      y="0px"
      viewBox="0 0 24.00 24.00"
      enableBackground="new 0 0 24 24"
      stroke="#fff"
      strokeWidth="0.00024000000000000003"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g id="Pause--Filled">
          {" "}
          <path d="M15,22V2h5v20H15z M9,22V2H4v20H9z"></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default PauseIcon;
