import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectWorkoutScore } from "../../../../store/fit/workout";

const ProgressContainer = styled.div`
  color: #7cbb7e;
  position: relative;
  min-width: 61px;
  min-height: 63px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(110, 244, 107, 0.3) 0%,
    rgba(244, 107, 107, 0.006) 100%
  );
`;

const ScoreContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreText = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
`;

const Count = () => {
  const workoutScore = useSelector(selectWorkoutScore);

  return (
    <ProgressContainer>
      <ScoreContainer>
        <ScoreText>{workoutScore}</ScoreText>
      </ScoreContainer>
    </ProgressContainer>
  );
};

export default Count;
