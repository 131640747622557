import { createSlice } from "@reduxjs/toolkit";
import xhrRequest from "../../api/xhrRequest";
import { BE_AWS_URL } from "../../api";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    workoutsDashboard: undefined,
    workoutsDashboardLoading: undefined,
  },
  reducers: {
    setWorkoutsDashboard: (state, action) => {
      state.workoutsDashboard = action.payload;
    },
    setWorkoutsDashboardLoading: (state, action) => {
      state.workoutsDashboardLoading = action.payload;
    },
  },
});

export const { setWorkoutsDashboard, setWorkoutsDashboardLoading } =
  dashboardSlice.actions;

//Thunk

export const getWorkoutsDashboardAsync = (username) => async (dispatch) => {
  try {
    dispatch(setWorkoutsDashboardLoading(true));
    const response = await xhrRequest({
      url: `${BE_AWS_URL}/workouthistory/${username}`,
    });
    const summaries = response.data.summaries;
    summaries.sort((a, b) => a.timestamp - b.timestamp);
    dispatch(setWorkoutsDashboard(summaries));
    dispatch(setWorkoutsDashboardLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setWorkoutsDashboardLoading(false));
  }
};

export const selectWorkoutsDashboard = (state) =>
  state?.fit?.dashboard?.workoutsDashboard;
export const selectWorkoutsDashboardLoading = (state) =>
  state?.fit?.dashboard?.workoutsDashboardLoading;

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;
