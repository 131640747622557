import styled from "styled-components";
import Count from "./Count";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 30px;
  left: 30px;
  flex-direction: column;
  z-index: 3;

  @media (min-width: 500px) {
    transform: scale(1.8);
    top: 50px;
    left: 50px;
  }

  @media (min-width: 700px) {
    transform: scale(2.3);
    top: 70px;
    left: 80px;
  }

  @media (min-width: 1000px) {
    transform: scale(2.7);
    top: 82px;
    left: 100px;
  }
`;

const ScoreContainer = styled.div`
  min-width: 57px;
  min-height: 12px;
  background: rgba(140, 140, 140, 0.4);
  backdrop-filter: blur(124px);
  border-radius: 40px;
  padding: 4px;
`;

const ScoreText = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  text-transform: capitalize;
  overflow-wrap: normal;
  max-width: 100px;
  color: #d1ffd8;
`;

const CountWrapper = styled.div`
  display: flex;
  margin: 0 auto;
`;

const CornerScore = () => {
  return (
    <Wrapper>
      <CountWrapper>
        <Count />
      </CountWrapper>
      <ScoreContainer>
        <ScoreText>Score</ScoreText>
      </ScoreContainer>
    </Wrapper>
  );
};

export default CornerScore;
