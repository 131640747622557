import { CircularProgress, Skeleton, styled } from "@mui/material";

const LoaderWrapper = styled("div")`
  display: flex;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
`;

const DashboardSkeleton = () => {
  return (
    <>
      <Skeleton style={{ width: 140, height: 20, marginBottom: 8 }} />
      <Skeleton
        style={{ width: 130, height: 15, marginBottom: 14, marginTop: 12 }}
      />
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
      <Skeleton
        style={{ height: 30, width: 270, marginTop: 12, marginBottom: 14 }}
      />
    </>
  );
};

export default DashboardSkeleton;
