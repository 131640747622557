import React from "react";

import AuthAccessScreen from "../AuthAccessScreen";
import clients from "../../constants/clients";
import { getCompanyNameFromUrl } from "../../utils/urls";
import useAuth from "../../hooks/useAuth";

export default function ProtectedRoute({
  children,
  route: { ID, PATH, TITLE },
}) {
  const { status } = useAuth();
  const clientName = getCompanyNameFromUrl();

  const { requireAuth, protectedRoutes } = clients(clientName);

  return status === "authenticated" ||
    !requireAuth ||
    (requireAuth && !protectedRoutes.some((x) => x === ID)) ? (
    children
  ) : (
    <AuthAccessScreen title={`To access ${TITLE} please login`} />
  );
}
