import { CircularProgress, keyframes, Slide, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGettingUrl,
  selectUploading,
  setGettingUrl,
  setUploading,
} from "../../../../store/fit/account";

const Card = styled("div")`
  height: 100vh; /* 100% of the viewport height */
  width: 100vw; /* 100% of the viewport width */
  position: fixed;
  gap: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 110;
  padding: 10px;
  top: 0;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(107, 116, 139) 150.91%);
`;

const revealFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #dbeefd;
  }
`;

const StyledTopText = styled("h2")`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
  animation: ${revealFromBottom} 0.5s ease-out forwards;
`;

const FinishingUploadScreen = () => {
  const uploading = useSelector(selectUploading);
  const gettingUrl = useSelector(selectGettingUrl);
  return (
    <Slide
      in={uploading || gettingUrl}
      direction="down"
      mountOnEnter
      unmountOnExit
      appear={false} // Disable the entrance animation
    >
      <Card>
        <StyledCircularProgress />
        <StyledTopText>
          {gettingUrl && <>Starting upload..</>}
          {uploading && <>Uploading..</>}
        </StyledTopText>
      </Card>
    </Slide>
  );
};

export default FinishingUploadScreen;
