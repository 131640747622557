import { useContext, useEffect } from "react";
import { useStopwatch } from "react-timer-hook";
import WorkoutContext from "../../../WorkoutContext";
import { useDispatch, useSelector } from "react-redux";
import { selectShowError } from "../../../../../../store/fit/error";
import { selectCurrentEventIndex } from "../../../../../../store/fit/workout";
import { challengeExerciseWorkoutFlowComponentTypes } from "./challengeFlowConfig";
import {
  resetExerciseData,
  selectChallengeRepIndex,
  incrementChallengeRepIndex,
  setChallengeRepCount,
  setChallengeScore,
  selectModifiedChallengeData,
} from "../../../../../../store/fit/challenge";

const ChallengeFlow = () => {
  const { startTimer, componentData } = useContext(WorkoutContext);
  const currentEventIndex = useSelector(selectCurrentEventIndex);
  const challengeRepIndex = useSelector(selectChallengeRepIndex);
  const showError = useSelector(selectShowError);
  const challengeData = useSelector(selectModifiedChallengeData);
  const { seconds, minutes, start, reset, isRunning } = useStopwatch({
    autoStart: false,
  });

  const dispatch = useDispatch();

  const msToSec = (milliseconds) => {
    return milliseconds / 1000;
  };

  useEffect(() => {
    dispatch(resetExerciseData());
    reset();
  }, [currentEventIndex]);

  useEffect(() => {
    if (!challengeData) return;
    if (!challengeExerciseWorkoutFlowComponentTypes.includes(componentData?.type)) return;

    const timeValue = challengeData?.[currentEventIndex]?.[challengeRepIndex]?.time;
    const challengeCurrentRepComponent =
      challengeData?.[currentEventIndex]?.[challengeRepIndex];
    const convertedSecs = timeValue !== undefined ? msToSec(timeValue) : null;
    if (convertedSecs < seconds) {
      if (challengeCurrentRepComponent) {
        dispatch(setChallengeRepCount(challengeCurrentRepComponent?.rep_count));
        dispatch(setChallengeScore(challengeCurrentRepComponent?.total_score));
      }

      dispatch(incrementChallengeRepIndex());
    }
  }, [seconds]);

  useEffect(() => {
    if (startTimer && !showError) start();
  }, [startTimer, showError]);

  return null;
};

export default ChallengeFlow;
