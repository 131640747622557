import WorkoutFlow from "../WorkoutScreen/NewLogic/WorkoutFlow";
import SoundContextComponent from "../WorkoutScreen/SoundContext/SoundContextComponent";
import WebsocketContextComponent from "../WorkoutScreen/WebsocketContext/WebsocketContextComponent";
import WorkoutContextComponent from "../WorkoutScreen/WorkoutContext/WorkoutContextComponent";

const FittyWorkoutScreen = () => {
  return (
    <WorkoutContextComponent>
      <WebsocketContextComponent>
        <SoundContextComponent>
          <WorkoutFlow fittyWorkout={true} />
        </SoundContextComponent>
      </WebsocketContextComponent>
    </WorkoutContextComponent>
  );
};

export default FittyWorkoutScreen;
