import { clientNamesToIgnoreNewUI } from "../../../constants/clients";
import { useParams } from "react-router";
import Kcal from "./Kcal";
import { styled } from "@mui/material";
import Timer from "./Timer";
import Score from "./Score";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { getCompanyNameFromUrl } from "../../../utils/urls";
import { CONFIG } from "../../../config";

import { useEffect } from "react";
import { useRef } from "react";
import WorkoutContext from "../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useContext } from "react";
import { useState } from "react";
import MobileStats from "./MobileStats";

const Wrapper = styled("div")`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 40px;
  left: 0;

  @media (max-width: 540px) {
    height: 10vh;
    // bottom: unset;
    position: fixed;
    // top: calc(50% - 5vh);

    // delete code below to make controls horizontal
    // height: fit-content;
    // width: 60px;
    // right: 15px;
    // top: calc(50% - 235px / 2);
    // top: 15%;
    // height: 235px;
  }
`;

const StatisticsContainer = styled("div")`
  background: black;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  backdrop-filter: blur(25px);
  background-color: rgba(0, 0, 0, 0.3);
  position: abosolute;
  border-radius: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 300px;
  min-height: 73px;
  transform: scale(1.6);

  @media (max-width: 540px) {
    padding-top: 13px;
    padding-bottom: 10px;
    transform: none;
    min-height: 83px;
    width: 90%;
    max-width: 300px;

    // delete code below to make controls horizontal
    // flex-direction: column;
    // min-height: 83px;
    // width: fit-content;
    // border-radius: 25px;
    // min-width: 75px;
  }
`;

const clientStatsComponentMap = {
  "reaction-club": null,
};

const Stats = () => {
  const { clientId } = useParams();
  const { startTimer, componentData } = useContext(WorkoutContext);
  const [startCountdown, setStartCountdown] = useState(false);
  const [startStopwatch, setStartStopwatch] = useState();

  const expiryTimestamp = useRef(null);
  const staticExpiryTimestamp = useRef(null);
  const width = useWindowWidth();
  const clientName = getCompanyNameFromUrl();

  /////Timer timestamp, for it to not reset after style change
  useEffect(() => {
    setStartCountdown(startTimer);
    if (startTimer) {
      const time = new Date();
      const staticTime = new Date();
      time.setSeconds(time.getSeconds() + componentData?.duration);
      staticTime.setSeconds(
        staticTime.getSeconds() +
          (componentData?.duration - CONFIG.staticExerciseTimeMinus)
      );

      expiryTimestamp.current = time;
      staticExpiryTimestamp.current = staticTime;
    }
  }, [startTimer]);

  if (clientName in clientStatsComponentMap) return clientStatsComponentMap[clientName];

  if (width < CONFIG.MobileBreakpoint && !clientNamesToIgnoreNewUI.includes(clientId))
    return (
      <MobileStats
        expiryTimestamp={expiryTimestamp.current}
        staticExpiryTimestamp={staticExpiryTimestamp.current}
        startCountdown={startCountdown}
      />
    );

  return (
    <Wrapper data-testid="exerciseStatsTest">
      <StatisticsContainer>
        <Kcal />
        <Timer
          startCountdown={startCountdown}
          data-testid="exerciseTimerTest"
          expiryTimestamp={expiryTimestamp.current}
        />
        <Score />
      </StatisticsContainer>
    </Wrapper>
  );
};

export default Stats;
