import { CONFIG } from "../../../../config";

export const elementsToShowInList = [
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.exercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.officeStaticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.staticExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.enduranceExercise,
  CONFIG.WORKOUTFLOW_COMPONENT_TYPES.break,
];
