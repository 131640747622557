import { Button, styled } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import SmartLink from "../../../../components/Primitives/SmartLink";

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  min-width: 120px;
`;

const TopNav = () => {
  const { appType, sessionId, clientId } = useParams();

  return (
    <Wrapper>
      <StyledButton
        component={SmartLink}
        to={`/${appType}/${clientId}/selection`}
        variant="outlined"
      >
        return
      </StyledButton>
    </Wrapper>
  );
};

export default TopNav;
