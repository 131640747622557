import { styled } from "@mui/material";
import Button from "../../components/Buttons/Button";
import { useEffect, useRef, useState } from "react";

const ErrorFallbackComponent = ({ error, resetErrorBoundary }) => {
  const [count, setCount] = useState(10);
  const timeout = useRef(null);
  const secondInMiliseconds = 1000;

  useEffect(() => {
    if (count === 0) resetErrorBoundary();
    timeout.current = setTimeout(() => {
      setCount((count) => {
        return count - 1;
      });
    }, secondInMiliseconds);
    return () => {
      clearTimeout(timeout.current);
    };
  }, [count]);

  return (
    <Card>
      <Wrapper>
        <IconWrapper></IconWrapper>
        <StyledText>{"An error has occurred :("}</StyledText>
        <ErrMsg>{error?.message}</ErrMsg>
        <ButtonWrapper>
          <StyledButton onClick={resetErrorBoundary}>
            Reload UI {count}
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    </Card>
  );
};
export default ErrorFallbackComponent;

const Card = styled("div")`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 110;
  padding: 10px;
  top: 0;
  background-color: black;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  justify-content: center;
  // margin-top: 40px;
  gap: 40px;
`;

const StyledText = styled("h2")`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  text-align: center;
  margin-top: 40px;
`;

const IconWrapper = styled("div")`
  max-width: 150px;
  margin: 0 auto;
`;

const ErrMsg = styled("p")`
  margin-top: 20px;
  color: white;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 140px;
  background-color: black;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;
