import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, styled } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  checkPasswordAsync,
  resetPasswordState,
  selectAuthStatus,
  selectCheckingPassLoading,
  selectPasswordErrMessage,
  selectSessionId,
  selectWorkoutId,
} from "../../../../../../store/fit/selection";
import { useEffect } from "react";

// import { validateEmail } from "../../../../../../utils/validators";

const StyledDialog = styled(Dialog)`
  & .MuiDialogContent-root {
    min-width: 400px;

    @media (max-width: 600px) {
      min-width: 95vw;
    }
  }

  & .MuiDialog-paper {
    margin: 8px;
  }
`;

const StyledButton = styled(Button)`
  & {
    min-width: 75px;
    justify-content: space-between;
  }
`;

const WorkoutLockModal = ({ isOpen, handleClose }) => {
  const { appType, clientId } = useParams();
  const dispatch = useDispatch();
  const workoutId = useSelector(selectWorkoutId);
  const sessionId = useSelector(selectSessionId);
  const authStatus = useSelector(selectAuthStatus);
  const checkingPassLoading = useSelector(selectCheckingPassLoading);
  const passwordErrMessage = useSelector(selectPasswordErrMessage);
  const [email, setEmail] = useState("");
  //   const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // const error = validateEmail(e.target.value);
    // setEmailError(error);
    if (passwordErrMessage) {
      dispatch(resetPasswordState());
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordErrMessage) {
      dispatch(resetPasswordState());
    }
  };

  const handleSubmit = () => {
    dispatch(
      checkPasswordAsync({
        email: email,
        password: password,
        workoutId: workoutId,
      })
    );
  };

  useEffect(() => {
    if (authStatus) {
      navigate(`/${appType}/${clientId}/${workoutId}/${sessionId}`);
      dispatch(resetPasswordState());
    }
  }, [authStatus]);

  return (
    <StyledDialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <TextField
          value={email}
          autoFocus
          margin="dense"
          id="name"
          label="Username"
          fullWidth
          variant="outlined"
          onChange={handleEmailChange}
        />
        <TextField
          error={Boolean(passwordErrMessage)}
          value={password}
          type="password"
          margin="dense"
          id="name"
          label="Password"
          fullWidth
          variant="outlined"
          helperText={passwordErrMessage}
          onChange={handlePasswordChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <StyledButton onClick={handleSubmit}>
          Login {checkingPassLoading && <CircularProgress size={13} />}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default WorkoutLockModal;
