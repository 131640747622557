import { LinearProgress, styled } from "@mui/material";
import WorkoutContext from "../../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectChallengeScore } from "../../../../../../store/fit/challenge";
import { selectWorkoutScore } from "../../../../../../store/fit/workout";

const Wrapper = styled("div")`
  width: 100%;
  height: 30px;
  margin-top: 20px;
  position: relative;
`;

const ScoreBar = () => {
  const { newScore, componentData, challengeMode, newScoreTimestamp } =
    useContext(WorkoutContext);
  const workoutScore = useSelector(selectWorkoutScore);
  const challengeScore = useSelector(selectChallengeScore);
  const [barValue, setBarValue] = useState(0);

  useEffect(() => {
    if (newScore) {
      setBarValue(newScore);
    }
  }, [newScoreTimestamp]);

  useEffect(() => {
    setBarValue(0);
  }, [componentData]);

  if (!challengeMode) {
    return (
      <Wrapper data-testid="scoreBarTest">
        <LinearProgress
          sx={{
            width: "100%",
            height: "25px",
            clipPath: "polygon(5% 0, 100% 0%, 95% 100%, 0% 100%)",
            color: barValue < 40 ? "#d10f0f" : barValue < 80 ? "#f5da42" : "#54B862",
            padding: "10px",
            transition: "width 1s ease-in-out, background-color 1s ease-in-out", // Add transition for smooth effect
          }}
          color="inherit"
          variant="determinate"
          value={barValue}
        />
      </Wrapper>
    );
  }

  const totalScore = workoutScore + challengeScore;
  const myScorePercentage = (workoutScore / totalScore) * 100;

  return (
    <Wrapper data-testid="scoreBarTest">
      <LinearProgress
        variant="determinate"
        value={myScorePercentage}
        sx={{
          width: "100%",
          height: "25px",
          clipPath: "polygon(5% 0, 100% 0%, 95% 100%, 0% 100%)",
          padding: "10px",
          transition: "width 0.5s ease-in-out, background-color 0.5s ease-in-out", // Add transition for smooth effect
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#54B862",
            transition: "width 0.5s ease-in-out, background-color 0.5s ease-in-out", // Add transition for smooth effect
          },
          backgroundColor: "#d10f0f",
        }}
      />
    </Wrapper>
  );
};

export default ScoreBar;
