import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material";
import { useContext, useRef } from "react";
import { selectWorkoutScore } from "../../../../../../store/fit/workout";
import WorkoutContext from "../../../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { selectChallengeScore } from "../../../../../../store/fit/challenge";
import { selectAccountUser } from "../../../../../../store/fit/account";

const Wrapper = styled("div")`
  display: flex;

  gap: 10px;
  flex-direction: column;
  margin-top: ${({ $challengeMode }) => ($challengeMode ? "0px" : "20px")};
  min-width: 115px;
`;

const InnerWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatisticsNumber = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #a6d5fa;
`;

const NewScoreNumber = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 25px;
  color: #a6d5fa;
`;

const NameText = styled("p")`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #a6d5fa;
`;

const ScoreNumber = ({ type, label, name }) => {
  const { newScore, challengeMode, newScoreTimestamp } = useContext(WorkoutContext);
  const [showNewScore, setShowNewScore] = useState(false);
  const workoutScore = useSelector(selectWorkoutScore);
  const challengeScore = useSelector(selectChallengeScore);
  const timeout = useRef();

  const score = type === "main" || type === "user" ? workoutScore : challengeScore;

  useEffect(() => {
    if (type === "challenger" || newScore < 0 || !newScore) return;
    setShowNewScore(true);
    timeout.current = setTimeout(() => {
      setShowNewScore(false);
    }, 600);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [newScoreTimestamp]);

  const displayName = (name, type) => {
    if (type === "challenger")
      return name === undefined || name === null || name === "" ? "Challenger" : name;
    if (type === "user")
      return name === undefined || name === null || name === "" ? "Your Score" : name;
  };
  if (type === "challenger")
    return (
      <Wrapper data-testid={"scoreTest"} $challengeMode={challengeMode}>
        <InnerWrapper>
          <StatisticsNumber>{score}</StatisticsNumber>
          <NameText>{displayName(name, type)}</NameText>
        </InnerWrapper>
      </Wrapper>
    );

  if (type === "user" || type === "main")
    return (
      <Wrapper data-testid={"scoreTest"} $challengeMode={challengeMode}>
        <InnerWrapper>
          {showNewScore ? (
            <NewScoreNumber $newScore={newScore}>+{Math.floor(newScore)}</NewScoreNumber>
          ) : (
            <StatisticsNumber>{score}</StatisticsNumber>
          )}
          <NameText>{displayName(name, type)}</NameText>
        </InnerWrapper>
      </Wrapper>
    );
};

export default ScoreNumber;
