import SoundContext from ".";
import { useEffect, useRef, useState } from "react";
import WorkoutContext from "../WorkoutContext";
import { useContext } from "react";
import { CONFIG } from "../../../../config";
import { createSounds } from "../../../../audio/soundEffects";
import {
  selectCurrentEventIndex,
  selectWorkoutFlow,
  selectWorkoutScore,
} from "../../../../store/fit/workout";
import { useSelector } from "react-redux";
import {
  selectIndicatorVolume,
  selectRepetitionVolume,
  selectStartStopVolume,
  selectVoiceVolume,
} from "../../../../store/fit/volume";
import {
  challengeSoundLevels,
  challengeStatusVoicePlayEvery,
  challengeVoiceMap,
  clientNamesToPlayTimeLeft,
  evenlyMatchedArray,
  exerciseStartSoundPlayComponentTypes,
  losingHardArray,
  losingSlightlyArray,
  playTimerVoiceComponentTypes,
  repetitionSoundPlayComponentTypes,
  slightlyWinningArray,
  startStopSoundsPlayComponentTypes,
  TimerVoiceTempMap,
  voicePlayComponentTypes,
  winningHardArray,
} from "./soundContextConfig";
import { findLongestCommonPrefix } from "../../../../helpers/findCommonPrefix";
import clients from "../../../../constants/clients";
import { getCompanyNameFromUrl } from "../../../../utils/urls";
import { selectFirstTimeUser } from "../../../../store/fit/quiz";
import { PriorityQueue } from "./priorityQueue";
import { selectChallengeScore } from "../../../../store/fit/challenge";
import { selectShowError } from "../../../../store/fit/error";

let visibilityOnCooldown = false;
let prefix;
let totalSounds = 0;
let firstStartSound = false;

let lastChallengeLevel = null;

const SoundContextComponent = ({ children }) => {
  const {
    repCount,
    newMessage,
    message,
    timeLeft,
    timePassed,
    componentData,
    startTimer,
    staticStartTimer,
    userReady,
    timeIsUp,
    challengeMode,
  } = useContext(WorkoutContext);
  const showError = useSelector(selectShowError);
  const challengeLevelRef = useRef(null);
  const workoutScore = useSelector(selectWorkoutScore);
  const challengerScore = useSelector(selectChallengeScore);
  ///// volume redux
  const voiceVolume = useSelector(selectVoiceVolume);
  const indicatorVolume = useSelector(selectIndicatorVolume);
  const repetitionVolume = useSelector(selectRepetitionVolume);
  const StaticstartStopVolume = useSelector(selectStartStopVolume);

  const currentEventIndex = useSelector(selectCurrentEventIndex);
  ///// voice sounds
  const liveFeedbackSound = useRef(null);
  const visibilityInstructionsSound = useRef(null);
  const timerVoiceSounds = useRef(null);
  const challengeVoiceRef = useRef(null);
  const AllComponentsAudio = useRef(null);

  ///// const AIFeedbackSound = useRef(null);

  ///// indicator sounds
  const timerIndicatorSounds = useRef(null);
  const timerStartStopSounds = useRef(null);
  const repSound = useRef(null);

  ///// workout redux
  const workout = useSelector(selectWorkoutFlow);
  const workoutFlow = workout?.workoutFlow;

  const [loadedCount, setLoadedCount] = useState(0);

  //skipping tutorial audio
  const companyName = getCompanyNameFromUrl();
  const { skipVideoTutorialFeature } = clients(companyName);
  const firstTimeUser = useSelector(selectFirstTimeUser);

  const [queue] = useState(new PriorityQueue());
  const [currentSound, setCurrentSound] = useState(null);
  const queueRef = useRef(queue);

  const challengeVoiceTimerRef = useRef(null);

  const onEndCallback = () => {
    setCurrentSound(null);
  };

  useEffect(() => {
    if (currentSound) {
      currentSound.play();
    }
    if (!currentSound) {
      const next = queueRef.current.dequeue();
      if (next) {
        setCurrentSound(next.value);
      }
    }
  }, [currentSound]);

  const addToQueue = (sound, priority) => {
    queueRef.current.enqueue(sound, priority);

    // Trigger the playback if no sound is currently playing
    if (!currentSound) {
      setCurrentSound(queueRef.current.dequeue().value);
    }
  };

  // Define an increment function
  const onLoadCallback = () => {
    setLoadedCount((prevNumber) => prevNumber + 1); // Increment the current state by 1
  };

  useEffect(() => {
    if (!workout || liveFeedbackSound.current) return;

    // Define an array with configurations for each sound
    const soundConfigs = [
      {
        ref: repSound,
        data: { repetitionSound: workout?.repetitionSound },
        volume: repetitionVolume,
      },

      {
        ref: timerIndicatorSounds,
        data: { indicatorSound: CONFIG.startStopIndicator },
        volume: indicatorVolume,
      },
      {
        ref: timerStartStopSounds,
        data: {
          timerStartSound: workout["timerStartSound"],
          timerStopSound: workout["timerStopSound"],
        },
        volume: StaticstartStopVolume,
      },
    ];

    const voiceConfigs = [
      {
        ref: visibilityInstructionsSound,
        data: {
          visibilityInstructions: workout?.visibilityInstructions,
          reducedJointSetModeInstructions:
            "https://fittyai-utils.s3.amazonaws.com/audio-files/SubBlock_Make-sure-you-are-visible.mp3",
        },
        volume: voiceVolume,
      },
      {
        ref: liveFeedbackSound,
        data: workout?.feedbackMessages,
        volume: voiceVolume,
      },

      {
        ref: timerVoiceSounds,
        data: {
          secondsLeftTen: workout["10SecondsLeftSound"],
          secondsLeftFive: workout["5SecondsLeftSound"],
          startPose:
            "https://fittyai-utils.s3.amazonaws.com/audio-files/SubBlock_thats-good-lets-start.mp3",
          badPose:
            "https://fittyai-utils.s3.amazonaws.com/audio-files/SubBlock_Please-fix-your-pose.mp3",

          goodPose:
            "https://fittyai-utils.s3.amazonaws.com/audio-files/SubBlock_Thats-good-lets-continue.mp3",
          ...TimerVoiceTempMap,
        },
        volume: voiceVolume,
      },
      {
        ref: AllComponentsAudio,
        data: createComponentSoundsObject(),
        volume: voiceVolume,
      },
      {
        ref: challengeVoiceRef,
        data: { ...challengeVoiceMap },
        volume: voiceVolume,
      },
    ];
    // Iterate over the configurations and apply createSounds for each
    soundConfigs.forEach(({ ref, data, volume }) => {
      const soundCount = Object.keys(data).length; // Count how many sounds are in each config
      totalSounds += soundCount; // Add to the total sounds count
      ref.current = createSounds(data, volume, onLoadCallback);
    });

    voiceConfigs.forEach(({ ref, data, volume }) => {
      const soundCount = Object.keys(data).length; // Count how many sounds are in each config
      totalSounds += soundCount; // Add to the total sounds count
      ref.current = createSounds(data, volume, onLoadCallback, onEndCallback);
    });
  }, [workout]);

  const createComponentSoundsObject = () => {
    const urls = [
      ...new Set(workoutFlow.flatMap((item) => [item?.audioUrl].filter(Boolean))),
    ];
    const commonPrefix = findLongestCommonPrefix(urls);
    prefix = commonPrefix;

    return createComponentSounds(urls, commonPrefix);
  };

  const createComponentSounds = (urls, prefix) => {
    return urls.reduce((acc, url) => {
      const key = url.replace(prefix, "");
      acc[key] = url;
      return acc;
    }, {});
  };

  ///////////// Repetitions  //////////////////////
  useEffect(() => {
    if (repCount > 0 && repetitionSoundPlayComponentTypes?.includes(componentData?.type))
      repSound.current?.repetitionSound.play();
  }, [repCount]);

  //////////////////// Workout live feedback ////////////////////////

  useEffect(() => {
    const id = newMessage?.id;
    if (newMessage?.status === "positive" && challengeMode) return;
    if (!voicePlayComponentTypes?.includes(componentData?.type)) return;

    if (!message) {
      playSoundFromFeedback(id);
      if (visibilityInstructionsSound.current[id] && !visibilityOnCooldown) {
        addToQueue(visibilityInstructionsSound.current[id], 1, 500);
        visibilityCooldown();
      }
    }
  }, [newMessage]);

  const visibilityCooldown = () => {
    visibilityOnCooldown = true;
    setTimeout(() => {
      visibilityOnCooldown = false;
    }, CONFIG.visibilityFeedbackTime * 1000);
  };

  ///// playing component audio
  const stopAllComponentAudio = () => {
    if (AllComponentsAudio?.current) {
      Object.values(AllComponentsAudio?.current).forEach((sound) => {
        if (sound?.playing()) {
          sound.stop();
        }
      });
    }
  };

  useEffect(() => {
    firstStartSound = false;
    setCurrentSound(null);

    stopAllComponentAudio();
    ///workout is not loaded yet
    if (!workout || !componentData?.audioUrl) return;
    /// skipping playing sound of tutorial component
    if (
      componentData?.type === CONFIG.WORKOUTFLOW_COMPONENT_TYPES.setupTutorial &&
      skipVideoTutorialFeature &&
      firstTimeUser === false
    )
      return;
    /// fixes bug when component audio is played even though workout ended
    if (componentData?.index >= workout?.workoutFlow.length) return;

    const key = componentData?.audioUrl.replace(prefix, "");

    addToQueue(AllComponentsAudio?.current[key], 1);

    return () => stopAllComponentAudio();
  }, [componentData, workout, userReady]);

  const playSoundFromFeedback = (id) => {
    if (liveFeedbackSound?.current[id]) {
      addToQueue(liveFeedbackSound?.current[id], 1);
      //visibility sound does not play if id is wrong
    }
  };

  //// Timer sounds
  useEffect(() => {
    // Define a mapping of timeLeft values to sound keys
    const timeToSoundKey = {
      [CONFIG.SOUND_INDICATORS.ten]: "secondsLeftTen",
      [CONFIG.SOUND_INDICATORS.five]: "secondsLeftFive",
    };

    const soundKey = timeToSoundKey[timeLeft];

    // Play the corresponding sound if it exists
    if (soundKey) {
      addToQueue(timerVoiceSounds.current?.[soundKey], 2);
    }

    if (componentData?.duration === timeLeft) return;

    if (timeLeft === 10) return;
    if (playTimerVoiceComponentTypes.includes(componentData?.type)) {
      // Determine if it's a whole minute or a specific second within a minute
      const isWholeMinute = timePassed % 60 === 0;
      const voiceSoundKey = isWholeMinute ? timeLeft : timeLeft % 60;

      if (timerVoiceSounds.current?.[voiceSoundKey])
        addToQueue(timerVoiceSounds.current?.[voiceSoundKey], 2);
    }
    // Specific condition for setting isVoicePlaying to true
  }, [timeLeft]);

  useEffect(() => {
    if (playTimerVoiceComponentTypes.includes(componentData?.type)) {
      // Determine if it's a whole minute or a specific second within a minute
      const isWholeMinute = timePassed % 60 === 0;
      const soundKey = isWholeMinute ? timePassed : timePassed % 60;
      addToQueue(timerVoiceSounds.current?.[soundKey], 2);
    }
  }, [timePassed]);

  useEffect(() => {
    if (timeIsUp) addToQueue(timerVoiceSounds.current?.["timeIsUp"], 5);
  }, [timeIsUp]);

  useEffect(() => {
    if (exerciseStartSoundPlayComponentTypes.includes(componentData?.type))
      if (startTimer === true || startTimer === false)
        timerIndicatorSounds?.current?.indicatorSound?.play();
  }, [startTimer]);

  useEffect(() => {
    if (startStopSoundsPlayComponentTypes.includes(componentData?.type)) {
      if (staticStartTimer === true) {
        firstStartSound
          ? addToQueue(timerVoiceSounds?.current?.goodPose, 3)
          : addToQueue(timerVoiceSounds?.current?.startPose, 3);
        timerStartStopSounds?.current?.timerStartSound?.play();
        if (!firstStartSound) firstStartSound = true;
      }
      if (staticStartTimer === false && firstStartSound) {
        addToQueue(timerVoiceSounds?.current?.badPose, 3);
        timerStartStopSounds?.current?.timerStopSound?.play();
      }
    }
  }, [staticStartTimer]);
  ///// Challenge mode
  useEffect(() => {
    if (!challengeMode) return;

    const scoreDifference = Math.abs(challengerScore - workoutScore);
    let newChallengeLevel;

    if (scoreDifference >= 600) {
      if (challengerScore > workoutScore) {
        newChallengeLevel = challengeSoundLevels.losingHard;
      } else {
        newChallengeLevel = challengeSoundLevels.winningHard;
      }
    } else if (scoreDifference >= 250) {
      if (challengerScore > workoutScore) {
        newChallengeLevel = challengeSoundLevels.losingSlightly;
      } else {
        newChallengeLevel = challengeSoundLevels.slightlyWinning;
      }
    } else {
      newChallengeLevel = challengeSoundLevels.evenlyMatched;
    }

    if (newChallengeLevel !== challengeLevelRef.current) {
      challengeLevelRef.current = newChallengeLevel;
    }
  }, [challengerScore, workoutScore, challengeMode]);

  const getRandomKey = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  // Add an effect to log challengeLevel every 10 seconds
  useEffect(() => {
    if (!startTimer || showError) return;

    const playChallengeVoice = () => {
      if (challengeLevelRef.current !== lastChallengeLevel) {
        lastChallengeLevel = challengeLevelRef.current;
        let soundKey;

        switch (challengeLevelRef.current) {
          case challengeSoundLevels.losingHard:
            soundKey = getRandomKey(losingHardArray);
            break;
          case challengeSoundLevels.winningHard:
            soundKey = getRandomKey(winningHardArray);
            break;
          case challengeSoundLevels.losingSlightly:
            soundKey = getRandomKey(losingSlightlyArray);
            break;
          case challengeSoundLevels.slightlyWinning:
            soundKey = getRandomKey(slightlyWinningArray);
            break;
          case challengeSoundLevels.evenlyMatched:
            soundKey = getRandomKey(evenlyMatchedArray);
            break;
          default:
            return; // No action if no challenge level is matched
        }

        if (soundKey) {
          const sound = challengeVoiceRef.current[soundKey]; // Get the sound object from the ref
          if (sound && voicePlayComponentTypes.includes(componentData?.type)) {
            addToQueue(sound, 1); // Adjust the priority as needed
          }
        }
      }
    };

    challengeVoiceTimerRef.current = setInterval(
      playChallengeVoice,
      challengeStatusVoicePlayEvery * 1000
    );

    return () => clearInterval(challengeVoiceTimerRef.current); // Cleanup interval on component unmount
  }, [startTimer, showError]);

  return (
    <SoundContext.Provider
      value={{
        loadedCount,
        totalSounds,
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};

export default SoundContextComponent;
