import { printAverageFPSFeature, printFPSFeature } from "./features";

let events_started = false;
const targetFps = 13;
const targetEvents = 15;
let ts_target = undefined;
let ts_target_fps = undefined;
let secondsPassed = 0;
let totalEvents = 0;
let averageEvents = 0;
let eventIndex = 0;

export let FPSDisplay = 0;

export const setEventsStarted = (value) => {
  events_started = value;
};

setInterval(() => {
  if (events_started) {
    // counting events per second sent
    secondsPassed += 1;
    totalEvents += eventIndex;
    averageEvents = (totalEvents / secondsPassed).toFixed(1);
    FPSDisplay = eventIndex;
    if (printFPSFeature) console.log(`${eventIndex} FPS`);
    if (printAverageFPSFeature) console.log(`Average: ${averageEvents} FPS`);
    eventIndex = 0;
  }
}, 1000);

export const eventsLimiter = () => {
  //initial timestamp

  if (!ts_target) ts_target = Date.now() / 1000 + 1 / targetEvents;

  if (Date.now() / 1000 >= ts_target && events_started) {
    ts_target =
      ts_target +
      (1 / targetEvents) *
        Math.floor(1 + (Date.now() / 1000 - ts_target) / (1 / targetEvents));
    eventIndex += 1;
    return true;
  }
  return false;
};

export const fpsLimiter = () => {
  //initial timestamp

  if (!ts_target_fps) ts_target_fps = Date.now() / 1000 + 1 / targetFps;

  if (Date.now() / 1000 >= ts_target_fps && events_started) {
    ts_target_fps =
      ts_target_fps +
      (1 / targetFps) *
        Math.floor(1 + (Date.now() / 1000 - ts_target_fps) / (1 / targetFps));

    return true;
  }
  return false;
};
