import Header from "./components/Header";
import styled from "styled-components";
import Body from "./components/Body";
import Footer from "./components/Footer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getWorkoutsAsync } from "../../../store/fit/selection";
import { GUID } from "../../../helpers/GUID";
import { resetState } from "../../../store/fit/workout";
import { useParams } from "react-router-dom";
import ContentWrapper from "../../../components/ContentWrapper";
import FloatingLogo from "../../../components/FloatingLogo";
import { resetChallenge } from "../../../store/fit/challenge";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px;
  padding-right: 8px;
`;

const SelectionScreen = () => {
  const dispatch = useDispatch();
  const sessionId = GUID();
  const { clientId } = useParams();

  useEffect(() => {
    dispatch(resetState());
    dispatch(resetChallenge());

    dispatch(getWorkoutsAsync(sessionId, clientId));
  }, []);

  return (
    <ContentWrapper>
      <Wrapper>
        <Header />
        <Body />
        <Footer />
      </Wrapper>
      <FloatingLogo showEmail={true} />
    </ContentWrapper>
  );
};

export default SelectionScreen;
