import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCMg-ywKfBlUh7xtXGnxSFj98ZvgY3zWh0",
  authDomain: "fitty-1.firebaseapp.com",
  projectId: "fitty-1",
  storageBucket: "fitty-1.appspot.com",
  messagingSenderId: "594640258849",
  appId: "1:594640258849:web:a14475d1b368547a936060",
  measurementId: "G-7EMZWXC600",
};
initializeApp(firebaseConfig);

export default getFirestore();
