const TimerIcon = (props) => {
  return (
    <svg
      width={6}
      height={6}
      viewBox="0 0 6 6"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      {...props}
    >
      <path
        d="M3 0C1.55039 0 0.375 1.17539 0.375 2.625C0.375 4.07461 1.55039 5.25 3 5.25C4.44961 5.25 5.625 4.07461 5.625 2.625C5.625 1.17539 4.44961 0 3 0ZM4.03418 3.43184L3.8666 3.66035C3.86296 3.66533 3.85836 3.66953 3.85309 3.67273C3.84781 3.67593 3.84196 3.67805 3.83586 3.67897C3.82976 3.6799 3.82354 3.67961 3.81755 3.67812C3.81157 3.67664 3.80593 3.67398 3.80098 3.67031L2.83184 2.96367C2.8258 2.95934 2.82089 2.95361 2.81753 2.94699C2.81416 2.94036 2.81244 2.93302 2.8125 2.92559V1.3125C2.8125 1.28672 2.83359 1.26562 2.85938 1.26562H3.14121C3.16699 1.26562 3.18809 1.28672 3.18809 1.3125V2.7627L4.02363 3.3668C4.04473 3.38145 4.04941 3.41074 4.03418 3.43184Z"
        fillOpacity="0.8"
      />
    </svg>
  );
};
export default TimerIcon;
