import { Button, styled } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import SmartLink from "../../../../components/Primitives/SmartLink";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  min-width: 120px;
`;

const TopNav = () => {
  const { appType, sessionId, clientId } = useParams();

  return (
    <Wrapper>
      <StyledButton
        component={SmartLink}
        to={`/${appType}/${clientId}/summary/${sessionId}`}
        variant="outlined"
        startIcon={<ArrowBackIosIcon />}
      >
        Overview
      </StyledButton>
    </Wrapper>
  );
};

export default TopNav;
