import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectWorkoutSummary,
  selectWorkoutSummaryLoading,
} from "../../../../store/fit/summary";
import CompletedCircle from "./CompletedCircle";
import PerformanceSkeleton from "./PerformanceSkeleton";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin-bottom: 24px;
  background: #171d29;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 24px;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 236px;
  width: 100%;
  height: fit-content;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 100%;
  margin-bottom: 40px;
  width: fit-content;
`;

const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const MetricTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 600px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-left: 24px;
  }
`;

const MetricDataText = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  min-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
`;

const MetricDataLabelText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  opacity: 0.4;
  white-space: nowrap;
`;

export const Metric = ({
  iconName,
  iconWidth,
  iconHeight,
  dataText,
  dataLabelText,
  percentage,
}) => {
  const summaryLoading = useSelector(selectWorkoutSummaryLoading);

  if (summaryLoading)
    return (
      <MetricContainer>
        <PerformanceSkeleton />
      </MetricContainer>
    );

  return (
    <MetricContainer>
      <CompletedCircle
        iconName={iconName}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        percentage={percentage}
      />
      <MetricTextContainer>
        <MetricDataText>{dataText}</MetricDataText>
        <MetricDataLabelText>{dataLabelText}</MetricDataLabelText>
      </MetricTextContainer>
    </MetricContainer>
  );
};

const PerformanceCard = () => {
  const workoutSummary = useSelector(selectWorkoutSummary);
  const {
    performanceAccuracyPercentage,
    performanceBurnedKcal,
    performanceBurnedKcalPercentage,
    performanceScore,
    performanceScorePercentage,
  } = workoutSummary || {};

  return (
    <Wrapper>
      <Title>Performance</Title>
      <CardWrapper>
        <Metric
          dataLabelText="accuracy"
          iconName="ChartIcon"
          iconWidth="22px"
          iconHeight="22px"
          dataText={performanceAccuracyPercentage}
          percentage={performanceAccuracyPercentage}
        />
        <Metric
          dataLabelText="score"
          iconName="FlagIcon"
          iconWidth="15px"
          iconHeight="19px"
          dataText={performanceScore}
          percentage={performanceScorePercentage}
        />
        <Metric
          dataLabelText="kcal burned"
          iconName="FlameIcon"
          iconWidth="17px"
          iconHeight="22px"
          dataText={performanceBurnedKcal}
          percentage={performanceBurnedKcalPercentage}
        />
      </CardWrapper>
    </Wrapper>
  );
};

export default PerformanceCard;
