import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import firestore from "../../api/firebase/firestore";
import DOMPurify from "dompurify";

const Wrapper = styled("div")`
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  color: white;
  margin-top: 64px;
`;

const InnerWrapper = styled("div")`
  width: 100%;
  padding: 0px 16px;
`;

const H1 = styled("h1")`
  min-width: 0px;
  font-family: "Roboto";
  color: white;
  font-weight: 700;
  font-size: 32px;
  padding-bottom: 32px;
  align-self: start;
  line-height: 1.25;
`;

const Text = styled(`div`)`
  background-color: transparent !important;
  color: white !important;

  * {
    background-color: transparent !important;
    color: white !important;
  }
`;

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState();

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  const fetchPrivacyPolicy = async () => {
    const firestorePrivacyPolicyKey = "SGeV0a9XtgT1JasmxTzO";
    const docRef = doc(firestore, "fl_content", firestorePrivacyPolicyKey);
    await getDoc(docRef).then((response) =>
      setPrivacyPolicy(response?.data?.()?.mainPolicy)
    );
  };

  const cleanHTML = DOMPurify.sanitize(privacyPolicy, {
    USE_PROFILES: { html: true },
  });

  return (
    <Wrapper>
      <InnerWrapper>
        <H1>Privacy Policy</H1>
        <Text dangerouslySetInnerHTML={{ __html: cleanHTML }} />
      </InnerWrapper>
    </Wrapper>
  );
};

export default PrivacyPolicy;
