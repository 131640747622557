import { Grow, CircularProgress, styled } from "@mui/material";
import WorkoutContext from "../../../../containers/Mirror/WorkoutScreen/WorkoutContext";
import { useState, useEffect, useRef, useContext } from "react";
import { staticComponentTypes } from "./MobileTrainerConfig";
import { isIOS, isSafari } from "react-device-detect";

let badRepIndex = 0;
let goodRepIndex = 0;
let firstTimeShown = false;

const MobileTrainer = () => {
  const {
    playSound,
    componentData,
    newScore,
    showTrainer,
    setShowTrainer,
    newScoreTimestamp,
  } = useContext(WorkoutContext);

  const videoUrl = componentData?.videoUrl;
  const videoUrlMov = componentData?.videoUrlMov;
  const trainerVideoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const repsToHideTrainer = staticComponentTypes.includes(componentData?.type)
    ? firstTimeShown
      ? 2
      : 4
    : firstTimeShown
    ? 3
    : 2;

  useEffect(() => {
    if (!newScore) return;
    if (newScore >= 60) goodRepIndex++;
    if (newScore < 60) badRepIndex++;

    if (badRepIndex >= 4) {
      setShowTrainer(true);
      resetIndexes();
    }

    if (goodRepIndex > repsToHideTrainer) {
      firstTimeShown = true;
      setShowTrainer(false);
      resetIndexes();
    }
  }, [newScoreTimestamp]);

  const resetIndexes = () => {
    goodRepIndex = 0;
    badRepIndex = 0;
  };

  useEffect(() => {
    resetIndexes();
    setShowTrainer(true);
    firstTimeShown = false;
  }, [componentData]);

  useEffect(() => {
    trainerVideoRef.current?.load();
  }, [videoUrl]);

  return (
    <Grow in={showTrainer}>
      <Wrapper className="trainer video">
        {/* {isLoading && <StyledCircularProgress />} */}
        {videoUrl && (
          <TrainerEl
            ref={trainerVideoRef}
            data-testid="exerciseVideoTest"
            playsInline={true}
            autoPlay
            muted={!playSound}
            loop
            $isLoading={isLoading}
            onCanPlay={() => {
              setIsLoading(false);
            }}
          >
            {(isIOS || isSafari) && videoUrlMov ? (
              <source src={videoUrlMov} />
            ) : (
              <>
                <source src={videoUrl} />
                <source src={videoUrlMov} />
              </>
            )}
          </TrainerEl>
        )}
      </Wrapper>
    </Grow>
  );
};

const Wrapper = styled("div")`
  height: -webkit-fill-available;
  position: relative;
  left: 0;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
`;

const TrainerEl = styled("video")`
  ${({ $isLoading }) => $isLoading && "display: none;"}
  position: absolute;
  width: 110%;
  left: 50%;
  top: 50%;
  max-width: 650px;
  transform: translate(-50%, -50%);
  z-index: 6;
`;

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: #a6d5fa;
  }
`;

export default MobileTrainer;
