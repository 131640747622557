import { createSlice } from "@reduxjs/toolkit";
import { BE_AWS_URL } from "../../api";
import xhrRequest from "../../api/xhrRequest";

// Reducer
const initialState = {
  sessionId: undefined,
  workoutId: undefined,
  workoutsSelection: {
    workoutsLoading: false,
    workouts: undefined,
  },

  passwordState: {
    authStatus: undefined,
    checkingPassLoading: false,
    passwordErrMessage: "",
  },
};

const selectionSlice = createSlice({
  name: "selection",
  initialState: initialState,
  reducers: {
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setWorkoutId: (state, action) => {
      state.workoutId = action.payload;
    },
    setWorkouts: (state, action) => {
      state.workoutsSelection.workouts = action.payload;
    },
    setWorkoutsLoading: (state, action) => {
      state.workoutsSelection.workoutsLoading = action.payload;
    },
    setPasswordStateAuthStatus: (state, action) => {
      state.passwordState.authStatus = action.payload;
    },
    setCheckingPassLoading: (state, action) => {
      state.passwordState.checkingPassLoading = action.payload;
    },
    setPasswordErrMessage: (state, action) => {
      state.passwordState.passwordErrMessage = action.payload;
    },
    resetPasswordState: (state) => {
      state.passwordState = initialState.passwordState;
    },
  },
});

export const {
  setSessionId,
  setWorkoutId,
  setWorkouts,
  setWorkoutsLoading,
  setPasswordStateAuthStatus,
  setCheckingPassLoading,
  setPasswordErrMessage,
  resetPasswordState,
} = selectionSlice.actions;

// Thunk

export const getWorkoutsAsync =
  (sessionId, companyName) => async (dispatch) => {
    try {
      dispatch(setSessionId(sessionId));
      dispatch(setWorkoutsLoading(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/getAvailableWorkouts?companyName=${companyName}`,
        tryAuth: false,
      });
      dispatch(setWorkoutsLoading(false));
      dispatch(setWorkouts(response?.data?.workouts));
    } catch (error) {
      dispatch(setWorkoutsLoading(false));
      console.error(error);
      dispatch(setWorkoutsLoading(false));
    }
  };

export const checkPasswordAsync =
  ({ email, password, workoutId }) =>
  async (dispatch) => {
    try {
      dispatch(setCheckingPassLoading(true));
      const response = await xhrRequest({
        url: `${BE_AWS_URL}/workoutAuth`,
        data: { userName: email, password, workoutId },
        tryAuth: false,
        method: "POST",
      });
      dispatch(setCheckingPassLoading(false));
      const authStatus = response?.data?.authStatus;
      dispatch(setPasswordStateAuthStatus(authStatus));
      if (authStatus === false) {
        dispatch(setPasswordErrMessage("Wrong Password"));
      }
    } catch (error) {
      dispatch(setCheckingPassLoading(false));
      dispatch(
        setPasswordErrMessage("An error occured. Please try again later.")
      );
      console.error(error);
    }
  };

// Selectors
export const selectSessionId = (state) => state.fit.selection.sessionId;
export const selectWorkoutId = (state) => state.fit.selection.workoutId;
export const selectWorkouts = (state) =>
  state.fit.selection.workoutsSelection.workouts;

export const selectWorkoutsLoading = (state) =>
  state.fit.selection.workoutsSelection.workoutsLoading;

export const selectAuthStatus = (state) =>
  state.fit.selection.passwordState.authStatus;

export const selectCheckingPassLoading = (state) =>
  state.fit.selection.passwordState.checkingPassLoading;

export const selectPasswordErrMessage = (state) =>
  state.fit.selection.passwordState.passwordErrMessage;

const selectionReducer = selectionSlice.reducer;

export default selectionReducer;
